import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { openToast } from '../../utility/toast';
import {
  addContainer,
  editContainer,
  removeContainer,
} from '../../services/containerManagementAPI';
import { ContainerManagemantState } from '../../model/containerManagementInterface';

const initialState: ContainerManagemantState = {
  viewModal: {
    data: false,
    status: 'idle'
  },
  container: {
    data: null,
    status: 'idle'
  },
  containerToEdit: {
    data: null,
    status: 'idle'
  },
  containerToDelete: {
    data: null,
    status: 'idle'
  },
};

export const addContainerAsync = createAsyncThunk(
  'containerManagement/addContainer',
  async (form: any) => {
    try {
      const response: any = await addContainer(form);
      openToast({
        severity: 'success',
        alertMessage: 'AddOK'
      });

      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'AddKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);
export const editContainerAsync = createAsyncThunk(
  'containerManagement/editContainer',
  async (form: { ctx: string; body: any; id:any}) => {
    try {
      const response: any = await editContainer(form);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);
export const removeContainerAsync = createAsyncThunk(
  'containerManagement/removeContainer',
  async (data:{ ctx: any, idCont: any }) => {
    try {
      const response: any = await removeContainer(data);
      openToast({
        severity: 'success',
        alertMessage: 'Deleted'
      });

      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'NotDeleted'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);



export const containerManagementSlice = createSlice({
  name: 'containerManagement',
  initialState,
  reducers: {
    resetContainer: (state) => {
      state.container.status = 'idle';
      state.container.data = null;
    },
    setViewModal: (state, action) => {
      state.viewModal.status = 'idle';
      state.viewModal.data = action.payload;
    },
    resetViewModal: (state) => {
      state.viewModal.status = 'idle';
      state.viewModal.data = false;
    },
    setContainerToEdit: (state, action) => {
      state.containerToEdit.data = action.payload;
    },
    resetContainerToEdit: (state) => {
      state.containerToEdit.status = 'idle';
      state.containerToEdit.data = null;
    },
    setContainerToDelete: (state, action) => {
      state.containerToDelete.data = action.payload;
    },
    resetContainerToDelete: (state) => {
      state.containerToDelete.status = 'idle';
      state.containerToDelete.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addContainerAsync.pending, (state) => {
        state.container.status = 'loading';

      })
      .addCase(addContainerAsync.fulfilled, (state: any, action) => {
        state.container.status = 'success';
        state.container.data = action.payload;

      })
      .addCase(addContainerAsync.rejected, (state: any, action) => {
        state.container.status = 'failed';
        state.container.data = action.error;
  
      })
      .addCase(editContainerAsync.pending, (state) => {
        state.containerToEdit.status = 'loading';

      })
      .addCase(editContainerAsync.fulfilled, (state: any, action) => {
        state.containerToEdit.status = 'success';
        state.containerToEdit.data = action.payload;

      })
      .addCase(editContainerAsync.rejected, (state: any, action) => {
        state.containerToEdit.status = 'failed';
        state.containerToEdit.data = action.error;
  
      })
      .addCase(removeContainerAsync.pending, (state) => {
        state.containerToDelete.status = 'loading';

      })
      .addCase(removeContainerAsync.fulfilled, (state: any, action) => {
        state.containerToDelete.status = 'success';
        state.containerToDelete.data = action.payload;

      })
      .addCase(removeContainerAsync.rejected, (state: any, action) => {
        state.containerToDelete.status = 'failed';
        state.containerToDelete.data = action.error;
  
      });
  }
  
});

export const {
  resetContainer,
  setViewModal,
  resetViewModal,
  setContainerToEdit,
  resetContainerToEdit,
  setContainerToDelete,
  resetContainerToDelete,
} = containerManagementSlice.actions;

export const containerStatus = (state: RootState) =>
  state.containerManagement.container;
export const viewModalStatus = (state: RootState) =>
  state.containerManagement.viewModal;
export const containerToEditStatus = (state: RootState) =>
  state.containerManagement.containerToEdit;
export const containerToDeleteStatus = (state: RootState) =>
  state.containerManagement.containerToDelete;



export default containerManagementSlice.reducer;
