import { get } from '../utility/http';

export async function fetchContesti() {
  try {
    const response = await get('/cnc/ctx', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchNavigazione(id: string, rootContainer: string) {
  try {
    const response = await get(
      `/cnc/ctx/${id}/containers/${rootContainer}?full=false`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchTaskList(rootContainer: string) {
  try {
    const response = await get(
      `/tasks/tasks/externalref/${rootContainer}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchTaskAssignment(taskId: string) {
  try {
    const response = await get(
      `/tree/masks/resource/${taskId}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
