import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ContainerTypeManagemantState } from '../../model/containerTypeManagementInterface';
import {
  addContainerType, 
  editContainerType, 
  fetchContainerType,
  removeContainerType
} from '../../services/containerTypeManagementAPI';
import { openToast } from '../../utility/toast';

const initialState: ContainerTypeManagemantState = {
  containerType: {
    data: null,
    status: 'idle'
  },
  addContainerType: {
    data: null,
    status: 'idle'
  },
  containerTypeToEdit: {
    data: null,
    status: 'idle'
  },
  containerTypeToDelete: {
    data: null,
    status: 'idle'
  },
  viewModalType: {
    data: false,
    status: 'idle'
  },
};


export const containerTypeAsync = createAsyncThunk(
  'containerTypeManagement/fetchContainerType',
  async (data: any) => {
    try {
      const response: any = await fetchContainerType(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addContainerTypeAsync = createAsyncThunk(
  'containerTypeManagement/addContainerType',
  async (form: any) => {
    try {
      const response: any = await addContainerType(form);
      openToast({
        severity: 'success',
        alertMessage: 'AddOK'
      });

      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'AddKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editContainerTypeAsync = createAsyncThunk(
  'containerTypeManagement/editContainerType',
  async (form: any) => {
    try {
      const response: any = await editContainerType(form);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });

      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);
export const removeContainerTypeAsync = createAsyncThunk(
  'containerTypeManagement/removeContainerType',
  async (data:{ctx: any, idCont: any}) => {
    try {
      const response: any = await removeContainerType(data);
      openToast({
        severity: 'success',
        alertMessage: 'Deleted'
      });

      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'NotDeleted'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const containerTypeManagementSlice = createSlice({
  name: 'containerTypeManagement',
  initialState,
  reducers:{
    resetContainerType: (state) =>{
      state.containerType.status = 'idle';
      state.containerType.data = null;
    },
    resetAddContainerType: (state) =>{
      state.addContainerType.status = 'idle';
      state.addContainerType.data = null;
    },
    setContainerTypeToEdit: (state, action) =>{
      state.containerTypeToEdit.data = action.payload;
    },
    resetContainerTypeToEdit: (state) =>{
      state.containerTypeToEdit.status = 'idle';
      state.containerTypeToEdit.data = null;
    },
    setContainerTypeToDelete: (state, action) =>{
      state.containerTypeToDelete.data = action.payload;
    },
    resetContainerTypeToDelete: (state) =>{
      state.containerTypeToDelete.status = 'idle';
      state.containerTypeToDelete.data = null;
    },
    setViewModalType: (state, action) => {
      state.viewModalType.status = 'idle';
      state.viewModalType.data = action.payload;
    },
    resetViewModalType: (state) => {
      state.viewModalType.status = 'idle';
      state.viewModalType.data = false;
    }
  },

  extraReducers: (builder) =>{
    builder
      .addCase(containerTypeAsync.pending, (state: any) => {
        state.containerType.status = 'loading';
      })
      .addCase(containerTypeAsync.fulfilled, (state: any, action) => {
        state.containerType.status = 'success';
        state.containerType.data = action.payload;
      })
      .addCase(containerTypeAsync.rejected, (state: any, action) => {
        state.containerType.status = 'failed';
        state.containerType.data = action.error;
      })
      .addCase(addContainerTypeAsync.pending, (state: any) => {
        state.addContainerType.status = 'loading';
      })
      .addCase(addContainerTypeAsync.fulfilled, (state: any, action) => {
        state.addContainerType.status = 'success';
        state.addContainerType.data = action.payload;
      })
      .addCase(addContainerTypeAsync.rejected, (state: any, action) => {
        state.addContainerType.status = 'failed';
        state.addContainerType.data = action.error;
      })
      .addCase(editContainerTypeAsync.pending, (state: any) => {
        state.containerTypeToEdit.status = 'loading';
      })
      .addCase(editContainerTypeAsync.fulfilled, (state: any, action) => {
        state.containerTypeToEdit.status = 'success';
        state.containerTypeToEdit.data = action.payload;
      })
      .addCase(editContainerTypeAsync.rejected, (state: any, action) => {
        state.containerTypeToEdit.status = 'failed';
        state.addContainerType.data = action.error;
      })
      .addCase(removeContainerTypeAsync.pending, (state: any) => {
        state.containerTypeToDelete.status = 'loading';
      })
      .addCase(removeContainerTypeAsync.fulfilled, (state: any, action) => {
        state.containerTypeToDelete.status = 'success';
        state.containerTypeToDelete.data = action.payload;
      })
      .addCase(removeContainerTypeAsync.rejected, (state: any, action) => {
        state.containerTypeToDelete.status = 'failed';
        state.containerTypeToDelete.data = action.error;
      });
  }
});

export const {
  resetContainerType,
  resetAddContainerType,
  setContainerTypeToEdit,
  resetContainerTypeToEdit,
  setContainerTypeToDelete,
  resetContainerTypeToDelete,
  setViewModalType,
  resetViewModalType,
} = containerTypeManagementSlice.actions;

export const containerTypeStatus = (state: RootState) =>
  state.containerTypeManagement.containerType;
export const addContainerTypeStatus = (state: RootState) =>
  state.containerTypeManagement.addContainerType;
export const editContainerTypeStatus = (state: RootState) =>
  state.containerTypeManagement.containerTypeToEdit;
export const removeContainerTypeStatus = (state: RootState) =>
  state.containerTypeManagement.containerTypeToDelete;
export const viewModalTypeStatus = (state: RootState) =>
  state.containerTypeManagement.viewModalType;


export default containerTypeManagementSlice.reducer;
