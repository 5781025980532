import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider as AppSettingsProvider } from '@sigeo-registry/config-react';
import config from './config';
import './utility/projections';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppSettingsProvider config={config}>
        <App />
      </AppSettingsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
