import { get, post } from '../utility/http';

export async function fetchListContainer() {
  try {
    const response = await get('/dm/list', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchLogContainerName({container, rows}:{container: string, rows: string}) {
  try {
    const response = await get(`/dm/log?name=${container}&rows=${rows}&state=all`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchRestartContainerName({container}:{container: string}) {
  try {
    const response = await post(`/dm/restart?name=${container}&state=all`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
