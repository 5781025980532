import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SuspenseLoaderOverlay from './components/suspenseLoader/SuspenseLoader';
import { themeStatus } from './utility/themeSlice';
import { useSelector } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import * as moment from 'moment';
import 'moment/locale/it';
import Toast from './components/toast/Toast';
import { SnackbarProvider } from 'notistack';

const Layout = React.lazy(() => import('./components/layout/Layout'));
const AuthProvider = React.lazy(
  () => import('./components/authProvider/AuthProvider')
);
const Login = React.lazy(() => import('./features/login/Login'));
const PageNotFound = React.lazy(
  () => import('./components/layout/PageNotFound')
);
const App = (): JSX.Element => {
  const themeStatusSelector: any = useSelector(themeStatus);

  return (
    <ThemeProvider theme={createTheme(themeStatusSelector.theme)}>
      <React.Suspense
        fallback={
          <>
            <SuspenseLoaderOverlay />
          </>
        }
      >
        <LocalizationProvider
          dateAdapter={DateAdapter}
          locale={moment.locale('it')}
        >
          <Toast />
          <SnackbarProvider maxSnack={5} autoHideDuration={7000}>
            <BrowserRouter>
              <AuthProvider>
                <Switch>
                  <Route
                    exact
                    path={'/404'}
                    render={() => {
                      return <PageNotFound />;
                    }}
                  />

                  <Route exact path="/error">
                    <PageNotFound />
                  </Route>

                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <Route path="/">
                    <Layout />
                  </Route>
                </Switch>
              </AuthProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </LocalizationProvider>
      </React.Suspense>
    </ThemeProvider>
  );
};

export default App;
