import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ThemeSliceInterface } from '../model/themeInterface';
import context from '../theme';

export const getTheme = () => {
  let selectedTheme: string | null = 'light';
  if (localStorage.getItem('theme')) {
    selectedTheme = localStorage.getItem('theme');
  }

  return context[`${selectedTheme}`];
};

const initialState: ThemeSliceInterface = {
  theme: getTheme()
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      localStorage.setItem('theme', action.payload);
      state.theme = context[action.payload];
    },
    removeTheme: (state) => {
      localStorage.removeItem('theme');
      state.theme = context.defaultTheme;
    }
  }
});

export const { setTheme, removeTheme } = themeSlice.actions;
export const themeStatus = (state: RootState) => state.theme;
export default themeSlice.reducer;
