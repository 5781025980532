import { del, get, post, put } from '../utility/http';

export async function fetchUserResources() {
  try {
    const response = await get(
      '/users/resources',
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchUserResource(id: string) {
  try {
    const response = await get(
      `/users/resources/${id}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchResourcesByUser(username: string) {
  try {
    const response = await get(`/users/resources/user/${username}`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addUserResource(body: any) {
  try {
    const response = await post(
      '/users/resources/',
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editUserResource(body: any, id: number) {
  try {
    const response = await put(
      `/users/resources/${id}`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeUserResource(id: number) {
  try {
    const response = await del(
      `/users/resources/${id}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
