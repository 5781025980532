import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { GenericWeatherState } from '../../model/realtimeMonitoringInterface';

const initialState: GenericWeatherState = {
  meteo: {
    data: null,
    status: 'idle'
  },
  weatherService: false
};

export const getWeatherAsync = createAsyncThunk(
  'weather/fetchWeather',
  async ([lon, lat, language]:any) => {
    try {
      const response: any = await fetch(
        // eslint-disable-next-line max-len
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&lang=${language}&units=metric&appid=dbb4b3fc3df9bf6c908de60bdd7dbc74`
      );
      return response.json();
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const popupWeatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    setWeatherService: (state,action) => {
      state.weatherService = action.payload;
    },
    resetPopupWeather: (state) => {
      state.meteo.status = 'idle';
      state.meteo.data = null;
    },
    resetWeatherService: (state) => {
      state.weatherService = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeatherAsync.pending, (state) => {
        state.meteo.status = 'loading';
      })
      .addCase(getWeatherAsync.fulfilled, (state, action) => {
        state.meteo.status = 'success';
        state.meteo.data = action.payload;
      })
      .addCase(getWeatherAsync.rejected, (state: any, action) => {
        state.meteo.status = 'failed';
        state.meteo.data = action.error;
      });
  }
});

export const {
  setWeatherService,
  resetPopupWeather,
  resetWeatherService
} = popupWeatherSlice.actions;
export const popupWeatherState = (state: RootState) => state.popupWeather;
export default popupWeatherSlice.reducer;
