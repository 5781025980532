import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {  IFormWidgetProps, widgetDashboardState } from '../../model/dashboardInterface';
import { 
  addWidget, 
  fetchDashboard, 
  fetchWidget 
} from '../../services/dashboardAPI';


const initialState: widgetDashboardState = {
  dashboard:{
    data: null,
    status: 'idle'
  },
  widgets:{
    data: null,
    status: 'idle'
  },
  addWidgets:{
    data: null,
    status: 'idle'
  },
  viewModalWidget: {
    data: false,
    status: 'idle'
  },
  
};

export const dashboardAsync = createAsyncThunk(
  'dashboard/fetchDashboard',
  async (ctx: string) => {
    try {
      const response: any = await fetchDashboard(ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const widgetsAsync = createAsyncThunk(
  'dashboard/fetchWidgetAsync',
  async (queryString: string) => {
    try {
      const response: any = await fetchWidget(queryString);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addWidgetsAsync = createAsyncThunk(
  'dashboard/addWidgetAsync',
  async (body: IFormWidgetProps) => {
    try {
      const response: any = await addWidget(body);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboard: (state) => {
      state.dashboard.status = 'idle';
      state.dashboard.data = null;
    },
    resetWidgets: (state) => {
      state.widgets.status = 'idle';
      state.widgets.data = null;
    },
    resetAddWidgets: (state) => {
      state.addWidgets.status = 'idle';
      state.addWidgets.data = null;
    },
    setViewModalWidget: (state, action) => {
      state.viewModalWidget.status = 'idle';
      state.viewModalWidget.data = action.payload;
    },
    resetViewModalWidget: (state) => {
      state.viewModalWidget.status = 'idle';
      state.viewModalWidget.data = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboardAsync.pending, (state) => {
        state.dashboard.status = 'loading';
      })
      .addCase(dashboardAsync.fulfilled, (state: any, action) => {
        state.dashboard.status = 'success';
        state.dashboard.data = action.payload;
      })
      .addCase(dashboardAsync.rejected, (state: any, action) => {
        state.dashboard.status = 'failed';
        state.dashboard.data = action.error;
      })
      .addCase(widgetsAsync.pending, (state) => {
        state.widgets.status = 'loading';
      })
      .addCase(widgetsAsync.fulfilled, (state: any, action) => {
        state.widgets.status = 'success';
        state.widgets.data = action.payload;
      })
      .addCase(widgetsAsync.rejected, (state: any, action) => {
        state.widgets.status = 'failed';
        state.widgets.data = action.error;
      })
      .addCase(addWidgetsAsync.pending, (state) => {
        state.addWidgets.status = 'loading';
      })
      .addCase(addWidgetsAsync.fulfilled, (state: any, action) => {
        state.addWidgets.status = 'success';
        state.addWidgets.data = action.payload;
      })
      .addCase(addWidgetsAsync.rejected, (state: any, action) => {
        state.addWidgets.status = 'failed';
        state.addWidgets.data = action.error;
      });
  }
});

export const { 
  resetDashboard, 
  resetWidgets,
  resetAddWidgets,
  setViewModalWidget,
  resetViewModalWidget,} = dashboardSlice.actions;
export const dashboardState = (state: RootState) => state.dashboard.dashboard;
export const widgetsState = (state: RootState) => state.dashboard.widgets;
export const addWidgetsState = (state: RootState) => state.dashboard.addWidgets;
export const viewModalWidgetState = (state: RootState) => state.dashboard.viewModalWidget;



export default dashboardSlice.reducer;
