import { ThemeOptions } from '@mui/material';

export interface CustomTheme extends ThemeOptions {
  name?: string;
}

const theme: CustomTheme = {
  name: 'Contesto 1',
  palette: {
    primary: {
      main: '#ae0427'
    },
    secondary: {
      main: '#9c27b0'
    },
    success: {
      main: '#2e7d32'
    },
    warning: {
      main: '#ED6C02'
    },
    error: {
      main: '#d32f2f'
    },
    info: {
      main: '#c8c8c8'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    h6: {
      fontSize: '14px'
    },
    h5: {
      fontSize: '16px'
    },
    h4: {
      fontSize: '20px'
    },
    h3: {
      fontSize: '24px'
    },
    h2: {
      fontSize: '30px'
    },
    h1: {
      fontSize: '40px'
    }
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    }
  }
};
const defaultTheme: CustomTheme = {
  name: 'Contesto 2',
  palette: {
    primary: {
      main: '#1976d2'
    },
    secondary: {
      main: '#9c27b0'
    },
    success: {
      main: '#2e7d32'
    },
    warning: {
      main: '#ED6C02'
    },
    error: {
      main: '#d32f2f'
    },
    info: {
      main: '#c8c8c8'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    h6: {
      fontSize: '14px'
    },
    h5: {
      fontSize: '16px'
    },
    h4: {
      fontSize: '20px'
    },
    h3: {
      fontSize: '24px'
    },
    h2: {
      fontSize: '30px'
    },
    h1: {
      fontSize: '40px'
    }
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    }
  }
};

type ContextInterface = {
  [key: string]: CustomTheme;
};

const context: ContextInterface = {
  dark: theme,
  light: theme,
  default: defaultTheme
};

export default context;
