import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { PopupTaskSliceInterface } from '../../model/popupTaskInterface';

const initialState: PopupTaskSliceInterface = {
  popupTasksService: false
};

export const popupTasksSlice = createSlice({
  name: 'popupTasks',
  initialState,
  reducers: {
    setPopupTasksService: (state,action) => {
      state.popupTasksService = action.payload;
    },
    resetPopupTasksService: (state) => {
      state.popupTasksService = false;
    }
  },
});

export const {
  setPopupTasksService,
  resetPopupTasksService
} = popupTasksSlice.actions;
export const popupTasksState = (state: RootState) => state.popupTasks;
export default popupTasksSlice.reducer;
