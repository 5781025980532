import { store } from '../app/store';
import { setToast } from '../components/toast/toastSlice';
import { ToastProps } from '../model/toastInterface';

export const openToast = ({
  severity,
  alertMessage, // i18n Variable
  duration = 5000,
  open = true
}: ToastProps) => {
  store.dispatch(setToast({ severity, alertMessage, duration, open }));
};
