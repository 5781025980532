import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { FetchLoaderState } from '../../model/fetchLoaderInterface';

export const getToken = (): string | null => {
  return localStorage.getItem('jwtToken');
};

const initialState: FetchLoaderState = {
  fetchLoader: false
};

export const fetchLoaderSlice = createSlice({
  name: 'fetchLoader',
  initialState,
  reducers: {
    setLoader: (state) => {
      state.fetchLoader = true;
    },
    removeLoader: (state) => {
      state.fetchLoader = false;
    }
  }
});

export const { setLoader, removeLoader } = fetchLoaderSlice.actions;
export const fetchLoaderStatus = (state: RootState): FetchLoaderState =>
  state.fetchLoader;
export default fetchLoaderSlice.reducer;
