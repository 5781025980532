import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export const getToken = () => {
  return localStorage.getItem('jwtToken');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

const initialState = {
  token: getToken(),
  refreshToken: getRefreshToken()
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      localStorage.setItem('jwtToken', action.payload);
      state.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      localStorage.setItem('refreshToken', action.payload);
      state.refreshToken = action.payload;
    },
    removeToken: (state) => {
      localStorage.removeItem('userRole');
      localStorage.removeItem('username');
      localStorage.removeItem('context');
      localStorage.removeItem('contextData');
      localStorage.removeItem('theme');
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('userEmail');
      state.token = null;
    },
    removeRefrshToken: (state) => {
      localStorage.removeItem('refreshToken');
      state.refreshToken = null;
    }
  }
});

export const { setToken, setRefreshToken, removeToken, removeRefrshToken } = tokenSlice.actions;
export const tokenStatus = (state: RootState) => state.token.token;
export const refreshTokenStatus = (state: RootState) => state.token.refreshToken;
export default tokenSlice.reducer;
