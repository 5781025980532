import LinearProgress from '@mui/material/LinearProgress';

function SuspenseLoaderOverlay() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 10000,
        backgroundColor: 'white'
      }}
    >
      <LinearProgress />
    </div>
  );
}

export default SuspenseLoaderOverlay;
