import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MapToolsState } from '../../model/mapToolsInterface';
import { RootState } from '../../app/store';
import {
  fetchMarkers,
  addMarker,
  editMarker,
  removeMarker,
  fetchMarkerDetail
} from '../../services/mapToolsAPI';

export const getMarkersAsync = createAsyncThunk(
  'mapTools/fetchMarkers',
  async ({ project }: { project: string }) => {
    try {
      const response: any = await fetchMarkers(project);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getMarkerDetailAsync = createAsyncThunk(
  'mapTools/fetchMarkerDetail',
  async ({ id, project }: { id: string; project: string }) => {
    try {
      const response: any = await fetchMarkerDetail({ id, project });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addMarkerAsync = createAsyncThunk(
  'mapTools/addMarker',
  async ({
    project,
    type,
    username,
    geometry,
    description
  }: {
    project: string;
    type: string;
    username: string;
    geometry: any;
    description: any;
  }) => {
    try {
      const response: any = await addMarker({
        project,
        type,
        username,
        geometry,
        description
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editMarkerAsync = createAsyncThunk(
  'mapTools/editMarker',
  async ({
    id,
    project,
    type,
    username,
    geometry,
    description
  }: {
    id: string;
    project: string;
    type: string;
    username: string;
    geometry: any;
    description: any;
  }) => {
    try {
      const response: any = await editMarker({
        id,
        project,
        type,
        username,
        geometry,
        description
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const removeMarkerAsync = createAsyncThunk(
  'mapTools/removeMarker',
  async ({ id, project }: { id: string; project: string }) => {
    try {
      const response: any = await removeMarker({
        id,
        project
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

const initialState: MapToolsState = {
  selectedTools: [],
  markers: {
    data: null,
    status: 'idle'
  },
  markerDetail: {
    data: null,
    status: 'idle'
  },
  addMarker: {
    data: null,
    status: 'idle'
  },
  editMarker: {
    data: null,
    status: 'idle'
  },
  removeMarker: {
    data: null,
    status: 'idle'
  },
  flag3D: false,
  flag3DDrawing: false
};
export const mapToolsSlice = createSlice({
  name: 'mapTools',
  initialState,
  reducers: {
    setSelectedTools: (state, action) => {
      state.selectedTools = action.payload;
    },
    resetSelectedTools: (state) => {
      state.selectedTools = [];
    },
    resetMarkers: (state) => {
      state.markers.status = 'idle';
      state.markers.data = null;
    },
    resetMarkerDetail: (state) => {
      state.markerDetail.status = 'idle';
      state.markerDetail.data = null;
    },
    resetAddMarker: (state) => {
      state.addMarker.status = 'idle';
      state.addMarker.data = null;
    },
    resetEditMarker: (state) => {
      state.editMarker.status = 'idle';
      state.editMarker.data = null;
    },
    resetRemoveMarker: (state) => {
      state.removeMarker.status = 'idle';
      state.removeMarker.data = null;
    },
    enable3D: (state) => {
      state.flag3D = true;
    },
    disable3D: (state) => {
      state.flag3D = false;
    },
    enable3DDrawing: (state) => {
      state.flag3DDrawing = true;
    },
    disable3DDrawing: (state) => {
      state.flag3DDrawing = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarkersAsync.pending, (state) => {
        state.markers.status = 'loading';
      })
      .addCase(getMarkersAsync.fulfilled, (state: any, action) => {
        state.markers.status = 'success';
        state.markers.data = action.payload;
      })
      .addCase(getMarkersAsync.rejected, (state: any, action) => {
        state.markers.status = 'failed';
        state.markers.data = action.error;
      })
      .addCase(getMarkerDetailAsync.pending, (state) => {
        state.markerDetail.status = 'loading';
      })
      .addCase(getMarkerDetailAsync.fulfilled, (state: any, action) => {
        state.markerDetail.status = 'success';
        state.markerDetail.data = action.payload;
      })
      .addCase(getMarkerDetailAsync.rejected, (state: any, action) => {
        state.markerDetail.status = 'failed';
        state.markerDetail.data = action.error;
      })
      .addCase(addMarkerAsync.pending, (state) => {
        state.addMarker.status = 'loading';
      })
      .addCase(addMarkerAsync.fulfilled, (state: any, action) => {
        state.addMarker.status = 'success';
        state.addMarker.data = action.payload;
      })
      .addCase(addMarkerAsync.rejected, (state: any, action) => {
        state.addMarker.status = 'failed';
        state.addMarker.data = action.error;
      })
      .addCase(editMarkerAsync.pending, (state) => {
        state.editMarker.status = 'loading';
      })
      .addCase(editMarkerAsync.fulfilled, (state: any, action) => {
        state.editMarker.status = 'success';
        state.editMarker.data = action.payload;
      })
      .addCase(editMarkerAsync.rejected, (state: any, action) => {
        state.editMarker.status = 'failed';
        state.editMarker.data = action.error;
      })
      .addCase(removeMarkerAsync.pending, (state) => {
        state.removeMarker.status = 'loading';
      })
      .addCase(removeMarkerAsync.fulfilled, (state: any, action) => {
        state.removeMarker.status = 'success';
        state.removeMarker.data = action.payload;
      })
      .addCase(removeMarkerAsync.rejected, (state: any, action) => {
        state.removeMarker.status = 'failed';
        state.removeMarker.data = action.error;
      });
  }
});

export const {
  setSelectedTools,
  resetSelectedTools,
  resetMarkers,
  resetMarkerDetail,
  resetAddMarker,
  resetEditMarker,
  resetRemoveMarker,
  enable3D,
  disable3D,
  enable3DDrawing,
  disable3DDrawing
} = mapToolsSlice.actions;
export const selectedToolsStatus = (state: RootState) =>
  state.mapTools.selectedTools;
export const markersStatus = (state: RootState) => state.mapTools.markers;
export const markerDetailStatus = (state: RootState) =>
  state.mapTools.markerDetail;
export const addMarkerStatus = (state: RootState) => state.mapTools.addMarker;
export const editMarkerStatus = (state: RootState) => state.mapTools.editMarker;
export const removeMarkerStatus = (state: RootState) =>
  state.mapTools.removeMarker;
export const flag3DStatus = (state: RootState) => state.mapTools.flag3D;
export const flag3DDrawingStatus = (state: RootState) => state.mapTools.flag3DDrawing;
export default mapToolsSlice.reducer;
