import { get, post } from '../utility/http';

export async function fetchDashboard(ctx: string) {
  try {
    const response = await get(`/tasks/tasks/ctx/${ctx}?type=drone-mission`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchWidget(queryString: string) {
  try {
    const response = await get('/widget/widgets/dashboard',
      true,
      {typeJobs: queryString}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchDataWidget(id: any, ctx: any) {
  try {
    //const ctx = localStorage?.getItem('context');
    if(ctx){
      const response = await get(`/widget/widgets/${id}/data`, 
        true,
        {
          placeholderSubstitute: ctx 
        }
      );
      return response;
    }
    
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}


export async function addWidget(body: any) {
  try {
    const response = await post('/widget/widgets', true, {}, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}


