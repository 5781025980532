import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { TasksState } from '../../model/tasksInterface';
import {
  fetchBoundComune,
  fetchComuni,
  fetchContainerTasks,
  fetchProvince,
  fetchRegioni
} from '../../services/tasksAPI';

const initialState: TasksState = {
  regioni: {
    data: null,
    status: 'idle'
  },
  province: {
    data: null,
    status: 'idle'
  },
  comuni: {
    data: null,
    status: 'idle'
  },
  filters: {
    location: {
      data: null,
      status: 'idle'
    },
    rangeDate: ['2015-01-01T00:00:00.000Z', '2025-12-31T24:00:00.000Z'],
    type: {
      toAssign: true,
      assigned: true,
      archived: true
    }
  },
  containerTasks: {
    data: [],
    status: 'idle'
  }
};

export const regioniAsync = createAsyncThunk('tasks/regioni', async () => {
  try {
    const response: any = await fetchRegioni();
    return response.data;
  } catch (e: any) {
    return Promise.reject(e.data ? e.data : e);
  }
});

export const provinceAsync = createAsyncThunk(
  'tasks/province',
  async (regione: string) => {
    try {
      const response: any = await fetchProvince(regione);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const comuniAsync = createAsyncThunk(
  'tasks/comuni',
  async (provincia: string) => {
    try {
      const response: any = await fetchComuni(provincia);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const boundComuneAsync = createAsyncThunk(
  'tasks/boundComune',
  async (data: string) => {
    try {
      const response: any = await fetchBoundComune(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const containerTasksAsync = createAsyncThunk(
  'tasks/containerTasks',
  async (ctx: string) => {
    try {
      const response: any = await fetchContainerTasks(ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);


export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    resetRegioni: (state) => {
      state.regioni.status = 'idle';
      state.regioni.data = null;
    },
    resetProvince: (state) => {
      state.province.status = 'idle';
      state.province.data = null;
    },
    resetComuni: (state) => {
      state.comuni.status = 'idle';
      state.comuni.data = null;
    },
    resetFiltersLocation: (state) => {
      state.filters.location.status = 'idle';
      state.filters.location.data = null;
    },
    resetFiltersRangeDate: (state) => {
      state.filters.rangeDate = [
        '2015-01-01T00:00:00.000Z',
        '2025-12-31T24:00:00.000Z'
      ];
    },
    resetFiltersType: (state) => {
      state.filters.type = {
        toAssign: true,
        assigned: true,
        archived: true
      };
    },
    setFiltersRangeDate: (state, action) => {
      state.filters.rangeDate = action.payload;
    },
    setFiltersType: (state, action) => {
      state.filters.type = action.payload;
    },
    resetContainerTasks: (state) => {
      state.containerTasks.status = 'idle';
      state.containerTasks.data = [];
    }
 
  },
  extraReducers: (builder) => {
    builder
      .addCase(regioniAsync.pending, (state) => {
        state.regioni.status = 'loading';
      })
      .addCase(regioniAsync.fulfilled, (state, action) => {
        state.regioni.status = 'success';
        state.regioni.data = action.payload;
      })
      .addCase(regioniAsync.rejected, (state, action) => {
        state.regioni.status = 'failed';
        state.regioni.data = action.error;
      })
      .addCase(provinceAsync.pending, (state) => {
        state.province.status = 'loading';
      })
      .addCase(provinceAsync.fulfilled, (state, action) => {
        state.province.status = 'success';
        state.province.data = action.payload;
      })
      .addCase(provinceAsync.rejected, (state, action) => {
        state.province.status = 'failed';
        state.province.data = action.error;
      })
      .addCase(comuniAsync.pending, (state) => {
        state.comuni.status = 'loading';
      })
      .addCase(comuniAsync.fulfilled, (state, action) => {
        state.comuni.status = 'success';
        state.comuni.data = action.payload;
      })
      .addCase(comuniAsync.rejected, (state, action) => {
        state.comuni.status = 'failed';
        state.comuni.data = action.error;
      })
      .addCase(boundComuneAsync.pending, (state) => {
        state.filters.location.status = 'loading';
      })
      .addCase(boundComuneAsync.fulfilled, (state, action) => {
        state.filters.location.status = 'success';
        state.filters.location.data = action.payload;
      })
      .addCase(boundComuneAsync.rejected, (state, action) => {
        state.filters.location.status = 'failed';
        state.filters.location.data = action.error;
      })
      .addCase(containerTasksAsync.pending, (state) => {
        state.containerTasks.status = 'loading';
      })
      .addCase(containerTasksAsync.fulfilled, (state, action) => {
        state.containerTasks.status = 'success';
        state.containerTasks.data = action.payload;
      })
      .addCase(containerTasksAsync.rejected, (state, action) => {
        state.containerTasks.status = 'failed';
        state.containerTasks.data = action.error;
      });
  }
});

export const {
  resetFiltersLocation,
  resetFiltersRangeDate,
  resetFiltersType,
  setFiltersRangeDate,
  setFiltersType,
  resetRegioni,
  resetProvince,
  resetComuni,
  resetContainerTasks
} = tasksSlice.actions;
export const regioniState = (state: RootState) => state.tasks.regioni;
export const provinceState = (state: RootState) => state.tasks.province;
export const comuniState = (state: RootState) => state.tasks.comuni;
export const filtersLocationState = (state: RootState) =>
  state.tasks.filters.location;
export const filtersRangeDateState = (state: RootState) =>
  state.tasks.filters.rangeDate;
export const filtersTypeState = (state: RootState) => state.tasks.filters.type;
export const containerTasksState = (state: RootState) =>
  state.tasks.containerTasks;
export default tasksSlice.reducer;
