import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  fetchFlights,
  fetchHangar,
  fetchHangarControl,
  fetchCleosList,
  fetchStartCleosStream,
  fetchDroneState,
  fetchDroneControl,
  fetchDronePositionPull,
  fetchGeolocation,
  fetchCleosActiveStreams,
  fetchMavlinkControlGet,
  fetchMavlinkControlPost,
  fetchMavlinkInitialized,
  linkTask,
  unlinkTask,
  checkTask,
  linkJob,
  unlinkJob,
  checkJob,
  fetchSocketConnected,
  fetchDroneTracker
} from '../../services/monitoringAPI';
import { MonitoringSliceProps } from '../../model/realtimeMonitoringInterface';
import { openToast } from '../../utility/toast';
const initialState: MonitoringSliceProps = {
  flights: {
    data: null,
    status: 'idle'
  },
  detail: false,
  droneDetail: {
    data: null,
    status: 'idle'
  },
  drone: {
    data: null,
    status: 'idle'
  },
  hangar: {
    data: null,
    status: 'idle'
  },
  hangarControl: {
    data: null,
    status: 'idle'
  },
  cleosList: {
    data: null,
    status: 'idle'
  },
  startCleosStream: {
    data: null,
    status: 'idle'
  },
  droneState: {
    data: null,
    status: 'idle'
  },
  droneControl: {
    data: null,
    status: 'idle'
  },
  trackingMode: '',
  cleosActiveStreams: {
    data: null,
    status: 'idle'
  },
  mavlink: {
    data: null,
    status: 'idle'
  },
  peopleGeoLocation: {
    which_detection: 'people',
    interval: null
  },
  crowdGeoLocation: {
    which_detection: 'crowd',
    interval: null
  },
  facemaskGeoLocation: {
    which_detection: 'facemask',
    interval: null
  },
  weaponGeoLocation: {
    which_detection: 'weapon',
    interval: null
  },
  peopleGeoLocationFetch: {
    data: null,
    status: 'idle'
  },
  crowdGeoLocationFetch: {
    data: null,
    status: 'idle'
  },
  facemaskGeoLocationFetch: {
    data: null,
    status: 'idle'
  },
  weaponGeoLocationFetch: {
    data: null,
    status: 'idle'
  },
  mavLinkInitialized: {
    data: false,
    status: 'idle'
  },
  guided: '',
  guidedFlag: false,
  socketInitialized: false,
  socketConnected: {
    data: false,
    status: 'idle'
  },
  value: 0,
  inputBuffer: {
    data: {
      distance: 1,
      height: 15
    },
    status: 'idle'
  },
  linkTaskData: {
    data: null,
    status: 'idle'
  },
  unlinkTaskData: {
    data: null,
    status: 'idle'
  },
  checkTaskData: {
    data: null,
    status: 'idle'
  },
  linkJobData: {
    data: null,
    status: 'idle'
  },
  unlinkJobData: {
    data: null,
    status: 'idle'
  },
  checkJobData: {
    data: null,
    status: 'idle'
  },
  selected: false,
  automaticActionEnabled: false
};

export const getDroneStateAsync = createAsyncThunk(
  'monitoring/fetchDroneState',
  async (id: any) => {
    try {
      const response: any = await fetchDroneState({ id });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const postDroneControlAsync = createAsyncThunk(
  'monitoring/fetchDroneControl',
  async ({ cmd, uav_id, ...rest }: any) => {
    try {
      const response: any = await fetchDroneControl({ cmd, uav_id, ...rest });
      if (response?.data?.status !== 'error') {
        openToast({
          severity: 'success',
          alertMessage: 'FetchOK'
        });
      } else {
        openToast({
          severity: 'error',
          alertMessage: 'FetchKO'
        });
      }
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getMavlinkControlAsync = createAsyncThunk(
  'monitoring/fetchMavlinkControlGet',
  async ({ cmd, uav_id, port, ...rest }: any) => {
    try {
      const response: any = await fetchMavlinkControlGet({
        cmd,
        uav_id,
        port,
        ...rest
      });
      if (response?.data?.status !== 'error') {
        openToast({
          severity: 'success',
          alertMessage: response?.data?.status ?? 'FetchOK'
        });
      } else {
        openToast({
          severity: 'error',
          alertMessage: response?.data?.status ?? 'FetchKO'
        });
      }
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.status ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const initializeMAVLinkAsync = createAsyncThunk(
  'monitoring/fetchMavlinkControlGet',
  async ({ cmd, uav_id, port, ...rest }: any) => {
    try {
      const response: any = await fetchMavlinkControlGet({
        cmd,
        uav_id,
        port,
        ...rest
      });
      if (response?.data?.status === 'error') {
        openToast({
          severity: 'error',
          alertMessage: response?.data?.status ?? 'FetchKO'
        });
      }
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.status ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const postMavlinkControlAsync = createAsyncThunk(
  'monitoring/fetchMavlinkControlPost',
  async ({ cmd, uav_id, port, ...rest }: any) => {
    try {
      const response: any = await fetchMavlinkControlPost({
        cmd,
        uav_id,
        port,
        ...rest
      });
      openToast({
        severity: 'success',
        alertMessage: response?.data?.status ?? 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.status ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getMavlinkInitializedAsync = createAsyncThunk(
  'monitoring/fetchMavlinkInitialized',
  async ({ uav_id, port }: any) => {
    try {
      const response: any = await fetchMavlinkInitialized({ uav_id, port });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getDronePositionAsync = createAsyncThunk(
  'monitoring/dronePosition',
  async ({
    uavId,
    type,
    taskId,
    ctx
  }: {
    uavId: string;
    type: string;
    taskId: string;
    ctx: any;
  }) => {
    try {
      let response: any;
      if (type === 'HANGAR' || type === 'VIRTUAL BOX') {
        response = await fetchDronePositionPull(uavId);
      } else if (type === 'COM-BOX') {
        response = await fetchDroneTracker(uavId, 'default', taskId, ctx);
      } else if (type === 'MAVLINK') {
        response = await fetchDroneTracker(uavId, 'mavlink', taskId, ctx);
      } else if (type === 'AUGMENTATION') {
        response = await fetchDroneTracker(uavId, 'gnss', taskId, ctx);
      } else if (type === 'MOBILE APP') {
        response = await fetchDroneTracker(uavId, 'mobile', taskId, ctx);
      }
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getHangarAsync = createAsyncThunk(
  'monitoring/fetchHangar',
  async (hanId: any) => {
    try {
      const response: any = await fetchHangar(hanId);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const postHangarAsync = createAsyncThunk(
  'monitoring/fetchHangarControl',
  async ({ hangar, hanId }: any) => {
    try {
      const response: any = await fetchHangarControl({ hangar, hanId });
      if (response?.data?.status !== 'error') {
        openToast({
          severity: 'success',
          alertMessage: 'FetchOK'
        });
      } else {
        openToast({
          severity: 'error',
          alertMessage: 'FetchKO'
        });
      }
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getFlightsAsync = createAsyncThunk(
  'monitoring/fetchFlights',
  async (ctx: string) => {
    try {
      const response: any = await fetchFlights(ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getCleosListAsync = createAsyncThunk(
  'monitoring/cleosList',
  async () => {
    try {
      const response: any = await fetchCleosList();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getCleosActiveStreamsAsync = createAsyncThunk(
  'monitoring/cleosActiveStreams',
  async () => {
    try {
      const response: any = await fetchCleosActiveStreams();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const startCleosStreamAsync = createAsyncThunk(
  'monitoring/startCleosStream',
  async ({
    uav_id,
    which_detection
  }: {
    uav_id: string;
    which_detection: string;
  }) => {
    try {
      const response: any = await fetchStartCleosStream({
        uav_id,
        which_detection
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getCrowdGeolocationAsync = createAsyncThunk(
  'monitoring/crowdGeolocation',
  async ({
    uav_id,
    which_detection
  }: {
    uav_id: string;
    which_detection: string;
  }) => {
    try {
      const response: any = await fetchGeolocation({
        uav_id,
        which_detection
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getFacemaskGeolocationAsync = createAsyncThunk(
  'monitoring/facemaskGeolocation',
  async ({
    uav_id,
    which_detection
  }: {
    uav_id: string;
    which_detection: string;
  }) => {
    try {
      const response: any = await fetchGeolocation({
        uav_id,
        which_detection
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getWeaponGeolocationAsync = createAsyncThunk(
  'monitoring/weaponGeolocation',
  async ({
    uav_id,
    which_detection
  }: {
    uav_id: string;
    which_detection: string;
  }) => {
    try {
      const response: any = await fetchGeolocation({
        uav_id,
        which_detection
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getPeopleGeolocationAsync = createAsyncThunk(
  'monitoring/peopleGeolocation',
  async ({
    uav_id,
    which_detection
  }: {
    uav_id: string;
    which_detection: string;
  }) => {
    try {
      const response: any = await fetchGeolocation({
        uav_id,
        which_detection
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const linkTaskAsync = createAsyncThunk(
  'monitoring/linkTask',
  async ({
    ref,
    task_id,
    ctx,
    task_label
  }: {
    ref: any;
    task_id: any;
    ctx: any;
    task_label: string;
  }) => {
    try {
      const response: any = await linkTask(ref, task_id, ctx, task_label);
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.details ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const unlinkTaskAsync = createAsyncThunk(
  'monitoring/unlinkTask',
  async ({ ref, task_id, ctx }: { ref: any; task_id: any; ctx: any }) => {
    try {
      const response: any = await unlinkTask(ref, task_id, ctx);
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.details ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const checkTaskAsync = createAsyncThunk(
  'monitoring/checkTask',
  async ({ ref, task_id, ctx }: { ref: any; task_id: any; ctx: any }) => {
    try {
      const response: any = await checkTask(ref, task_id, ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const linkJobAsync = createAsyncThunk(
  'monitoring/linkJob',
  async ({
    ref,
    job_id,
    ctx,
    job_label
  }: {
    ref: any;
    job_id: any;
    ctx: any;
    job_label: any;
  }) => {
    try {
      const response: any = await linkJob(ref, job_id, ctx, job_label);
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.details ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const unlinkJobAsync = createAsyncThunk(
  'monitoring/unlinkJob',
  async ({ ref, job_id, ctx }: { ref: any; job_id: any; ctx: any }) => {
    try {
      const response: any = await unlinkJob(ref, job_id, ctx);
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.details ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const checkJobAsync = createAsyncThunk(
  'monitoring/checkJob',
  async ({ ref, job_id, ctx }: { ref: any; job_id: any; ctx: any }) => {
    try {
      const response: any = await checkJob(ref, job_id, ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const checkSocketConnected = createAsyncThunk(
  'monitoring/checkSocketConnected',
  async ({ uavId, username }: { uavId: string; username: string }) => {
    try {
      const response: any = await fetchSocketConnected(uavId, username);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    resetMonitoringFlights: (state) => {
      state.flights.status = 'idle';
      state.flights.data = null;
    },
    setMonitoringDetail: (state) => {
      state.detail = true;
    },
    resetMonitoringDetail: (state) => {
      state.detail = false;
    },
    setMonitoringDroneDetail: (state, action) => {
      state.droneDetail.status = 'success';
      state.droneDetail.data = action.payload;
    },
    resetMonitoringDroneDetail: (state) => {
      state.droneDetail.status = 'idle';
      state.droneDetail.data = null;
    },
    resetMonitoringDrone: (state) => {
      state.drone.status = 'idle';
      state.drone.data = null;
    },
    resetMonitoringHangar: (state) => {
      state.hangar.status = 'idle';
      state.hangar.data = null;
    },
    resetMonitoringHangarControl: (state) => {
      state.hangarControl.status = 'idle';
      state.hangarControl.data = null;
    },
    resetCleosList: (state) => {
      state.cleosList.status = 'idle';
      state.cleosList.data = null;
    },
    resetStartCleosStream: (state) => {
      state.startCleosStream.status = 'idle';
      state.startCleosStream.data = null;
    },
    resetDroneState: (state) => {
      state.droneState.status = 'idle';
      state.droneState.data = null;
    },
    resetDroneControl: (state) => {
      state.droneControl.status = 'idle';
      state.droneControl.data = null;
    },
    setTrackingMode: (state, action) => {
      state.trackingMode = action.payload;
    },
    resetTrackingMode: (state) => {
      state.trackingMode = '';
    },
    resetCleosActiveStreams: (state) => {
      state.cleosActiveStreams.status = 'idle';
      state.cleosActiveStreams.data = null;
    },
    resetMavlink: (state) => {
      state.mavlink.status = 'idle';
      state.mavlink.data = null;
    },
    setPeopleGeoLocation: (state, action) => {
      state.peopleGeoLocation = {
        which_detection: 'people',
        interval: action.payload
      };
    },
    resetPeopleGeoLocation: (state) => {
      clearInterval(state.peopleGeoLocation.interval);
      state.peopleGeoLocation = {
        which_detection: 'people',
        interval: null
      };
    },
    setCrowdGeoLocation: (state, action) => {
      state.crowdGeoLocation = {
        which_detection: 'crowd',
        interval: action.payload
      };
    },
    resetCrowdGeoLocation: (state) => {
      clearInterval(state.crowdGeoLocation.interval);
      state.crowdGeoLocation = {
        which_detection: 'crowd',
        interval: null
      };
    },
    setFacemaskGeoLocation: (state, action) => {
      state.facemaskGeoLocation = {
        which_detection: 'facemask',
        interval: action.payload
      };
    },
    resetFacemaskGeoLocation: (state) => {
      clearInterval(state.facemaskGeoLocation.interval);
      state.facemaskGeoLocation = {
        which_detection: 'facemask',
        interval: null
      };
    },
    setWeaponGeoLocation: (state, action) => {
      state.weaponGeoLocation = {
        which_detection: 'weapon',
        interval: action.payload
      };
    },
    resetWeaponGeoLocation: (state) => {
      clearInterval(state.weaponGeoLocation.interval);
      state.weaponGeoLocation = {
        which_detection: 'weapon',
        interval: null
      };
    },
    resetPeopleGeoLocationFetch: (state) => {
      state.peopleGeoLocationFetch.status = 'idle';
      state.peopleGeoLocationFetch.data = null;
    },
    resetCrowdGeoLocationFetch: (state) => {
      state.crowdGeoLocationFetch.status = 'idle';
      state.crowdGeoLocationFetch.data = null;
    },
    resetFacemaskGeoLocationFetch: (state) => {
      state.facemaskGeoLocationFetch.status = 'idle';
      state.facemaskGeoLocationFetch.data = null;
    },
    resetWeaponGeoLocationFetch: (state) => {
      state.weaponGeoLocationFetch.status = 'idle';
      state.weaponGeoLocationFetch.data = null;
    },
    setGuided: (state, action) => {
      state.guided = action.payload;
    },
    resetGuided: (state) => {
      state.guided = '';
    },
    setGuidedFlag: (state, action) => {
      state.guidedFlag = action.payload;
    },
    resetGuidedFlag: (state, action) => {
      state.guidedFlag = action.payload;
    },
    resetSocketConnected: (state) => {
      state.socketConnected.status = 'idle';
      state.socketConnected.data = false;
    },
    setValueTab: (state, action) => {
      state.value = action.payload;
    },
    resetValueTab: (state) => {
      state.value = 0;
    },
    resetMAVLinkInitialized: (state) => {
      state.mavLinkInitialized.status = 'idle';
      state.mavLinkInitialized.data = false;
    },
    setInputBuffer: (state, action) => {
      state.inputBuffer.status = 'success';
      state.inputBuffer.data = action.payload;
    },
    resetInputBuffer: (state) => {
      state.inputBuffer.status = 'idle';
      state.inputBuffer.data = { distance: 1, height: 15 };
    },
    resetCheckTaskData: (state) => {
      state.checkTaskData.status = 'idle';
      state.checkTaskData.data = null;
    },
    resetCheckJobData: (state) => {
      state.checkJobData.status = 'idle';
      state.checkJobData.data = null;
    },
    resetLinkTaskData: (state) => {
      state.linkTaskData.status = 'idle';
      state.linkTaskData.data = null;
    },
    resetUnlinkTaskData: (state) => {
      state.unlinkTaskData.status = 'idle';
      state.unlinkTaskData.data = null;
    },
    resetLinkJobData: (state) => {
      state.linkJobData.status = 'idle';
      state.linkJobData.data = null;
    },
    resetUnlinkJobData: (state) => {
      state.unlinkJobData.status = 'idle';
      state.unlinkJobData.data = null;
    },
    setSocketInitialized: (state, action) => {
      state.socketInitialized = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    resetSelected: (state) => {
      state.selected = false;
    },
    setAutomaticActionEnabled: (state, action) => {
      state.automaticActionEnabled = action.payload;
    },
    resetAutomaticActionEnabled: (state) => {
      state.automaticActionEnabled = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlightsAsync.pending, (state) => {
        state.flights.status = 'loading';
      })
      .addCase(getFlightsAsync.fulfilled, (state, action) => {
        state.flights.status = 'success';
        state.flights.data = action.payload;
      })
      .addCase(getFlightsAsync.rejected, (state: any, action) => {
        state.flights.status = 'failed';
        state.flights.data = action.error;
      })
      .addCase(getDronePositionAsync.pending, (state) => {
        state.drone.status = 'loading';
      })
      .addCase(getDronePositionAsync.fulfilled, (state, action) => {
        state.drone.status = 'success';
        state.drone.data = action.payload;
      })
      .addCase(getDronePositionAsync.rejected, (state: any, action) => {
        state.drone.status = 'failed';
        state.drone.data = action.error;
      })
      .addCase(getHangarAsync.pending, (state) => {
        state.hangar.status = 'loading';
      })
      .addCase(getHangarAsync.fulfilled, (state, action) => {
        state.hangar.status = 'success';
        state.hangar.data = action.payload;
      })
      .addCase(getHangarAsync.rejected, (state: any, action) => {
        state.hangar.status = 'failed';
        state.hangar.data = action.error;
      })
      .addCase(postHangarAsync.pending, (state) => {
        state.hangarControl.status = 'loading';
      })
      .addCase(postHangarAsync.fulfilled, (state, action) => {
        state.hangarControl.status = 'success';
        state.hangarControl.data = action.payload;
      })
      .addCase(postHangarAsync.rejected, (state: any, action) => {
        state.hangarControl.status = 'failed';
        state.hangarControl.data = action.error;
      })
      .addCase(getCleosListAsync.pending, (state) => {
        state.cleosList.status = 'loading';
      })
      .addCase(getCleosListAsync.fulfilled, (state, action) => {
        state.cleosList.status = 'success';
        state.cleosList.data = action.payload;
      })
      .addCase(getCleosListAsync.rejected, (state: any, action) => {
        state.cleosList.status = 'failed';
        state.cleosList.data = action.error;
      })
      .addCase(startCleosStreamAsync.pending, (state) => {
        state.startCleosStream.status = 'loading';
      })
      .addCase(startCleosStreamAsync.fulfilled, (state, action) => {
        state.startCleosStream.status = 'success';
        state.startCleosStream.data = { ...action.payload, ...action.meta.arg };
      })
      .addCase(startCleosStreamAsync.rejected, (state: any, action) => {
        state.startCleosStream.status = 'failed';
        state.startCleosStream.data = action.error;
      })
      .addCase(getDroneStateAsync.pending, (state) => {
        state.droneState.status = 'loading';
      })
      .addCase(getDroneStateAsync.fulfilled, (state, action) => {
        state.droneState.status = 'success';
        state.droneState.data = action.payload;
      })
      .addCase(getDroneStateAsync.rejected, (state: any, action) => {
        state.droneState.status = 'failed';
        state.droneState.data = action.error;
      })
      .addCase(postDroneControlAsync.pending, (state) => {
        state.droneControl.status = 'loading';
      })
      .addCase(postDroneControlAsync.fulfilled, (state, action) => {
        state.droneControl.status = 'success';
        state.droneControl.data = action.payload;
      })
      .addCase(postDroneControlAsync.rejected, (state: any, action) => {
        state.droneControl.status = 'failed';
        state.droneControl.data = action.error;
      })
      .addCase(getCleosActiveStreamsAsync.pending, (state) => {
        state.cleosActiveStreams.status = 'loading';
      })
      .addCase(getCleosActiveStreamsAsync.fulfilled, (state, action) => {
        state.cleosActiveStreams.status = 'success';
        state.cleosActiveStreams.data = action.payload;
      })
      .addCase(getCleosActiveStreamsAsync.rejected, (state: any, action) => {
        state.cleosActiveStreams.status = 'failed';
        state.cleosActiveStreams.data = action.error;
      })
      .addCase(getMavlinkControlAsync.pending, (state) => {
        state.mavlink.status = 'loading';
      })
      .addCase(getMavlinkControlAsync.fulfilled, (state, action) => {
        state.mavlink.status = 'success';
        state.mavlink.data = action.payload;
      })
      .addCase(getMavlinkControlAsync.rejected, (state: any, action) => {
        state.mavlink.status = 'failed';
        state.mavlink.data = action.error;
      })
      .addCase(getCrowdGeolocationAsync.pending, (state) => {
        state.crowdGeoLocationFetch.status = 'loading';
      })
      .addCase(getCrowdGeolocationAsync.fulfilled, (state, action) => {
        state.crowdGeoLocationFetch.status = 'success';
        state.crowdGeoLocationFetch.data = action.payload;
      })
      .addCase(getCrowdGeolocationAsync.rejected, (state: any, action) => {
        state.crowdGeoLocationFetch.status = 'failed';
        state.crowdGeoLocationFetch.data = action.error;
      })
      .addCase(getFacemaskGeolocationAsync.pending, (state) => {
        state.facemaskGeoLocationFetch.status = 'loading';
      })
      .addCase(getFacemaskGeolocationAsync.fulfilled, (state, action) => {
        state.facemaskGeoLocationFetch.status = 'success';
        state.facemaskGeoLocationFetch.data = action.payload;
      })
      .addCase(getFacemaskGeolocationAsync.rejected, (state: any, action) => {
        state.facemaskGeoLocationFetch.status = 'failed';
        state.facemaskGeoLocationFetch.data = action.error;
      })
      .addCase(getWeaponGeolocationAsync.pending, (state) => {
        state.weaponGeoLocationFetch.status = 'loading';
      })
      .addCase(getWeaponGeolocationAsync.fulfilled, (state, action) => {
        state.weaponGeoLocationFetch.status = 'success';
        state.weaponGeoLocationFetch.data = action.payload;
      })
      .addCase(getWeaponGeolocationAsync.rejected, (state: any, action) => {
        state.weaponGeoLocationFetch.status = 'failed';
        state.weaponGeoLocationFetch.data = action.error;
      })
      .addCase(getPeopleGeolocationAsync.pending, (state) => {
        state.peopleGeoLocationFetch.status = 'loading';
      })
      .addCase(getPeopleGeolocationAsync.fulfilled, (state, action) => {
        state.peopleGeoLocationFetch.status = 'success';
        state.peopleGeoLocationFetch.data = action.payload;
      })
      .addCase(getPeopleGeolocationAsync.rejected, (state: any, action) => {
        state.peopleGeoLocationFetch.status = 'failed';
        state.peopleGeoLocationFetch.data = action.error;
      })
      .addCase(getMavlinkInitializedAsync.pending, (state) => {
        state.mavLinkInitialized.status = 'loading';
      })
      .addCase(getMavlinkInitializedAsync.fulfilled, (state, action) => {
        state.mavLinkInitialized.status = 'success';
        state.mavLinkInitialized.data = action.payload;
      })
      .addCase(getMavlinkInitializedAsync.rejected, (state: any, action) => {
        state.mavLinkInitialized.status = 'failed';
        state.mavLinkInitialized.data = action.error;
      })
      .addCase(checkTaskAsync.pending, (state) => {
        state.checkTaskData.status = 'loading';
      })
      .addCase(checkTaskAsync.fulfilled, (state, action) => {
        state.checkTaskData.status = 'success';
        state.checkTaskData.data = action.payload;
      })
      .addCase(checkTaskAsync.rejected, (state: any, action) => {
        state.checkTaskData.status = 'failed';
        state.checkTaskData.data = action.error;
      })
      .addCase(linkJobAsync.pending, (state) => {
        state.linkJobData.status = 'loading';
      })
      .addCase(linkJobAsync.fulfilled, (state, action) => {
        state.linkJobData.status = 'success';
        state.linkJobData.data = action.payload;
      })
      .addCase(linkJobAsync.rejected, (state: any, action) => {
        state.linkJobData.status = 'failed';
        state.linkJobData.data = action.error;
      })
      .addCase(unlinkJobAsync.pending, (state) => {
        state.unlinkJobData.status = 'loading';
      })
      .addCase(unlinkJobAsync.fulfilled, (state, action) => {
        state.unlinkJobData.status = 'success';
        state.unlinkJobData.data = action.payload;
      })
      .addCase(unlinkJobAsync.rejected, (state: any, action) => {
        state.unlinkJobData.status = 'failed';
        state.unlinkJobData.data = action.error;
      })
      .addCase(unlinkTaskAsync.pending, (state) => {
        state.unlinkTaskData.status = 'loading';
      })
      .addCase(unlinkTaskAsync.fulfilled, (state, action) => {
        state.unlinkTaskData.status = 'success';
        state.unlinkTaskData.data = action.payload;
      })
      .addCase(unlinkTaskAsync.rejected, (state: any, action) => {
        state.unlinkTaskData.status = 'failed';
        state.unlinkTaskData.data = action.error;
      })
      .addCase(linkTaskAsync.pending, (state) => {
        state.linkTaskData.status = 'loading';
      })
      .addCase(linkTaskAsync.fulfilled, (state, action) => {
        state.linkTaskData.status = 'success';
        state.linkTaskData.data = action.payload;
      })
      .addCase(linkTaskAsync.rejected, (state: any, action) => {
        state.linkTaskData.status = 'failed';
        state.linkTaskData.data = action.error;
      })
      .addCase(checkJobAsync.pending, (state) => {
        state.checkJobData.status = 'loading';
      })
      .addCase(checkJobAsync.fulfilled, (state, action) => {
        state.checkJobData.status = 'success';
        state.checkJobData.data = action.payload;
      })
      .addCase(checkJobAsync.rejected, (state: any, action) => {
        state.checkJobData.status = 'failed';
        state.checkJobData.data = action.error;
      })
      .addCase(checkSocketConnected.pending, (state) => {
        state.socketConnected.status = 'loading';
      })
      .addCase(checkSocketConnected.fulfilled, (state, action) => {
        state.socketConnected.status = 'success';
        state.socketConnected.data = action.payload;
      })
      .addCase(checkSocketConnected.rejected, (state: any, action) => {
        state.socketConnected.status = 'failed';
        state.socketConnected.data = action.error;
      });
  }
});

export const {
  resetMonitoringFlights,
  setMonitoringDetail,
  resetMonitoringDetail,
  setMonitoringDroneDetail,
  resetMonitoringDroneDetail,
  resetMonitoringDrone,
  resetMonitoringHangar,
  resetMonitoringHangarControl,
  resetCleosList,
  resetStartCleosStream,
  resetDroneState,
  resetDroneControl,
  setTrackingMode,
  resetTrackingMode,
  resetCleosActiveStreams,
  resetMavlink,
  setPeopleGeoLocation,
  resetPeopleGeoLocation,
  setCrowdGeoLocation,
  resetCrowdGeoLocation,
  setFacemaskGeoLocation,
  resetFacemaskGeoLocation,
  setWeaponGeoLocation,
  resetWeaponGeoLocation,
  resetPeopleGeoLocationFetch,
  resetCrowdGeoLocationFetch,
  resetFacemaskGeoLocationFetch,
  resetWeaponGeoLocationFetch,
  setGuided,
  resetGuided,
  setValueTab,
  resetValueTab,
  resetMAVLinkInitialized,
  setInputBuffer,
  resetInputBuffer,
  resetCheckTaskData,
  resetCheckJobData,
  resetLinkTaskData,
  resetUnlinkTaskData,
  resetLinkJobData,
  resetUnlinkJobData,
  setGuidedFlag,
  resetGuidedFlag,
  resetSocketConnected,
  setSocketInitialized,
  setSelected,
  resetSelected,
  setAutomaticActionEnabled,
  resetAutomaticActionEnabled
} = monitoringSlice.actions;
export const monitoringFlightsState = (state: RootState) =>
  state.monitoring.flights;
export const monitoringDetailState = (state: RootState) =>
  state.monitoring.detail;
export const monitoringDroneDetailState = (state: RootState) =>
  state.monitoring.droneDetail;
export const monitoringDroneState = (state: RootState) =>
  state.monitoring.drone;
export const monitoringHangarState = (state: RootState) =>
  state.monitoring.hangar;
export const monitoringHangarControlState = (state: RootState) =>
  state.monitoring.hangarControl;
export const cleosListState = (state: RootState) => state.monitoring.cleosList;
export const cleosStartStreamState = (state: RootState) =>
  state.monitoring.startCleosStream;
export const droneState = (state: RootState) => state.monitoring.droneState;
export const droneControlState = (state: RootState) =>
  state.monitoring.droneControl;
export const trackingModeState = (state: RootState) =>
  state.monitoring.trackingMode;
export const cleosActiveStreamsState = (state: RootState) =>
  state.monitoring.cleosActiveStreams;
export const mavlinkState = (state: RootState) => state.monitoring.mavlink;
export const peopleGeoLocationState = (state: RootState) =>
  state.monitoring.peopleGeoLocation;
export const crowdGeoLocationState = (state: RootState) =>
  state.monitoring.crowdGeoLocation;
export const facemaskGeoLocationState = (state: RootState) =>
  state.monitoring.facemaskGeoLocation;
export const weaponGeoLocationState = (state: RootState) =>
  state.monitoring.weaponGeoLocation;
export const crowdGeoLocationFetchState = (state: RootState) =>
  state.monitoring.crowdGeoLocationFetch;
export const facemaskGeoLocationFetchState = (state: RootState) =>
  state.monitoring.facemaskGeoLocationFetch;
export const weaponGeoLocationFetchState = (state: RootState) =>
  state.monitoring.weaponGeoLocationFetch;
export const peopleGeoLocationFetchState = (state: RootState) =>
  state.monitoring.peopleGeoLocationFetch;
export const guidedState = (state: RootState) => state.monitoring.guided;
export const guidedFlagState = (state: RootState) =>
  state.monitoring.guidedFlag;
export const socketConnectedState = (state: RootState) =>
  state.monitoring.socketConnected;
export const valueState = (state: RootState) => state.monitoring.value;
export const mavLinkInitializedState = (state: RootState) =>
  state.monitoring.mavLinkInitialized;
export const inputBufferState = (state: RootState) =>
  state.monitoring.inputBuffer;
export const checkTaskState = (state: RootState) =>
  state.monitoring.checkTaskData;
export const linkJobState = (state: RootState) => state.monitoring.linkJobData;
export const unlinkJobState = (state: RootState) =>
  state.monitoring.unlinkJobData;
export const unlinkTaskState = (state: RootState) =>
  state.monitoring.unlinkTaskData;
export const linkTaskState = (state: RootState) =>
  state.monitoring.linkTaskData;
export const checkJobState = (state: RootState) =>
  state.monitoring.checkJobData;
export const socketInitializedState = (state: RootState) =>
  state.monitoring.socketInitialized;
export const selectedState = (state: RootState) => state.monitoring.selected;
export const automaticActionEnabledState = (state: RootState) =>
  state.monitoring.automaticActionEnabled;
export default monitoringSlice.reducer;
