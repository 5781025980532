import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { NewTaskState } from '../../model/newTaskInterface';

import {
  fetchBoundComune,
  fetchComuni,
  fetchCreateTask,
  fetchDroni,
  fetchProvince,
  fetchRegioni,
  fetchImportTask
} from '../../services/newTaskAPI';
import { openToast } from '../../utility/toast';

const initialState: NewTaskState = {
  regioni: {
    data: null,
    status: 'idle'
  },
  province: {
    data: null,
    status: 'idle'
  },
  comuni: {
    data: null,
    status: 'idle'
  },
  boundComune: {
    data: null,
    status: 'idle'
  },
  newTask: {
    taskData: {
      data: null,
      status: 'idle'
    },
    drawing: false,
    drawingType: ''
  },
  createTask: {
    data: null,
    status: 'idle'
  },
  droni: {
    data: [],
    status: 'idle'
  },
  street: {
    data: null,
    status: 'idle'
  },
  importTask: {
    data: null,
    status: 'idle'
  }
};

export const regioniAsync = createAsyncThunk('newTask/regioni', async () => {
  try {
    const response: any = await fetchRegioni();
    return response.data;
  } catch (e: any) {
    return Promise.reject(e.data ? e.data : e);
  }
});

export const provinceAsync = createAsyncThunk(
  'newTask/province',
  async (regione: string) => {
    try {
      const response: any = await fetchProvince(regione);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const comuniAsync = createAsyncThunk(
  'newTask/comuni',
  async (provincia: string) => {
    try {
      const response: any = await fetchComuni(provincia);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const boundComuneAsync = createAsyncThunk(
  'newTask/boundComune',
  async (data: string) => {
    try {
      const response: any = await fetchBoundComune(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const createTaskAsync = createAsyncThunk(
  'newTask/createTask',
  async (data: any) => {
    try {
      const response: any = await fetchCreateTask(data);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const droniAsync = createAsyncThunk('newTask/droni', async () => {
  try {
    const response: any = await fetchDroni();
    return response.data;
  } catch (e: any) {
    return Promise.reject(e.data ? e.data : e);
  }
});

export const getStreetAsync = createAsyncThunk(
  'street/fetchStreet',
  async (street: string) => {
    try {
      const response: any = await fetch(
        // eslint-disable-next-line max-len
        `https://nominatim.openstreetmap.org/search?q=${street}&format=geojson`
      );
      return response.json();
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const importTaskAsync = createAsyncThunk(
  'newTask/importTask',
  async (data: any) => {
    try {
      const response: any = await fetchImportTask(data);
      if (
        response?.data?.assignAllOk &&
        response?.data?.taskPilotAllOk &&
        response?.data?.layerAllOk &&
        response?.data?.missionAllOk
      ) {
        openToast({
          severity: 'success',
          alertMessage: 'FetchOK'
        });
      } else if (!response?.data?.missionAllOk) {
        openToast({
          severity: 'warning',
          alertMessage: 'Mission not formatted correctly'
        });
      } else if (!response?.data?.assignAllOk) {
        openToast({
          severity: 'warning',
          alertMessage: 'Assign Error: Operator Not Found'
        });
      } else if (!response?.data?.taskPilotAllOk) {
        openToast({
          severity: 'warning',
          alertMessage: 'Assign Error: Pilot Not Found'
        });
      } else if (!response?.data?.layerAllOk) {
        openToast({
          severity: 'warning',
          alertMessage: 'Plot Layers not formatted correctly'
        });
      }
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const newTaskSlice = createSlice({
  name: 'newTask',
  initialState,
  reducers: {
    resetRegioni: (state) => {
      state.regioni.status = 'idle';
      state.regioni.data = null;
    },
    resetProvince: (state) => {
      state.province.status = 'idle';
      state.province.data = null;
    },
    resetComuni: (state) => {
      state.comuni.status = 'idle';
      state.comuni.data = null;
    },
    resetNewTaskData: (state) => {
      state.newTask.taskData.status = 'idle';
      state.newTask.taskData.data = null;
    },
    resetNewTaskDrawing: (state) => {
      state.newTask.drawing = false;
    },
    resetNewTaskDrawingType: (state) => {
      state.newTask.drawingType = '';
    },
    setNewTaskData: (state, action) => {
      state.newTask.taskData.status = 'success';
      state.newTask.taskData.data = action.payload;
    },
    setNewTaskDrawing: (state) => {
      state.newTask.drawing = true;
    },
    setNewTaskDrawingType: (state, action) => {
      state.newTask.drawingType = action.payload;
    },
    resetCreateTask: (state) => {
      state.createTask.status = 'idle';
      state.createTask.data = null;
    },
    resetBoundComune: (state) => {
      state.boundComune.status = 'idle';
      state.boundComune.data = null;
    },
    resetDroni: (state) => {
      state.droni.status = 'idle';
      state.droni.data = [];
    },
    resetStreet: (state) => {
      state.street.status = 'idle';
      state.street.data = null;
    },
    resetImportTaskData: (state) => {
      state.importTask.status = 'idle';
      state.importTask.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(regioniAsync.pending, (state) => {
        state.regioni.status = 'loading';
      })
      .addCase(regioniAsync.fulfilled, (state, action) => {
        state.regioni.status = 'success';
        state.regioni.data = action.payload;
      })
      .addCase(regioniAsync.rejected, (state, action) => {
        state.regioni.status = 'failed';
        state.regioni.data = action.error;
      })
      .addCase(provinceAsync.pending, (state) => {
        state.province.status = 'loading';
      })
      .addCase(provinceAsync.fulfilled, (state, action) => {
        state.province.status = 'success';
        state.province.data = action.payload;
      })
      .addCase(provinceAsync.rejected, (state, action) => {
        state.province.status = 'failed';
        state.province.data = action.error;
      })
      .addCase(comuniAsync.pending, (state) => {
        state.comuni.status = 'loading';
      })
      .addCase(comuniAsync.fulfilled, (state, action) => {
        state.comuni.status = 'success';
        state.comuni.data = action.payload;
      })
      .addCase(comuniAsync.rejected, (state, action) => {
        state.comuni.status = 'failed';
        state.comuni.data = action.error;
      })
      .addCase(boundComuneAsync.pending, (state) => {
        state.boundComune.status = 'loading';
      })
      .addCase(boundComuneAsync.fulfilled, (state, action) => {
        state.boundComune.status = 'success';
        state.boundComune.data = action.payload;
      })
      .addCase(boundComuneAsync.rejected, (state, action) => {
        state.boundComune.status = 'failed';
        state.boundComune.data = action.error;
      })
      .addCase(createTaskAsync.pending, (state) => {
        state.createTask.status = 'loading';
      })
      .addCase(createTaskAsync.fulfilled, (state, action) => {
        state.createTask.status = 'success';
        state.createTask.data = action.payload;
      })
      .addCase(createTaskAsync.rejected, (state, action) => {
        state.createTask.status = 'failed';
        state.createTask.data = action.error;
      })
      .addCase(droniAsync.pending, (state) => {
        state.droni.status = 'loading';
      })
      .addCase(droniAsync.fulfilled, (state, action) => {
        state.droni.status = 'success';
        state.droni.data = action.payload;
      })
      .addCase(droniAsync.rejected, (state, action) => {
        state.droni.status = 'failed';
        state.droni.data = action.error;
      })
      .addCase(getStreetAsync.pending, (state) => {
        state.street.status = 'loading';
      })
      .addCase(getStreetAsync.fulfilled, (state, action) => {
        state.street.status = 'success';
        state.street.data = action.payload;
      })
      .addCase(getStreetAsync.rejected, (state, action) => {
        state.street.status = 'failed';
        state.street.data = action.error;
      })
      .addCase(importTaskAsync.pending, (state) => {
        state.importTask.status = 'loading';
      })
      .addCase(importTaskAsync.fulfilled, (state, action) => {
        state.importTask.status = 'success';
        state.importTask.data = action.payload;
      })
      .addCase(importTaskAsync.rejected, (state, action) => {
        state.importTask.status = 'failed';
        state.importTask.data = action.error;
      });
  }
});

export const {
  resetRegioni,
  resetProvince,
  resetComuni,
  resetNewTaskData,
  resetNewTaskDrawing,
  resetNewTaskDrawingType,
  setNewTaskData,
  setNewTaskDrawing,
  setNewTaskDrawingType,
  resetCreateTask,
  resetBoundComune,
  resetDroni,
  resetStreet,
  resetImportTaskData
} = newTaskSlice.actions;
export const regioniState = (state: RootState) => state.newTask.regioni;
export const provinceState = (state: RootState) => state.newTask.province;
export const comuniState = (state: RootState) => state.newTask.comuni;
export const boundComuneState = (state: RootState) => state.newTask.boundComune;
export const newTaskDataState = (state: RootState) =>
  state.newTask.newTask.taskData;
export const newTaskDrawingState = (state: RootState) =>
  state.newTask.newTask.drawing;
export const newTaskDrawingTypeState = (state: RootState) =>
  state.newTask.newTask.drawingType;
export const createTaskState = (state: RootState) => state.newTask.createTask;
export const droniState = (state: RootState) => state.newTask.droni;
export const streetState = (state: RootState) => state.newTask.street;
export const importTaskState = (state: RootState) => state.newTask.importTask;

export default newTaskSlice.reducer;
