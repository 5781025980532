import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
  forecast: {
    data: null,
    status: 'idle'
  },
  forecastService: false
};

export const getForecastAsync = createAsyncThunk(
  'forecast/fetchForecast',
  async ([lon, lat]: number[]) => {
    try {
      const response: any = await fetch(
        // eslint-disable-next-line max-len
        `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&exclude=hourly,minutely&lang=it&units=metric&appid=dbb4b3fc3df9bf6c908de60bdd7dbc74`
      );
      return response.json();
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const popupForecastSlice = createSlice({
  name: 'forecast',
  initialState,
  reducers: {
    setForecastService: (state,action) => {
      state.forecastService = action.payload;
    },
    resetPopupForecast: (state) => {
      state.forecast.status = 'idle';
      state.forecast.data = null;
    },
    resetForecastService: (state) => {
      state.forecastService = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getForecastAsync.pending, (state) => {
        state.forecast.status = 'loading';
      })
      .addCase(getForecastAsync.fulfilled, (state, action) => {
        state.forecast.status = 'success';
        state.forecast.data = action.payload;
      })
      .addCase(getForecastAsync.rejected, (state: any, action) => {
        state.forecast.status = 'failed';
        state.forecast.data = action.error;
      });
  }
});

export const { setForecastService, resetPopupForecast, resetForecastService } =
  popupForecastSlice.actions;
export const popupForecastState = (state: RootState) => state.popupForecast;
export default popupForecastSlice.reducer;
