import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchDroneTracker } from '../../services/monitoringAPI';
import { DroneTrackerSliceProps } from '../../model/realtimeMonitoringInterface';

const initialState: DroneTrackerSliceProps = {
  beaconTrack: {
    data: null,
    status: 'idle'
  },
  visualTrack: {
    data: null,
    status: 'idle'
  },
  gnssTrack: {
    data: null,
    status: 'idle'
  }
};

export const getBeaconTrackAsync = createAsyncThunk(
  'droneTracker/beaconTrack',
  async ({
    uavId,
    type,
    taskId,
    ctx,
  }: {
    uavId: string;
    type: string;
    taskId: string;
    ctx: any;
  }) => {
    try {
      const response: any = await fetchDroneTracker(uavId, type, taskId, ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getVisualTrackAsync = createAsyncThunk(
  'droneTracker/visualTrack',
  async ({
    uavId,
    type,
    taskId,
    ctx
  }: {
    uavId: string;
    type: string;
    taskId: string;
    ctx: any;
  }) => {
    try {
      const response: any = await fetchDroneTracker(uavId, type, taskId, ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getGnssTrackAsync = createAsyncThunk(
  'droneTracker/gnssTrack',
  async ({
    uavId,
    type,
    taskId,
    ctx
  }: {
    uavId: string;
    type: string;
    taskId: string;
    ctx: any;
  }) => {
    try {
      const response: any = await fetchDroneTracker(uavId, type, taskId, ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const droneTrackerSlice = createSlice({
  name: 'droneTracker',
  initialState,
  reducers: {
    resetBeaconTrack: (state) => {
      state.beaconTrack.status = 'idle';
      state.beaconTrack.data = null;
    },
    resetVisualTrack: (state) => {
      state.visualTrack.status = 'idle';
      state.visualTrack.data = null;
    },
    resetGnssTrack: (state) => {
      state.gnssTrack.status = 'idle';
      state.gnssTrack.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBeaconTrackAsync.pending, (state) => {
        state.beaconTrack.status = 'loading';
      })
      .addCase(getBeaconTrackAsync.fulfilled, (state, action) => {
        state.beaconTrack.status = 'success';
        state.beaconTrack.data = action.payload;
      })
      .addCase(getBeaconTrackAsync.rejected, (state: any, action) => {
        state.beaconTrack.status = 'failed';
        state.beaconTrack.data = action.error;
      })
      .addCase(getVisualTrackAsync.pending, (state) => {
        state.visualTrack.status = 'loading';
      })
      .addCase(getVisualTrackAsync.fulfilled, (state, action) => {
        state.visualTrack.status = 'success';
        state.visualTrack.data = action.payload;
      })
      .addCase(getVisualTrackAsync.rejected, (state: any, action) => {
        state.visualTrack.status = 'failed';
        state.visualTrack.data = action.error;
      })
      .addCase(getGnssTrackAsync.pending, (state) => {
        state.gnssTrack.status = 'loading';
      })
      .addCase(getGnssTrackAsync.fulfilled, (state, action) => {
        state.gnssTrack.status = 'success';
        state.gnssTrack.data = action.payload;
      })
      .addCase(getGnssTrackAsync.rejected, (state: any, action) => {
        state.gnssTrack.status = 'failed';
        state.gnssTrack.data = action.error;
      });
  }
});

export const { resetBeaconTrack, resetVisualTrack, resetGnssTrack } =
  droneTrackerSlice.actions;
export const beaconTrackState = (state: RootState) =>
  state.droneTracker.beaconTrack;
export const visualTrackState = (state: RootState) =>
  state.droneTracker.visualTrack;
export const gnssTrackState = (state: RootState) =>
  state.droneTracker.gnssTrack;
export default droneTrackerSlice.reducer;
