import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchLogin } from '../../services/userAPI';
import { RootState } from '../../app/store';
import { LoginState } from '../../model/loginInterface';
import { openToast } from '../../utility/toast';
import { changeProfilePassword } from '../../services/profileAPI';

const initialState: LoginState = {
  login: { data: null, status: 'idle' },
  changePassword: { data: null, status: 'idle' }
};

export const loginAsync = createAsyncThunk(
  'login/fetchLogin',
  async (data: any) => {
    try {
      const response: any = await fetchLogin(data.username, data.password);
      openToast({
        severity: 'success',
        alertMessage: 'LoginOK'
      });
      return response.data;
    } catch (e: any) {
      if (
        e?.status === 400 &&
        e?.data?.error_description === 'Account is not fully set up'
      ) {
        openToast({
          severity: 'warning',
          alertMessage: 'LoginChangePassword'
        });
        return Promise.reject('FirstAccess');
      } else {
        openToast({
          severity: 'error',
          alertMessage: 'LoginKO'
        });
      }
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const changePasswordAsync = createAsyncThunk(
  'login/changeProfilePassword',
  async ({
    username,
    oldPassword,
    newPassword,
    temporary
  }: {
    username: string;
    oldPassword: string;
    newPassword: string;
    temporary: boolean;
  }) => {
    try {
      const response: any = await changeProfilePassword(
        username,
        oldPassword,
        newPassword,
        temporary
      );
      openToast({
        severity: 'success',
        alertMessage: 'FetchOKChangedPassword'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLogin: (state) => {
      state.login.status = 'idle';
      state.login.data = null;
    },
    resetChangePassword: (state) => {
      state.changePassword.status = 'idle';
      state.changePassword.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.login.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.login.status = 'success';
        state.login.data = action.payload;
      })
      .addCase(loginAsync.rejected, (state: any, action) => {
        state.login.status = 'failed';
        state.login.data = action.error;
      })
      .addCase(changePasswordAsync.pending, (state) => {
        state.changePassword.status = 'loading';
      })
      .addCase(changePasswordAsync.fulfilled, (state, action) => {
        state.changePassword.status = 'success';
        state.changePassword.data = action.payload;
      })
      .addCase(changePasswordAsync.rejected, (state: any, action) => {
        state.changePassword.status = 'failed';
        state.changePassword.data = action.error;
      });
  }
});

export const { resetLogin, resetChangePassword } = loginSlice.actions;
export const loginState = (state: RootState) => state.login.login;
export const changePasswordState = (state: RootState) => state.login.changePassword;
export default loginSlice.reducer;
