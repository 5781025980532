import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ContextState } from '../model/contextInterface';
import { fetchContesti } from '../services/navigazioneAPI';

const initialState: ContextState = {
  data: null,
  status: 'idle'
};

export const contestiAsync = createAsyncThunk(
  'contesti/fetchContesti',
  async () => {
    try {
      const response: any = await fetchContesti();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const contestiSlice = createSlice({
  name: 'contesti',
  initialState,
  reducers: {
    resetContesti: (state) => {
      state.status = 'idle';
      state.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(contestiAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(contestiAsync.fulfilled, (state: any, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(contestiAsync.rejected, (state: any, action) => {
        state.status = 'failed';
        state.data = action.error;
      });
  }
});

export const { resetContesti } = contestiSlice.actions;
export const contestiState = (state: RootState): ContextState => state.contesti;
export default contestiSlice.reducer;