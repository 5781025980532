import { REACT_APP_ADPM_API_KEY } from '../config';
import { get, post } from '../utility/http';

export async function fetchFlights(ctx: string) {
  try {
    const response = await get(
      `/tasks/tasks/ctx/${ctx}?type=drone-mission`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchDroneTracker(
  uavId: string,
  type: string,
  taskId: string,
  ctx: string
) {
  try {
    const response = await get(
      `/tracks/last?uavId=${uavId}&type=${type}&taskId=${taskId}&ctx=${ctx}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchDronePositionPull(uavId: string) {
  try {
    const response = await get(
      `/adpm/drone?id=${uavId}&apikey=${REACT_APP_ADPM_API_KEY}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchDroneState({ id }: { id: any }) {
  try {
    const response = await get(
      `/adpm/drone?id=${id}&apikey=${REACT_APP_ADPM_API_KEY}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchDroneControl({ cmd, uav_id, ...rest }: any) {
  try {
    const response = await post(
      `/adpm/drone?apikey=${REACT_APP_ADPM_API_KEY}`,
      true,
      {},
      {
        cmd,
        uav_id,
        ...rest
      },
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchMavlinkControlGet({
  cmd,
  uav_id,
  port,
  ...rest
}: any) {
  try {
    const response = await get(
      `/mavlink-srv/services/tdromes_${port}/${cmd}`,
      true,
      { uav_id, ...rest },
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchMavlinkControlPost({
  cmd,
  uav_id,
  port,
  ...rest
}: any) {
  try {
    const response = await post(
      `/mavlink-srv/services/tdromes_${port}/${
        cmd === 'loadmission' ? 'upload_mission' : 'goto_loc'
      }`,
      true,
      { uav_id },
      {
        cmd,
        uav_id,
        ...rest
      },
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchMavlinkInitialized({ uav_id, port }: any) {
  try {
    const response = await get(
      `/mavlink-srv/services/tdromes_${port}/initialized?uav_id=${uav_id}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchHangar(hanId: any) {
  try {
    const response = await get(
      `/adpm/hangar?id=${hanId}&apikey=${REACT_APP_ADPM_API_KEY}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchHangarControl({ hangar, hanId }: any) {
  try {
    const response = await post(
      `/adpm/hangar?apikey=${REACT_APP_ADPM_API_KEY}`,
      true,
      {},
      {
        hangar,
        hanId
      },
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchCleosList() {
  try {
    const response = await get(
      '/sedge-api/getDetectionsList',
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchStartCleosStream({
  uav_id,
  which_detection
}: {
  uav_id: string;
  which_detection: string;
}) {
  try {
    const response = await post(
      '/sedge-api/startDetection ',
      true,
      {},
      { uav_id: `${uav_id}`, which_detection },
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchStopCleosStream({
  uav_id,
  which_detection
}: {
  uav_id: string;
  which_detection: string;
}) {
  try {
    const response = await post(
      '/sedge-api/stopDetection ',
      true,
      {},
      { uav_id, which_detection },
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchGeolocation({
  uav_id,
  which_detection
}: {
  uav_id: string;
  which_detection: string;
}) {
  try {
    const response = await get(
      `/sedge-api/getGeolocation?uavId=${uav_id}&detection_type=${which_detection}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchCleosActiveStreams() {
  try {
    const response = await get(
      '/sedge-api/getActiveDetections',
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function linkTask(uavId: any, taskId: any, ctx: any, taskLabel: string) {
  try {
    const response = await post(
      '/tracks/link',
      true,
      { uavId: uavId, taskId: taskId, ctx: ctx, taskLabel: taskLabel },
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function unlinkTask(uavId: any, taskId: any, ctx: any) {
  try {
    const response = await post(
      '/tracks/unlink',
      true,
      { uavId: uavId, taskId: taskId, ctx: ctx },
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function checkTask(uavId: any, taskId: any, ctx: any) {
  try {
    const response = await get(
      '/tracks/check',
      true,
      { uavId: uavId, taskId: taskId, ctx: ctx },
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function linkJob(uavId: any, jobId: any, ctx: any, jobLabel: any) {
  try {
    const response = await post(
      '/tracks/link',
      true,
      { uavId: uavId, jobId: jobId, ctx: ctx, jobLabel: jobLabel },
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function unlinkJob(uavId: any, jobId: any, ctx: any) {
  try {
    const response = await post(
      '/tracks/unlink',
      true,
      { uavId: uavId, jobId: jobId, ctx: ctx },
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function checkJob(uavId: any, jobId: any, ctx: any) {
  try {
    const response = await get(
      '/tracks/check',
      true,
      { uavId: uavId, jobId: jobId, ctx: ctx },
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchSocketConnected(uavId: any, username: any) {
  try {
    const response = await get(
      `/websocket/api/${uavId}/${username}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchRestartService(serviceName: string) {
  try {
    const response = await post(`/dm/restart?name=${serviceName}&state=all`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
