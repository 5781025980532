import { Config } from '@sigeo-registry/config-react';
import type { Settings } from '@sigeo-registry/config-react';
import { version } from '../package.json';

export const NODE_ENV = process.env.NODE_ENV;
export const HOST = process.env.HOST;
export const PORT = process.env.PORT;
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
export const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL || '';
export const REACT_APP_WS_PATH = process.env.REACT_APP_WS_PATH || '';
export const REACT_APP_KEYCLOAK_CLIENT_SECRET =
  process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET;
export const REACT_APP_KEYCLOAK_SCOPE = process.env.REACT_APP_KEYCLOAK_SCOPE;
export const REACT_APP_ADPM_API_KEY = process.env.REACT_APP_ADPM_API_KEY;
export const REACT_APP_KEYCLOAK_REALM =
  process.env.REACT_APP_KEYCLOAK_REALM || '';
export const REACT_APP_KEYCLOAK_CLIENT_ID =
  process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '';
export const REACT_APP_STREAMING_URL =
  process.env.REACT_APP_STREAMING_URL || '';
export const REACT_APP_MAVLINK_NAME =
  process.env.REACT_APP_MAVLINK_NAME || '';
export const REACT_APP_SEDGE_NAME =
  process.env.REACT_APP_SEDGE_NAME || '';
export const REACT_APP_CESIUM_TOKEN =
  process.env.REACT_APP_CESIUM_TOKEN || '';
export const v = version;

const config = new Config({
  map: {
    default: {
      proj: 'EPSG:4326',
      extent: [11, 42, 14, 43],
      defaultPadding: [0, 0, 200, 0]
    }
  }
} as Settings);

export default config;
