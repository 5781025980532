import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { DockerManagerState } from '../../model/dockerManagerInterface';
import {
  fetchListContainer,
  fetchLogContainerName,
  fetchRestartContainerName
} from '../../services/dockerManagerAPI';

const initialState: DockerManagerState = {
  listContainer: { data: null, status: 'idle' },
  logContainer: { data: null, status: 'idle' },
  restartContainer: { data: null, status: 'idle' }
};

export const getListContainerAsync = createAsyncThunk(
  'dockerManager/fetchListContainer',
  async () => {
    try {
      const response: any = await fetchListContainer();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getLogContainerNameAsync = createAsyncThunk(
  'dockerManager/fetchLogContainerName',
  async ({ container, rows }: { container: string; rows: string }) => {
    try {
      const response: any = await fetchLogContainerName({ container, rows });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const restartContainerNameAsync = createAsyncThunk(
  'dockerManager/fetchRestartContainerName',
  async ({ container }: { container: string }) => {
    try {
      const response: any = await fetchRestartContainerName({ container });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const dockerManagerSlice = createSlice({
  name: 'dockerManager',
  initialState,
  reducers: {
    resetListContainer: (state) => {
      state.listContainer.status = 'idle';
      state.listContainer.data = null;
    },
    resetLogContainer: (state) => {
      state.logContainer.status = 'idle';
      state.logContainer.data = null;
    },
    resetRestartContainer: (state) => {
      state.restartContainer.status = 'idle';
      state.restartContainer.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListContainerAsync.pending, (state) => {
        state.listContainer.status = 'loading';
      })
      .addCase(getListContainerAsync.fulfilled, (state: any, action) => {
        state.listContainer.status = 'success';
        state.listContainer.data = action.payload;
      })
      .addCase(getListContainerAsync.rejected, (state: any, action) => {
        state.listContainer.status = 'failed';
        state.listContainer.data = action.error;
      })
      .addCase(getLogContainerNameAsync.pending, (state) => {
        state.logContainer.status = 'loading';
      })
      .addCase(getLogContainerNameAsync.fulfilled, (state: any, action) => {
        state.logContainer.status = 'success';
        state.logContainer.data = action.payload;
      })
      .addCase(getLogContainerNameAsync.rejected, (state: any, action) => {
        state.logContainer.status = 'failed';
        state.logContainer.data = action.error;
      })
      .addCase(restartContainerNameAsync.pending, (state) => {
        state.restartContainer.status = 'loading';
      })
      .addCase(restartContainerNameAsync.fulfilled, (state: any, action) => {
        state.restartContainer.status = 'success';
        state.restartContainer.data = action.payload;
      })
      .addCase(restartContainerNameAsync.rejected, (state: any, action) => {
        state.restartContainer.status = 'failed';
        state.restartContainer.data = action.error;
      });
  }
});

export const { resetListContainer, resetLogContainer, resetRestartContainer } =
  dockerManagerSlice.actions;
export const listContainerState = (state: RootState) =>
  state.dockerManager.listContainer;
export const logContainerState = (state: RootState) =>
  state.dockerManager.logContainer;
export const restartContainerState = (state: RootState) =>
  state.dockerManager.restartContainer;
export default dockerManagerSlice.reducer;
