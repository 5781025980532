import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { NavigationState } from '../../model/navigationInterface';
import { fetchNavigazione, fetchTaskList } from '../../services/navigazioneAPI';
import { addMaskContainer, editMaskContainer, fetchMask } from '../../services/ruoli';
import { openToast } from '../../utility/toast';

const initialState: NavigationState = {
  nav: {
    data: null,
    status: 'idle'
  },
  taskList: {
    data: null,
    status: 'idle'
  },
  valueSearch: {
    data: '',
    status: 'idle'
  },
  masksCont: {
    data: null,
    status: 'idle'
  },
  masksContToAdd: {
    data: null,
    status: 'idle'
  },
  viewModalMask: {
    data: false,
    status: 'idle'
  },
  maskContToEdit:{
    data: null,
    status: 'idle'
  }
};

export const navigazioneAsync = createAsyncThunk(
  'navigazione/fetchNavigazione',
  async (data: any) => {
    try {
      const response: any = await fetchNavigazione(data.id, data.rootContainer);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const taskListAsync = createAsyncThunk(
  'navigazione/fetchTaskList',
  async (data: any) => {
    try {
      const response: any = await fetchTaskList(data.rootContainer);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const masksContAsync = createAsyncThunk(
  'navigazione/fetchMasksCont',
  async (data: any) => {
    try {
      const response: any = await fetchMask(data.rootContainer);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const masksContToEditAsync = createAsyncThunk(
  'navigazione/maskToEdit',
  async (data: any) => {
    try {
      const response: any = await editMaskContainer(data);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);
export const masksContToAddAsync = createAsyncThunk(
  'navigazione/maskToAdd',
  async (data: any) => {
    try {
      const response: any = await addMaskContainer(data);
      openToast({
        severity: 'success',
        alertMessage: 'AddOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'AddKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const navigazioneSlice = createSlice({
  name: 'navigazione',
  initialState,
  reducers: {
    resetNavigazione: (state) => {
      state.nav.status = 'idle';
      state.nav.data = null;
    },
    resetTaskList: (state) => {
      state.taskList.status = 'idle';
      state.taskList.data = null;
    },
    resetMaskCont: (state) => {
      state.masksCont.status = 'idle';
      state.masksCont.data = null;
    },
    setMaskContToAdd: (state, action) => {
      state.masksContToAdd.status = 'idle';
      state.masksContToAdd.data = action.payload;
    },
    resetMaskContToAdd: (state) => {
      state.masksContToAdd.status = 'idle';
      state.masksContToAdd.data = null;
    },
    resetValueSearch: (state) => {
      state.valueSearch.status = 'idle';
      state.valueSearch.data = '';
    },
    setValueSearch: (state, action) => {
      state.valueSearch.data = action.payload;
      state.valueSearch.status = 'idle';
    },
    setViewModalMask: (state, action) => {
      state.viewModalMask.status = 'idle';
      state.viewModalMask.data = action.payload;
    },
    resetViewModalMask: (state) => {
      state.viewModalMask.status = 'idle';
      state.viewModalMask.data = false;
    },
    setMaskContToEdit: (state, action) => {
      state.maskContToEdit.status = 'idle';
      state.maskContToEdit.data = action.payload;
    },
    resetMaskContToEdit: (state) => {
      state.maskContToEdit.status = 'idle';
      state.maskContToEdit.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(navigazioneAsync.pending, (state) => {
        state.nav.status = 'loading';
      })
      .addCase(navigazioneAsync.fulfilled, (state: any, action) => {
        state.nav.status = 'idle';
        state.nav.data = action.payload;
      })
      .addCase(navigazioneAsync.rejected, (state: any, action) => {
        state.nav.status = 'failed';
        state.nav.data = action.error;
      })
      .addCase(taskListAsync.pending, (state) => {
        state.taskList.status = 'loading';
      })
      .addCase(taskListAsync.fulfilled, (state: any, action) => {
        state.taskList.status = 'success';
        state.taskList.data = action.payload;
      })
      .addCase(taskListAsync.rejected, (state: any, action) => {
        state.taskList.status = 'failed';
        state.taskList.data = action.error;
      })
      .addCase(masksContAsync.pending, (state) => {
        state.masksCont.status = 'loading';
      })
      .addCase(masksContAsync.fulfilled, (state: any, action) => {
        state.masksCont.status = 'success';
        state.masksCont.data = action.payload;
      })
      .addCase(masksContAsync.rejected, (state: any, action) => {
        state.masksCont.status = 'failed';
        state.masksCont.data = action.error;
      })
      .addCase(masksContToAddAsync.pending, (state) => {
        state.masksContToAdd.status = 'loading';
      })
      .addCase(masksContToAddAsync.fulfilled, (state: any, action) => {
        state.masksContToAdd.status = 'success';
        state.masksContToAdd.data = action.payload;
      })
      .addCase(masksContToAddAsync.rejected, (state: any, action) => {
        state.masksContToAdd.status = 'failed';
        state.masksContToAdd.data = action.error;
      })
      .addCase(masksContToEditAsync.pending, (state) => {
        state.maskContToEdit.status = 'loading';
      })
      .addCase(masksContToEditAsync.fulfilled, (state: any, action) => {
        state.maskContToEdit.status = 'success';
        state.maskContToEdit.data = action.payload;
      })
      .addCase(masksContToEditAsync.rejected, (state: any, action) => {
        state.maskContToEdit.status = 'failed';
        state.maskContToEdit.data = action.error;
      });
  }
});

export const {
  resetNavigazione,
  resetTaskList,
  resetMaskCont,
  resetValueSearch,
  setValueSearch,
  setViewModalMask,
  resetViewModalMask,
  setMaskContToAdd,
  resetMaskContToAdd,
  setMaskContToEdit,
  resetMaskContToEdit
} = navigazioneSlice.actions;
export const navigazioneState = (state: RootState) => state.navigazione.nav;
export const taskListState = (state: RootState) => state.navigazione.taskList;
export const valueSearchState = (state: RootState) =>
  state.navigazione.valueSearch;
export const viewModalMaskState = (state: RootState) =>
  state.navigazione.viewModalMask;
export const maskContState = (state: RootState) =>
  state.navigazione.masksCont;
export const maskContToAddState = (state: RootState) =>
  state.navigazione.masksContToAdd;
export const maskContToEditState = (state: RootState) =>
  state.navigazione.maskContToEdit;

export default navigazioneSlice.reducer;
