import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { TocState } from '../../model/tocInterface';
import { fetchLayers } from '../../services/tocAPI';

const initialState: TocState = {
  layers: {
    data: [],
    status: 'idle'
  }
};

export const layersAsync = createAsyncThunk(
  'toc/layers',
  async (data: { projects: string; maps: number }) => {
    try {
      const response: any = await fetchLayers(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const tasksSlice = createSlice({
  name: 'toc',
  initialState,
  reducers: {
    resetTocLayers: (state) => {
      state.layers.status = 'idle';
      state.layers.data = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(layersAsync.pending, (state) => {
        state.layers.status = 'loading';
      })
      .addCase(layersAsync.fulfilled, (state, action) => {
        state.layers.status = 'success';
        state.layers.data = action.payload;
      })
      .addCase(layersAsync.rejected, (state, action) => {
        state.layers.status = 'failed';
        state.layers.data = action.error;
      });
  }
});

export const { resetTocLayers } = tasksSlice.actions;
export const tocLayersState = (state: RootState) => state.toc.layers;
export default tasksSlice.reducer;
