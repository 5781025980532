import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
  windService: false
};

export const popupWindSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    setWindService: (state,action) => {
      state.windService = action.payload;
    },
    resetWindService: (state) => {
      state.windService = false;
    }
  }
});

export const {
  setWindService,
  resetWindService
} = popupWindSlice.actions;
export const popupWindState = (state: RootState) => state.popupWind;
export default popupWindSlice.reducer;
