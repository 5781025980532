import { del, get, post, put } from '../utility/http';

export async function fetchUserAsset(type: string) {
  try {
    const response = await get(
      `/asset-management/userhasasset/myassets/${type}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchAsset() {
  try {
    const response = await get('/asset-management/assets', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchLayers({
  projects,
  maps
}: {
  projects: string;
  maps: number;
}) {
  try {
    await post('/layers/clean-cache', true, {}, {});
    const response = await get(
      `/layers/projects/${projects}/maps/${maps}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addLayerToUser({
  projects,
  body
}: {
  projects: string;
  body: any;
}) {
  try {
    const response = await post(
      `/layers/projects/${projects}/layers`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function importLayerToUser(body:any, context:any) {
  try {
    const response = await post(
      `/import/projects/${context}/upload`,
      true,
      {},
      body,
      false,
      false,
      'multipart/form-data',
      300000
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function suspendAssetToUser(body: any, idUserAsset: number) {
  try {
    const response = await put(
      `/asset-management/userhasasset/${idUserAsset}`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeLayerToUser({
  projects,
  id
}: {
  projects: string;
  id: any;
}) {
  try {
    const response = await del(
      `/layers/projects/${projects}/layers/${id}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeImportedLayerToUser({
  projects,
  id,
  runId
}: {
  projects: string;
  id: any;
  runId: string;
}) {
  try {
    const response = await del(
      `/import/delete/${projects}/layer/${id}/run/${runId}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editLayerToUser({
  projects,
  body
}: {
  projects: string;
  body: any;
}) {
  try {
    const response = await put(
      `/layers/projects/${projects}/layers/${body.id}`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
