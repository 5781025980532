import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { TaskState } from '../../model/taskDetailInterface';
import {
  fetchFedUsersCtx,
  fetchFedUsersRolesAndCtx
} from '../../services/pilotAPI';
import {
  fetchTask,
  fetchDroneAssets,
  fetchJobsByTask,
  doActionOnTask,
  doActionOnJob,
  addJob,
  addPeriodicJob,
  editJob,
  fetchCheckFlightOverlap,
  fetchSummaryTask,
  searchLogbookFlight,
  searchLogbookTask
} from '../../services/taskDetailAPI';
import { openToast } from '../../utility/toast';

const initialState: TaskState = {
  task: {
    data: null,
    status: 'idle'
  },
  droneOps: {
    data: null,
    status: 'idle'
  },
  pilots: {
    data: null,
    status: 'idle'
  },
  jobs: {
    data: null,
    status: 'idle'
  },
  droneAssets: {
    data: null,
    status: 'idle'
  },
  addJob: {
    data: null,
    status: 'idle'
  },
  taskAction: {
    data: null,
    status: 'idle'
  },
  jobAction: {
    data: null,
    status: 'idle'
  },
  periodicJob: {
    data: null,
    status: 'idle'
  },
  flightToEdit: {
    data: null,
    status: 'idle'
  },
  modelDronePlan: {
    data: null,
    status: 'idle'
  },
  checkFlightOverlap: {
    data: null,
    status: 'idle'
  },
  taskSummary: {
    data: null,
    status: 'idle'
  },
  logbookSearch: {
    data: null,
    status: 'idle'
  }
};

export const fetchTaskAsync = createAsyncThunk(
  'task/fetchTask',
  async (id: any) => {
    try {
      const response: any = await fetchTask(id);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const fetchDroneAssetsAsync = createAsyncThunk(
  'newTasks/fetchDroneAssets',
  async (role: any) => {
    try {
      const response: any = await fetchDroneAssets(role);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addJobAsync = createAsyncThunk(
  'pilot/addJob',
  async (body: any) => {
    try {
      const response: any = await addJob(body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editJobAsync = createAsyncThunk(
  'pilot/editJob',
  async (body: any) => {
    try {
      const response: any = await editJob(body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

//---------ADD PERIODIC JOB--------------
export const addPeriodicJobAsync = createAsyncThunk(
  'task/addPeriodicJob',
  async (body: any) => {
    try {
      const response: any = await addPeriodicJob(body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const fetchDroneOpsAsync = createAsyncThunk(
  'task/fetchDroneOps',
  async ({ role, ctx }: { role: string; ctx: string }) => {
    try {
      const response: any = await fetchFedUsersRolesAndCtx(role, ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const fetchPilotsAsync = createAsyncThunk(
  'task/fetchPilots',
  async (ctx: string) => {
    try {
      const response: any = await fetchFedUsersCtx(ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const fetchJobsByTaskAsync = createAsyncThunk(
  'task/fetchJobsByTask',
  async (id: any) => {
    try {
      const response: any = await fetchJobsByTask(id);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const doActionOnTaskAsync = createAsyncThunk(
  'task/doActionOnTask',
  async ({
    taskId,
    action,
    ctx,
    targetUser,
    mask
  }: {
    taskId: string;
    action: string;
    ctx: string;
    targetUser?: string;
    mask?: string;
  }) => {
    try {
      const response: any = await doActionOnTask(
        taskId,
        action,
        ctx,
        targetUser,
        mask
      );
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const doActionOnJobAsync = createAsyncThunk(
  'task/doActionOnJob',
  async ({
    jobId,
    action,
    ctx,
    targetUser,
    mask
  }: {
    jobId: string;
    action: string;
    ctx: string;
    targetUser?: string;
    mask?: string;
  }) => {
    try {
      const response: any = await doActionOnJob(
        jobId,
        action,
        ctx,
        targetUser,
        mask
      );
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const checkFlightOverlapAsync = createAsyncThunk(
  'task/fetchCheckFlightOverlap',
  async ({
    startDate,
    endDate,
    altMax,
    altMin,
    buffer,
    geometry,
    jobId = null
  }: {
    startDate: string;
    endDate: string;
    altMax: number;
    altMin: number;
    buffer: number;
    geometry: any;
    jobId?: string | null;
  }) => {
    try {
      const response: any = await fetchCheckFlightOverlap({
        startDate,
        endDate,
        altMax,
        altMin,
        buffer,
        geometry,
        jobId
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const fetchSummaryTaskAsync = createAsyncThunk(
  'task/fetchSummaryTask',
  async ({ uavId, ctx, taskId }: { uavId: any; ctx: any; taskId: any }) => {
    try {
      const response: any = await fetchSummaryTask(uavId, ctx, taskId);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const searchLogbookFlightAsync = createAsyncThunk(
  'task/searchLogbookFlight',
  async ({
    taskId,
    query
  }: {
    taskId: string;
    query: {
      startDate: string | null;
      endDate: string | null;
      pilotName: string | null;
      operatorName: string | null;
      droneModel: string | null;
    };
  }) => {
    try {
      let response: any;
      if (taskId) {
        response = await searchLogbookFlight(taskId, query);
      } else {
        response = await searchLogbookTask(query);
      }      
      return response?.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const taskDetailSlice = createSlice({
  name: 'taskDetail',
  initialState,
  reducers: {
    setFligthToEdit: (state, action) => {
      state.flightToEdit.data = action.payload;
    },
    setModelDronePlan: (state, action) => {
      state.modelDronePlan.data = action.payload;
    },
    resetModelDronePlan: (state) => {
      state.modelDronePlan.data = null;
      state.modelDronePlan.status = 'idle';
    },
    resetFlightToEdit: (state) => {
      state.flightToEdit.data = null;
      state.flightToEdit.status = 'idle';
    },
    resetTaskDetail: (state) => {
      state.task.status = 'idle';
      state.task.data = null;
    },
    resetDroneAssets: (state) => {
      state.droneAssets.status = 'idle';
      state.droneAssets.data = null;
    },
    resetAddJob: (state) => {
      state.addJob.status = 'idle';
      state.addJob.data = null;
    },
    resetDroneOps: (state) => {
      state.droneOps.status = 'idle';
      state.droneOps.data = null;
    },
    resetPilots: (state) => {
      state.pilots.status = 'idle';
      state.pilots.data = null;
    },
    resetJobs: (state) => {
      state.jobs.status = 'idle';
      state.jobs.data = null;
    },
    resetTaskAction: (state) => {
      state.taskAction.status = 'idle';
      state.taskAction.data = null;
    },
    resetJobAction: (state) => {
      state.jobAction.status = 'idle';
      state.jobAction.data = null;
    },
    resetPeriodicJobAction: (state) => {
      state.periodicJob.status = 'idle';
      state.periodicJob.data = null;
    },
    resetCheckFlightOverlap: (state) => {
      state.checkFlightOverlap.status = 'idle';
      state.checkFlightOverlap.data = null;
    },
    resetSummaryTask: (state) => {
      state.taskSummary.status = 'idle';
      state.taskSummary.data = null;
    },
    resetLogbookSearch: (state) => {
      state.logbookSearch.status = 'idle';
      state.taskSummary.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskAsync.pending, (state) => {
        state.task.status = 'loading';
      })
      .addCase(fetchTaskAsync.fulfilled, (state, action) => {
        state.task.status = 'success';
        state.task.data = action.payload;
      })
      .addCase(fetchTaskAsync.rejected, (state, action) => {
        state.task.status = 'failed';
        state.task.data = action.error;
      })
      .addCase(fetchDroneAssetsAsync.pending, (state) => {
        state.droneAssets.status = 'loading';
      })
      .addCase(fetchDroneAssetsAsync.fulfilled, (state, action) => {
        state.droneAssets.status = 'success';
        state.droneAssets.data = action.payload;
      })
      .addCase(fetchDroneAssetsAsync.rejected, (state, action) => {
        state.droneAssets.status = 'failed';
        state.droneAssets.data = action.error;
      })
      .addCase(addJobAsync.pending, (state) => {
        state.addJob.status = 'loading';
      })
      .addCase(addJobAsync.fulfilled, (state, action) => {
        state.addJob.status = 'success';
        state.addJob.data = action.payload;
      })
      .addCase(addJobAsync.rejected, (state, action) => {
        state.addJob.status = 'failed';
        state.addJob.data = action.error;
      })
      .addCase(fetchDroneOpsAsync.pending, (state) => {
        state.droneOps.status = 'loading';
      })
      .addCase(fetchDroneOpsAsync.fulfilled, (state, action) => {
        state.droneOps.status = 'success';
        state.droneOps.data = action.payload;
      })
      .addCase(fetchDroneOpsAsync.rejected, (state, action) => {
        state.droneOps.status = 'failed';
        state.droneOps.data = action.error;
      })
      .addCase(fetchPilotsAsync.pending, (state) => {
        state.pilots.status = 'loading';
      })
      .addCase(fetchPilotsAsync.fulfilled, (state, action) => {
        state.pilots.status = 'success';
        state.pilots.data = action.payload;
      })
      .addCase(fetchPilotsAsync.rejected, (state, action) => {
        state.pilots.status = 'failed';
        state.pilots.data = action.error;
      })
      .addCase(fetchJobsByTaskAsync.pending, (state) => {
        state.jobs.status = 'loading';
      })
      .addCase(fetchJobsByTaskAsync.fulfilled, (state, action) => {
        state.jobs.status = 'success';
        state.jobs.data = action.payload;
      })
      .addCase(fetchJobsByTaskAsync.rejected, (state, action) => {
        state.jobs.status = 'failed';
        state.jobs.data = action.error;
      })
      .addCase(doActionOnTaskAsync.pending, (state) => {
        state.taskAction.status = 'loading';
      })
      .addCase(doActionOnTaskAsync.fulfilled, (state, action) => {
        state.taskAction.status = 'success';
        state.taskAction.data = action.payload;
      })
      .addCase(doActionOnTaskAsync.rejected, (state, action) => {
        state.taskAction.status = 'failed';
        state.taskAction.data = action.error;
      })
      .addCase(doActionOnJobAsync.pending, (state) => {
        state.jobAction.status = 'loading';
      })
      .addCase(doActionOnJobAsync.fulfilled, (state, action) => {
        state.jobAction.status = 'success';
        state.jobAction.data = action.payload;
      })
      .addCase(doActionOnJobAsync.rejected, (state, action) => {
        state.jobAction.status = 'failed';
        state.jobAction.data = action.error;
      })
      .addCase(addPeriodicJobAsync.pending, (state) => {
        state.periodicJob.status = 'loading';
      })
      .addCase(addPeriodicJobAsync.fulfilled, (state, action) => {
        state.periodicJob.status = 'success';
        state.periodicJob.data = action.payload;
      })
      .addCase(addPeriodicJobAsync.rejected, (state, action) => {
        state.periodicJob.status = 'failed';
        state.periodicJob.data = action.error;
      })
      .addCase(editJobAsync.pending, (state) => {
        state.flightToEdit.status = 'loading';
      })
      .addCase(editJobAsync.fulfilled, (state, action) => {
        state.flightToEdit.status = 'success';
        state.flightToEdit.data = action.payload;
      })
      .addCase(editJobAsync.rejected, (state, action) => {
        state.flightToEdit.status = 'failed';
        state.flightToEdit.data = action.error;
      })
      .addCase(checkFlightOverlapAsync.pending, (state) => {
        state.checkFlightOverlap.status = 'loading';
      })
      .addCase(checkFlightOverlapAsync.fulfilled, (state, action) => {
        state.checkFlightOverlap.status = 'success';
        state.checkFlightOverlap.data = action.payload;
      })
      .addCase(checkFlightOverlapAsync.rejected, (state, action) => {
        state.checkFlightOverlap.status = 'failed';
        state.checkFlightOverlap.data = action.error;
      })
      .addCase(fetchSummaryTaskAsync.pending, (state) => {
        state.taskSummary.status = 'loading';
      })
      .addCase(fetchSummaryTaskAsync.fulfilled, (state, action) => {
        state.taskSummary.status = 'success';
        state.taskSummary.data = action.payload;
      })
      .addCase(fetchSummaryTaskAsync.rejected, (state, action) => {
        state.taskSummary.status = 'failed';
        state.taskSummary.data = action.error;
      })
      .addCase(searchLogbookFlightAsync.pending, (state) => {
        state.logbookSearch.status = 'loading';
      })
      .addCase(searchLogbookFlightAsync.fulfilled, (state, action) => {
        state.logbookSearch.status = 'success';
        state.logbookSearch.data = action.payload;
      })
      .addCase(searchLogbookFlightAsync.rejected, (state, action) => {
        state.taskSummary.status = 'failed';
        state.logbookSearch.data = action.error;
      });
  }
});

export const {
  setFligthToEdit,
  resetFlightToEdit,
  resetTaskDetail,
  resetDroneAssets,
  resetDroneOps,
  resetPilots,
  resetJobs,
  resetTaskAction,
  resetJobAction,
  resetAddJob,
  resetPeriodicJobAction,
  setModelDronePlan,
  resetModelDronePlan,
  resetCheckFlightOverlap,
  resetSummaryTask,
  resetLogbookSearch
} = taskDetailSlice.actions;
export const addJobState = (state: RootState) => state.taskDetail.addJob;
export const flightToEditState = (state: RootState) =>
  state.taskDetail.flightToEdit;
export const taskListState = (state: RootState) => state.taskDetail.task;
export const droneAssetsState = (state: RootState) =>
  state.taskDetail.droneAssets;
export const droneOpsState = (state: RootState) => state.taskDetail.droneOps;
export const pilotsState = (state: RootState) => state.taskDetail.pilots;
export const jobsState = (state: RootState) => state.taskDetail.jobs;
export const taskActionState = (state: RootState) =>
  state.taskDetail.taskAction;
export const jobActionState = (state: RootState) => state.taskDetail.jobAction;
export const periodicJobState = (state: RootState) =>
  state.taskDetail.periodicJob;
export const modelDroneState = (state: RootState) =>
  state.taskDetail.modelDronePlan;
export const checkFlightOverlapState = (state: RootState) =>
  state.taskDetail.checkFlightOverlap;
export const taskSummaryState = (state: RootState) =>
  state.taskDetail.taskSummary;
export const logbookSearchState = (state: RootState) =>
  state.taskDetail.logbookSearch;
export default taskDetailSlice.reducer;
