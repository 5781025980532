import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { PilotState } from '../../model/pilotInterface';
import {
  fetchFedUsers,
  addFedUser,
  activateFedUser,
  deleteFedUser,
  getPilotDetailToView,
  getPilotsAviability,
  fetchFedUsersRolesAndCtx,
  editFedUser
} from '../../services/pilotAPI';

const initialState: PilotState = {
  fedUsers: {
    data: null,
    status: 'idle'
  },
  fedUsersRoles: {
    data: null,
    status: 'idle'
  },
  activateFedUser: {
    data: null,
    status: 'idle'
  },
  deleteFedUser: {
    data: null,
    status: 'idle'
  },
  addFedUser: {
    data: null,
    status: 'idle'
  },
  editFedUser: {
    data: null,
    status: 'idle'
  },
  pilotDetail: {
    data: null,
    status: 'idle'
  },
  pilotsTasksList: {
    data: null,
    status: 'idle'
  }
};

export const fedUserAsync = createAsyncThunk(
  'pilot/fetchFedUsers',
  async () => {
    try {
      const response: any = await fetchFedUsers();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const fedUserRolesAsync = createAsyncThunk(
  'pilot/fetchFedUsersRoles',
  async ({ role, ctx }: { role: string; ctx: string }) => {
    try {
      const response: any = await fetchFedUsersRolesAndCtx(role, ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const activateFedUserAsync = createAsyncThunk(
  'pilot/activateFedUser',
  async (data: any) => {
    try {
      const response: any = await activateFedUser(data.id, data.active);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const deleteFedUserAsync = createAsyncThunk(
  'pilot/deleteFedUser',
  async (data: any) => {
    try {
      const response: any = await deleteFedUser(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addFedUserAsync = createAsyncThunk(
  'pilot/addFedUser',
  async (body: any) => {
    try {
      const response: any = await addFedUser(body);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editFedUserAsync = createAsyncThunk(
  'pilot/editFedUser',
  async ({username, body}: {username: string, body: any}) => {
    try {
      const response: any = await editFedUser(username, body);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getPilotTasksAsync = createAsyncThunk(
  'assetManagement/pilotDetail',
  async (username: any) => {
    try {
      const response: any = await getPilotDetailToView(username);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getPilotsAviabilityAsync = createAsyncThunk(
  'assetManagement/pilotAviability',
  async ({ startDate, endDate }: any) => {
    try {
      const response: any = await getPilotsAviability({ startDate, endDate });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

//export const getPilotJobsAsync = createAsyncThunk(
//   'assetManagement/assetJobsList',
//   async (id: any) => {
//     try {
//       console.log(id);
//       const response: any = await getPilotJobs(id);
//       return response.data;
//     } catch (e: any) {
//       return Promise.reject(e.data ? e.data : e);
//     }
//   }
// );

export const pilotSlice = createSlice({
  name: 'pilot',
  initialState,
  reducers: {
    resetFedUsers: (state) => {
      state.fedUsers.status = 'idle';
      state.fedUsers.data = null;
    },
    resetFedUsersRoles: (state) => {
      state.fedUsersRoles.status = 'idle';
      state.fedUsersRoles.data = null;
    },
    resetActivateFedUser: (state) => {
      state.activateFedUser.status = 'idle';
      state.activateFedUser.data = null;
    },
    resetDeleteFedUser: (state) => {
      state.deleteFedUser.status = 'idle';
      state.deleteFedUser.data = null;
    },
    resetAddFedUser: (state) => {
      state.addFedUser.status = 'idle';
      state.addFedUser.data = null;
    },
    resetPilotDetail: (state) => {
      state.pilotDetail.status = 'idle';
      state.pilotDetail.data = null;
    },
    resetPilotTasksList: (state) => {
      state.pilotsTasksList.status = 'idle';
      state.pilotsTasksList.data = null;
    },
    resetEditFedUser: (state) => {
      state.editFedUser.status = 'idle';
      state.editFedUser.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fedUserAsync.pending, (state) => {
        state.fedUsers.status = 'loading';
      })
      .addCase(fedUserAsync.fulfilled, (state: any, action) => {
        state.fedUsers.status = 'success';
        state.fedUsers.data = action.payload;
      })
      .addCase(fedUserAsync.rejected, (state: any, action) => {
        state.fedUsers.status = 'failed';
        state.fedUsers.data = action.error;
      })
      .addCase(fedUserRolesAsync.pending, (state) => {
        state.fedUsersRoles.status = 'loading';
      })
      .addCase(fedUserRolesAsync.fulfilled, (state: any, action) => {
        state.fedUsersRoles.status = 'success';
        state.fedUsersRoles.data = action.payload;
      })
      .addCase(fedUserRolesAsync.rejected, (state: any, action) => {
        state.fedUsersRoles.status = 'failed';
        state.fedUsersRoles.data = action.error;
      })
      .addCase(activateFedUserAsync.pending, (state) => {
        state.activateFedUser.status = 'loading';
      })
      .addCase(activateFedUserAsync.fulfilled, (state: any, action) => {
        state.activateFedUser.status = 'success';
        state.activateFedUser.data = action.payload;
      })
      .addCase(activateFedUserAsync.rejected, (state: any, action) => {
        state.activateFedUser.status = 'failed';
        state.activateFedUser.data = action.error;
      })
      .addCase(deleteFedUserAsync.pending, (state) => {
        state.deleteFedUser.status = 'loading';
      })
      .addCase(deleteFedUserAsync.fulfilled, (state: any, action) => {
        state.deleteFedUser.status = 'success';
        state.deleteFedUser.data = action.payload;
      })
      .addCase(deleteFedUserAsync.rejected, (state: any, action) => {
        state.deleteFedUser.status = 'failed';
        state.deleteFedUser.data = action.error;
      })
      .addCase(addFedUserAsync.pending, (state) => {
        state.addFedUser.status = 'loading';
      })
      .addCase(addFedUserAsync.fulfilled, (state: any, action) => {
        state.addFedUser.status = 'success';
        state.addFedUser.data = action.payload;
      })
      .addCase(addFedUserAsync.rejected, (state: any, action) => {
        state.addFedUser.status = 'failed';
        state.addFedUser.data = action.error;
      })
      .addCase(getPilotsAviabilityAsync.pending, (state) => {
        state.pilotDetail.status = 'loading';
      })
      .addCase(getPilotsAviabilityAsync.fulfilled, (state: any, action) => {
        state.pilotDetail.status = 'success';
        state.pilotDetail.data = action.payload;
      })
      .addCase(getPilotsAviabilityAsync.rejected, (state: any, action) => {
        state.pilotDetail.status = 'failed';
        state.pilotDetail.data = action.error;
      })
      .addCase(getPilotTasksAsync.pending, (state) => {
        state.pilotsTasksList.status = 'loading';
      })
      .addCase(getPilotTasksAsync.fulfilled, (state: any, action) => {
        state.pilotsTasksList.status = 'success';
        state.pilotsTasksList.data = action.payload;
      })
      .addCase(getPilotTasksAsync.rejected, (state: any, action) => {
        state.pilotsTasksList.status = 'failed';
        state.pilotsTasksList.data = action.error;
      })
      .addCase(editFedUserAsync.pending, (state) => {
        state.editFedUser.status = 'loading';
      })
      .addCase(editFedUserAsync.fulfilled, (state: any, action) => {
        state.editFedUser.status = 'success';
        state.editFedUser.data = action.payload;
      })
      .addCase(editFedUserAsync.rejected, (state: any, action) => {
        state.editFedUser.status = 'failed';
        state.editFedUser.data = action.error;
      });
  }
});

export const {
  resetFedUsers,
  resetFedUsersRoles,
  resetActivateFedUser,
  resetDeleteFedUser,
  resetAddFedUser,
  resetPilotDetail,
  resetPilotTasksList,
  resetEditFedUser
} = pilotSlice.actions;
export const fedUsersStatus = (state: RootState) => state.pilot.fedUsers;
export const pilotDetailStatus = (state: RootState) => state.pilot.pilotDetail;
export const pilotTasksListStatus = (state: RootState) =>
  state.pilot.pilotsTasksList;
export const fedUsersRolesStatus = (state: RootState) =>
  state.pilot.fedUsersRoles;
export const activateFedUsersStatus = (state: RootState) =>
  state.pilot.activateFedUser;
export const deleteFedUsersStatus = (state: RootState) =>
  state.pilot.deleteFedUser;
export const addFedUsersStatus = (state: RootState) => state.pilot.addFedUser;
export const editFedUserStatus = (state: RootState) => state.pilot.editFedUser;
export default pilotSlice.reducer;
