import { get, post } from '../utility/http';

export async function fetchRegioni() {
  try {
    const response = await get('/com-istat/v1/regioni', true, {});
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchProvince(regione: string) {
  try {
    const response = await get(
      `/com-istat/v1/province?filter=regione eq ${regione}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchComuni(provincia: string) {
  try {
    const response = await get(
      `/com-istat/v1/comuni?filter=provincia eq ${provincia}&$size=10000`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchBoundComune(comune: string) {
  try {
    const response = await get(
      `/com-istat/v1/comuni?include=extent&srid=4326&filter=comune eq ${comune}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchCreateTask(body: any) {
  try {
    const response = await post(`/tasks/ctx/${body.ctx}/tasks`, true, {}, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchImportTask({
  containerId,
  payload,
  ctx
}: {
  containerId: string;
  payload: any;
  ctx: string;
}) {
  try {
    const response = await post(
      `/import/projects/${ctx}/mdo/container/${containerId}`,
      true,
      {},
      payload,
      false,
      false,
      'multipart/form-data',
      300000
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchDroni() {
  try {
    const response = await get(
      '/asset-management/assets/standalone/true',
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
