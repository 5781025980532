import { get, post, put, del } from '../utility/http';

export async function fetchCategory() {
  try {
    const response = await get('/asset-management/category', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchManufacturer() {
  try {
    const response = await get('/asset-management/manufacturer', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addManufacturer(body: any) {
  try {
    const response = await post(
      '/asset-management/manufacturer',
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editManufacturer(body: any, ManufacturerId: number) {
  try {
    const response = await put(
      `/asset-management/manufacturer/${ManufacturerId}`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeManufacturer(ManufacturerId: number) {
  try {
    const response = await del(
      `/asset-management/manufacturer/${ManufacturerId}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchAsset() {
  try {
    const response = await get('/asset-management/assets', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addAsset(body: any) {
  try {
    const response = await post(
      '/asset-management/assets',
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editAsset(body: any, AssetId: number) {
  try {
    const response = await put(
      `/asset-management/assets/${AssetId}`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeAsset(AssetId: number) {
  try {
    const response = await del(
      `/asset-management/assets/${AssetId}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}