import { get } from '../utility/http';

export async function fetchRegioni(): Promise<any> {
  try {
    const response = await get('/com-istat/v1/regioni', true, {});
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchProvince(regione: string): Promise<any> {
  try {
    const response = await get(`/com-istat/v1/province?filter=regione eq ${regione}`, true, {});
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchComuni(provincia: string): Promise<any> {
  try {
    const response = await get(
      `/com-istat/v1/comuni?filter=provincia eq ${provincia}&$size=10000`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchBoundComune(comune: string): Promise<any> {
  try {
    const response = await get(
      `/com-istat/v1/comuni?include=extent&srid=4326&filter=comune eq ${comune}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchContainerTasks(ctx: string): Promise<any> {
  try {
    const response = await get(
      `/tasks/tasks/ctx/${ctx}?type=drone-mission`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
