import { del, get } from '../utility/http';

export async function fetchListLinked(ctx: string) {
  try {
    const response = await get(
      `/tracks/ctx/${ctx}/listLinked`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function unlistLinked(data: {ctx: any, drRef: number}) {
  try {
    
    const response = await del(
      `/tracks/ctx/${data.ctx}/uavId/${data.drRef}/forceUnlink`,
      true,
      {}
    );
    return response; 
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
