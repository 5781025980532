import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ToastState } from '../../model/toastInterface';

const initialState: ToastState = {
  toast: {
    open: false,
    duration: 5000,
    alertMessage: '',
    severity: 'success'
  }
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.toast = action.payload;
    },
    resetToast: (state) => {
      state.toast = {
        ...state.toast,
        open: false,
      };
    }
  }
});

export const { setToast, resetToast } = toastSlice.actions;
export const toastState = (state: RootState): ToastState => state.toast;
export default toastSlice.reducer;
