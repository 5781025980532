import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ViewAlertsDeleteState } from '../../model/alertsDeleteInterface';


const initialState: ViewAlertsDeleteState = {
  viewAlerts: {
    data: false,
    status: 'idle'
  },
};

export const alertsDeleteSlice = createSlice({
  name: 'alertsDelete',
  initialState,
  reducers:{
    setViewAlerts: (state, action) => {
      state.viewAlerts.status = 'idle';
      state.viewAlerts.data = action.payload;
    },
    resetViewAlerts: (state) => {
      state.viewAlerts.status = 'idle';
      state.viewAlerts.data = false;
    },
  }
});

export const {
  setViewAlerts,
  resetViewAlerts,
} = alertsDeleteSlice.actions;

export const viewAlertsStatus = (state: RootState) =>
  state.alertsDelete.viewAlerts;
export default alertsDeleteSlice.reducer;
