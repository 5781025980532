import { Slide, SlideProps, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { ToastProps } from '../../model/toastInterface';
import { resetToast, toastState } from './toastSlice';
import { useTranslation } from 'react-i18next';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open, duration, severity, alertMessage }: ToastProps =
    useSelector(toastState).toast;

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(resetToast());
  };

  const SlideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="down" />;
  };
  

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={duration}
      TransitionComponent={SlideTransition}
      onClose={closeAlert}
    >
      <Alert onClose={closeAlert} severity={severity} sx={{ width: '100%' }}>
        {t(alertMessage)}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
