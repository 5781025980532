import { MaskCont } from '../model/navigationInterface';
import { get, post, put  } from '../utility/http';


export async function fetchMask(resourceId: any) {
  try {
    const response = await get(
      `/tree/masks/resource/${resourceId}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editMaskContainer(data: MaskCont) {
  try {
    const response = await put(
      `/tree/masks/set-mask/username/${data.username}`,
      true,
      {},
      data
    );
    return response; 
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addMaskContainer(data: MaskCont) {
  try {
    const response = await post(
      `/tree/masks/add-mask/username/${data.username}`,
      true,
      {},
      data
    );
    return response;  
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

