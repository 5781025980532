import { del, get, post, put } from '../utility/http';

export async function fetchMarkers(project: string) {
  try {
    const response = await get(
      `/layers/projects/${project}/markers`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchMarkerDetail(body: any) {
  try {
    const response = await get(
      `/layers/projects/${body.project}/markers/${body.id}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addMarker(body: any) {
  try {
    const response = await post(
      `/layers/projects/${body.project}/markers`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editMarker(body: any) {
  try {
    const response = await put(
      `/layers/projects/${body.project}/markers/${body.id}`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeMarker(body: any) {
  try {
    const response = await del(`/layers/projects/${body.project}/markers/${body.id}`, true, {});
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchSolids(project: string) {
  try {
    const response = await get(
      `/layers/projects/${project}/solids`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addSolid(body: any) {
  try {
    const response = await post(
      `/layers/projects/${body.project}/solids`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeSolid(body: any) {
  try {
    const response = await del(`/layers/projects/${body.project}/solids/${body.id}`, true, {});
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
