import { REACT_APP_KEYCLOAK_REALM, REACT_APP_KEYCLOAK_URL } from '../config';
import { del, get, post, put } from '../utility/http';

export async function fetchKCContexts(): Promise<any> {
  try {
    const response = await get(
      `${REACT_APP_KEYCLOAK_URL}admin/realms/${REACT_APP_KEYCLOAK_REALM}/groups`,
      true,
      {},
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function postContext(bodyKey: any, bodyDB: any): Promise<any> {
  try {
    const response = await Promise.all([
      await post(
        `${REACT_APP_KEYCLOAK_URL}admin/realms/${REACT_APP_KEYCLOAK_REALM}/groups`,
        true,
        {},
        bodyKey,
        true
      ),
      await post('/cnc/ctx', true, {}, bodyDB)
    ]);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function putContext(idK: string, body: any): Promise<any> {
  try {
    const response = await put('/cnc/ctx', true, {}, body?.bodyDB);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function delContext(idK: string, idDB: string): Promise<any> {
  try {
    const response = await Promise.all([
      await del(
        //eslint-disable-next-line max-len
        `${REACT_APP_KEYCLOAK_URL}admin/realms/${REACT_APP_KEYCLOAK_REALM}/groups/${idK}`,
        true,
        {},
        {},
        true
      ),
      await del(`/cnc/ctx/${idDB}`, true, {}, {})
    ]);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
