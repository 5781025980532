import { del, get, post, put } from '../utility/http';

export async function fetchPeriodicJob(idJob: any) {
  try {
    const response = await get(`/cron/extref/${idJob}`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchjobHistory(
  uavId: any,
  ctx: any,
  jobId: any
) {
  try {
    const response = await get(
      `/tracks/summary-no-uavid?ctx=${ctx}&jobId=${jobId}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
//!da modificare i types -> default-mavlink-gnss
export async function fetchjobHistoryDetail(runId: any, types: any) {
  try {
    const response = await get(
      `/tracks/history/runId/${runId}/types/${types}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addPeriodicJob(periodicJob: any) {
  try {
    const response = await post('/cron', true, {}, periodicJob);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editPeriodicJob(body: any, idCron: string) {
  try {
    const response = await put(`/cron/${idCron}`, true, {}, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removePeriodicJob(idCron: string) {
  try {
    const response = await del(`/cron/${idCron}`, true, {});
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchJobsByJobIdTaskId(jobId: string, taskId: string) {
  try {
    const response = await get(
      `/tasks/jobs/${jobId}/task/${taskId}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function uploadAttachFile(formData: any) {
  try {
    const ctx = localStorage.getItem('context');
    const response = await post(
      `/files/files/ctx/${ctx}/upload`,
      true,
      {},
      formData,
      false,
      true,
      'multipart/form-data',
      240000
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchAttachedFiles(runId: string, ctx: string) {
  try {
    const response = await get(
      `/files/files/ctx/${ctx}/parent/${runId}/info`,
      true,
      {
        withBase64: true
      },
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchAttachedVideo(resId: string, ctx: string) {
  try {
    const response = await get(
      `/files/files/download/${resId}/ctx/${ctx}`,
      true,
      {},
      false,
      true,
      'application/json',
      300000
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
