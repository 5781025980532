import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../features/login/loginSlice';
import tokenReducer from '../utility/tokenSlice';
import themeReducer from '../utility/themeSlice';
import fetchLoaderReducer from '../components/fetchLoader/fetchLoaderSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import tasksReducer from '../features/tasks/tasksSlice';
import newTaskReducer from '../features/newTask/newTaskSlice';
import taskDetailReducer from '../features/taskDetail/taskDetailSlice';
import pilotReducer from '../features/pilot/pilotSlice';
import navigazioneReducer from '../features/navigazione/navigazioneSlice';
import contestiReducer from '../utility/contestiSlice';
import assetManagementReducer from '../features/assetManagement/assetManagementSlice';
import resourceManagementReducer from '../features/resourceManagement/resourceManagementSlice';
import assetManagementSrvRdx from '../features/assetManagementService/assetManagementServiceSlice';
import layerManagementReducer from '../features/layerManagement/layerManagementSlice';
import tocReducer from '../components/toc/tocSlice';
import dockerManagerReducer from '../features/dockerManager/dockerManagerSlice';
import popupWeatherReducer from '../components/popupService/popupWeatherSlice';
import popupForecastReducer from '../components/popupService/popupForecastSlice';
import popupWindReducer from '../components/popupService/popupWindSlice';
import popupTasksReducer from '../components/popupService/popupTasksSlice';
import monitoringReducer from '../features/realtimemonitoring/monitoringSlice';
import droneTrackerReducer from '../features/realtimemonitoring/droneTrackerSlice';
import taskToEditReducer from '../features/modifyTask/modifyTaskSlice';
import toastReducer from '../components/toast/toastSlice';
import jobDetailReducer from '../features/jobDetail/jobDetailSlice';
import threatPointReducer from '../components/popupService/threatPointSlice';
import containerManagementReducer from '../features/navigazione/containerManagementSlice';
// eslint-disable-next-line max-len
import containerTypeManagementReducer from '../features/containerTypesManagement/containerTypeManagementSlice';
import alertsDeleteReducer from '../components/alertsDelete/alertsDeleteSlice';
import serviceManagementReducer from '../features/serviceManagement/serviceManagementSlice';
import adminManagementReducer from '../features/adminManagement/adminManagementSlice';
import mapToolsReducer from '../components/mapTools/mapToolsSlice';
import profileReducer from '../features/profile/profileSlice';
import map3DDataReducer from '../components/map3D/map3DSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    token: tokenReducer,
    theme: themeReducer,
    fetchLoader: fetchLoaderReducer,
    dashboard: dashboardReducer,
    tasks: tasksReducer,
    newTask: newTaskReducer,
    taskToEdit: taskToEditReducer,
    taskDetail: taskDetailReducer,
    pilot: pilotReducer,
    navigazione: navigazioneReducer,
    contesti: contestiReducer,
    assetManagement: assetManagementReducer,
    resourceManagement: resourceManagementReducer,
    assetManagementService: assetManagementSrvRdx,
    layerManagement: layerManagementReducer,
    toc: tocReducer,
    dockerManager: dockerManagerReducer,
    popupWeather: popupWeatherReducer,
    popupForecast: popupForecastReducer,
    popupWind: popupWindReducer,
    popupTasks: popupTasksReducer,
    monitoring: monitoringReducer,
    droneTracker: droneTrackerReducer,
    toast: toastReducer,
    jobDetail: jobDetailReducer,
    threatPoint: threatPointReducer,
    containerManagement: containerManagementReducer,
    containerTypeManagement: containerTypeManagementReducer,
    alertsDelete: alertsDeleteReducer,
    serviceManagement: serviceManagementReducer,
    adminManagement: adminManagementReducer,
    mapTools: mapToolsReducer,
    profile: profileReducer,
    map3D: map3DDataReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'map3D/setGeoJSONData',
          'map3D/setEntityData',
          'map3D/setSolid'
        ],
        // Ignore these paths in the state
        ignoredPaths: ['map3D.geoJSONData', 'map3D.entityData', 'map3D.solid']
      }
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
