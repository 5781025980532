import { get, post } from '../utility/http';

export async function fetchLayers({
  projects,
  maps
}: {
  projects: string;
  maps: number;
}) {
  try {
    await post('/layers/clean-cache', true, {}, {});
    const response = await get(
      `/layers/projects/${projects}/maps/${maps}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
