import { RootState } from '../../app/store';
import { openToast } from '../../utility/toast';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serviceManagementState } from '../../model/serviceManagementInterface';
import { fetchListLinked, unlistLinked } from '../../services/serviceManagementAPI';



const initialState: serviceManagementState = {
  listLinked: {
    data: null,
    status: 'idle'
  },
  unlistLinked: {
    data: null,
    status: 'idle'
  },
};

export const listLinkedAsync = createAsyncThunk(
  'serviceManagement/listLinked',
  async (ctx: string) => {
    try {
      const response: any = await fetchListLinked(ctx);
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);
export const unlistLinkedAsync = createAsyncThunk(
  'serviceManagement/unlistLinked',
  async (data: {ctx: string, drRef: number}) => {
    try {
      const response: any = await unlistLinked(data);
      openToast({
        severity: 'success',
        alertMessage: 'Successfully Unlinked'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);



export const serviceManagementSlice = createSlice({
  name: 'serviceManagement',
  initialState,
  reducers: {
    resetlistLinked: (state) => {
      state.listLinked.status = 'idle';
      state.listLinked.data = null;
    },
    resetUnlistLinkedAsync: (state) => {
      state.unlistLinked.status = 'idle';
      state.unlistLinked.data = null;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(listLinkedAsync.pending, (state) => {
        state.listLinked.status = 'loading';
      })
      .addCase(listLinkedAsync.fulfilled, (state, action) => {
        state.listLinked.status = 'success';
        state.listLinked.data = action.payload;
      })
      .addCase(listLinkedAsync.rejected, (state: any, action) => {
        state.listLinked.status = 'failed';
        state.listLinked.data = action.error;
      })
      .addCase(unlistLinkedAsync.pending, (state) => {
        state.unlistLinked.status = 'loading';
      })
      .addCase(unlistLinkedAsync.fulfilled, (state, action) => {
        state.unlistLinked.status = 'success';
        state.unlistLinked.data = action.payload;
      })
      .addCase(unlistLinkedAsync.rejected, (state: any, action) => {
        state.unlistLinked.status = 'failed';
        state.unlistLinked.data = action.error;
      });
  }
});
export const {
  resetlistLinked,
  resetUnlistLinkedAsync
} = serviceManagementSlice.actions;

export const listLinkedState = (state: RootState) =>
  state.serviceManagement.listLinked;
export const unlistLinkedState = (state: RootState) =>
  state.serviceManagement.unlistLinked;

export default serviceManagementSlice.reducer;
