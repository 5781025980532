import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Map3DState } from '../../model/map3DInterface';
import { addSolid, fetchSolids, removeSolid } from '../../services/mapToolsAPI';

export const getSolidsAsync = createAsyncThunk(
  'map3D/fetchSolids',
  async ({ project }: { project: string }) => {
    try {
      const response: any = await fetchSolids(project);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addSolidAsync = createAsyncThunk(
  'map3D/addSolid',
  async ({
    project,
    type,
    username,
    props,
    description,
    label
  }: {
    project: string;
    type: string;
    username: string;
    props: any;
    description: any;
    label: string;
  }) => {
    try {
      const response: any = await addSolid({
        project,
        type,
        username,
        props,
        description,
        label
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const removeSolidAsync = createAsyncThunk(
  'map3D/removeSolid',
  async ({ id, project }: { id: string; project: string }) => {
    try {
      const response: any = await removeSolid({
        id,
        project
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

const initialState: Map3DState = {
  geoJSONData: null,
  entityData: null,
  droneEntity: null,
  drawPoint: false,
  polygonHeight: 0,
  solid: null,
  solids: {
    data: null,
    status: 'idle'
  },
  addSolid: {
    data: null,
    status: 'idle'
  },
  deleteSolid: {
    data: null,
    status: 'idle'
  },
  trackEntity: {
    id: null,
    follow: false
  }
};

export const map3DSlice = createSlice({
  name: 'map3D',
  initialState,
  reducers: {
    setGeoJSONData: (state, action) => {
      state.geoJSONData = action.payload;
    },
    resetGeoJSONData: (state) => {
      state.geoJSONData = null;
    },
    setEntityData: (state, action) => {
      state.entityData = action.payload;
    },
    resetEntityData: (state) => {
      state.entityData = null;
    },
    setDroneEntity: (state, action) => {
      state.droneEntity = action.payload;
    },
    resetDroneEntity: (state) => {
      state.droneEntity = null;
    },
    setDraw3DPoint: (state, action) => {
      state.drawPoint = action.payload;
    },
    resetDraw3DPoint: (state) => {
      state.drawPoint = false;
    },
    setPolygonHeight: (state, action) => {
      state.polygonHeight = action.payload;
    },
    resetPolygonHeight: (state) => {
      state.polygonHeight = 0;
    },
    setSolid: (state, action) => {
      state.solid = action.payload;
    },
    resetSolid: (state) => {
      state.solid = null;
    },
    resetSolids: (state) => {
      state.solids.status = 'idle';
      state.solids.data = null;
    },
    resetAddSolid: (state) => {
      state.addSolid.status = 'idle';
      state.addSolid.data = null;
    },
    resetDeleteSolid: (state) => {
      state.deleteSolid.status = 'idle';
      state.deleteSolid.data = null;
    },
    setTrackEntity: (state, action) => {
      state.trackEntity.id = action.payload;
      state.trackEntity.follow = true;
    },
    resetTrackEntity: (state) => {
      state.trackEntity.id = null;
      state.trackEntity.follow = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSolidsAsync.pending, (state) => {
        state.solids.status = 'loading';
      })
      .addCase(getSolidsAsync.fulfilled, (state: any, action) => {
        state.solids.status = 'success';
        state.solids.data = action.payload;
      })
      .addCase(getSolidsAsync.rejected, (state: any, action) => {
        state.solids.status = 'failed';
        state.solids.data = action.error;
      })
      .addCase(addSolidAsync.pending, (state) => {
        state.addSolid.status = 'loading';
      })
      .addCase(addSolidAsync.fulfilled, (state: any, action) => {
        state.addSolid.status = 'success';
        state.addSolid.data = action.payload;
      })
      .addCase(addSolidAsync.rejected, (state: any, action) => {
        state.addSolid.status = 'failed';
        state.addSolid.data = action.error;
      })
      .addCase(removeSolidAsync.pending, (state) => {
        state.deleteSolid.status = 'loading';
      })
      .addCase(removeSolidAsync.fulfilled, (state: any, action) => {
        state.deleteSolid.status = 'success';
        state.deleteSolid.data = action.payload;
      })
      .addCase(removeSolidAsync.rejected, (state: any, action) => {
        state.deleteSolid.status = 'failed';
        state.deleteSolid.data = action.error;
      });
  }
});

export const {
  setGeoJSONData,
  resetGeoJSONData,
  setEntityData,
  resetEntityData,
  setDroneEntity,
  resetDroneEntity,
  setDraw3DPoint,
  resetDraw3DPoint,
  setPolygonHeight,
  resetPolygonHeight,
  setSolid,
  resetSolid,
  resetSolids,
  resetAddSolid,
  resetDeleteSolid,
  setTrackEntity,
  resetTrackEntity
} = map3DSlice.actions;
export const geoJSONDataState = (state: RootState) => state.map3D.geoJSONData;
export const entityDataState = (state: RootState) => state.map3D.entityData;
export const droneEntityState = (state: RootState) => state.map3D.droneEntity;
export const drawPointState = (state: RootState) => state.map3D.drawPoint;
export const polygonHeightState = (state: RootState) => state.map3D.polygonHeight;
export const solidState = (state: RootState) => state.map3D.solid;
export const solidsDataState = (state: RootState) => state.map3D.solids;
export const addSolidState = (state: RootState) => state.map3D.addSolid;
export const deleteSolidState = (state: RootState) => state.map3D.deleteSolid;
export const trackEntityState = (state: RootState) => state.map3D.trackEntity;
export default map3DSlice.reducer;
