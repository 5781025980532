import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const resources = {
  it: {
    translation: require('./assets/locales/it.json')
  },
  en: {
    translation: require('./assets/locales/en.json')
  }
};

i18n
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // init i18nexta
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
