import { get, post, put } from '../utility/http';

export async function fetchTask(id: string) {
  try {
    const response = await get(`/tasks/tasks/${id}`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function doActionOnTask(
  id: string,
  action: string,
  ctx: string,
  user?: string,
  mask?: string
) {
  try {
    const body = {
      action: action,
      mask: mask || null,
      targetUsers: user ? [user] : []
    };
    const response = await post(
      `/tasks/ctx/${ctx}/tasks/${id}/do`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchDroneAssets(role: any) {
  try {
    const response = await get(
      `/asset-management/userhasasset/${role.toString()}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

// =============================================
// JOB

export async function addJob(job: any) {
  try {
    const ctx = localStorage.getItem('context');
    const response = await post(`/tasks/ctx/${ctx}/jobs/`, true, {}, job);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editJob(job: any) {
  try {
    const response = await put(`/tasks/jobs/${job.id}`, true, {}, job);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

//--------------ADD PERIODIC JOB ---------------

export async function addPeriodicJob(periodicJob: any) {
  try {
    const response = await post('/cron', true, {}, periodicJob);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function doActionOnJob(
  id: string,
  action: string,
  ctx: string,
  user?: string,
  mask?: string
) {
  try {
    const body = {
      action: action,
      mask: mask || null,
      targetUsers: user ? [user] : []
    };
    const response = await post(
      `/tasks/ctx/${ctx}/jobs/${id}/do`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchJobsByTask(taskId: string) {
  try {
    const response = await get(
      `/tasks/jobs/task/${taskId}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchCheckFlightOverlap(body: {
  startDate: string;
  endDate: string;
  altMax: number;
  altMin: number;
  buffer: number;
  geometry: any;
  jobId: string | null;
}) {
  try {
    const response = await post('/data/overlaps', true, {}, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchSummaryTask(uavId: any, ctx: any, taskId: any) {
  try {
    const response = await get(`/data/tasks/${taskId}/duration`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function searchLogbookFlight(
  taskId: string,
  query: {
    startDate: string | null;
    endDate: string | null;
    pilotName: string | null;
    operatorName: string | null;
    droneModel: string | null;
  }
) {
  try {
    const response = await get(`/data/tasks/${taskId}/logbook`, true, query);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function searchLogbookTask(query: {
  startDate: string | null;
  endDate: string | null;
  pilotName: string | null;
  operatorName: string | null;
  droneModel: string | null;
}) {
  try {
    const response = await get('/data/logbook', true, query);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
