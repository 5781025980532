import { get, put } from '../utility/http';

export async function fetchDroni() {
  try {
    const response = await get(
      '/asset-management/assets/standalone/true',
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchBoundComune(comune: string) {
  try {
    const response = await get(
      `/com-istat/v1/comuni?include=extent&srid=4326&filter=comune eq ${comune}`,
      true,
      {},
      false,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchEditTask(body: any) {
  try {
    const response = await put(`/tasks/tasks/${body.id}`, true, {}, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}