import axios, { AxiosRequestConfig } from 'axios';
import {
  getToken,
  removeRefrshToken,
  removeToken,
  setRefreshToken,
  setToken
} from '../utility/tokenSlice';
import {
  REACT_APP_API_URL,
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_CLIENT_SECRET,
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_URL
} from '../config';
import {
  removeLoader,
  setLoader
} from '../components/fetchLoader/fetchLoaderSlice';
import { store } from '../app/store';
import * as qs from 'qs';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

const basePath = REACT_APP_API_URL;

const _getConfig = (
  authorization = false,
  query = {},
  type = 'application/json',
  timeout = 30000,
) => {
  const headers: any = {
    'Content-Type': type
  };
  if (authorization) {
    headers['Authorization'] = `Bearer ${getToken()}`;
  }
  return { headers, timeout: timeout, params: query };
};

const isExpired = (): boolean => {
  const decodedToken: any = jwt_decode(store.getState().token.token ?? '');
  const exp = decodedToken.exp * 1000;
  return moment().isAfter(moment(exp, 'x'));
};

const _refetchIfExpired = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config: AxiosRequestConfig<any> = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({
        client_id: REACT_APP_KEYCLOAK_CLIENT_ID,
        client_secret: REACT_APP_KEYCLOAK_CLIENT_SECRET,
        grant_type: 'refresh_token',
        refresh_token: store.getState().token.refreshToken
      }),
      // eslint-disable-next-line max-len
      url: `${REACT_APP_KEYCLOAK_URL}realms/${REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`
    };
    axios(config)
      .then(function (response) {
        return resolve(response);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
};

export const post = (
  path: string,
  authentication = false,
  query = {},
  body = {},
  getAllPath = false,
  hideLoader = false,
  contentType = 'application/json',
  timeout = 30000,
) => {
  if (!hideLoader) {
    store.dispatch(setLoader());
  }
  return new Promise((resolve, reject) => {
    if (!getAllPath) {
      path = `${basePath}${path}`;
    }
    if (isExpired()) {
      _refetchIfExpired()
        .then((response) => {
          store.dispatch(setToken(response.data.access_token));
          store.dispatch(setRefreshToken(response.data.refresh_token));
          axios
            .post(path, body, _getConfig(authentication, query, contentType, timeout))
            .then(function (response) {
              store.dispatch(removeLoader());
              return resolve(response);
            })
            .catch(function (error) {
              store.dispatch(removeLoader());
              return reject(error);
            });
        })
        .catch(() => {
          store.dispatch(removeLoader());
          store.dispatch(removeToken());
          store.dispatch(removeRefrshToken());
          window.location.replace('login');
        });
    } else {
      axios
        .post(path, body, _getConfig(authentication, query, contentType, timeout))
        .then(function (response) {
          store.dispatch(removeLoader());
          return resolve(response);
        })
        .catch(function (error) {
          store.dispatch(removeLoader());
          return reject(error);
        });
    }
  });
};

export const postFormEncoded = (url: string, data = {}) => {
  store.dispatch(setLoader());
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        qs.stringify(data),
        _getConfig(false, {}, 'application/x-www-form-urlencoded')
      )
      .then(function (response) {
        store.dispatch(removeLoader());
        return resolve(response);
      })
      .catch(function (error) {
        store.dispatch(removeLoader());
        return reject(error);
      });
  });
};

export const put = (
  path: string,
  authentication = false,
  query = {},
  body = {},
  getAllPath = false
) => {
  store.dispatch(setLoader());
  return new Promise((resolve, reject) => {
    if (!getAllPath) {
      path = `${basePath}${path}`;
    }
    if (authentication && isExpired()) {
      _refetchIfExpired()
        .then((response) => {
          store.dispatch(setToken(response.data.access_token));
          store.dispatch(setRefreshToken(response.data.refresh_token));
          axios
            .put(path, body, _getConfig(authentication, query))
            .then(function (response) {
              store.dispatch(removeLoader());
              return resolve(response);
            })
            .catch(function (error) {
              store.dispatch(removeLoader());
              return reject(error);
            });
        })
        .catch(() => {
          store.dispatch(removeLoader());
          store.dispatch(removeToken());
          store.dispatch(removeRefrshToken());
          window.location.replace('login');
        });
    } else {
      axios
        .put(path, body, _getConfig(authentication, query))
        .then(function (response) {
          store.dispatch(removeLoader());
          return resolve(response);
        })
        .catch(function (error) {
          store.dispatch(removeLoader());
          return reject(error);
        });
    }
  });
};

export const get = (
  path: string,
  authentication = false,
  query = {},
  getAllPath = false,
  hideLoader = false,
  contentType = 'application/json',
  timeout = 30000
) => {
  if (!hideLoader) {
    store.dispatch(setLoader());
  }
  return new Promise((resolve, reject) => {
    if (!getAllPath) {
      path = `${basePath}${path}`;
    }
    if (isExpired()) {
      _refetchIfExpired()
        .then((response) => {
          store.dispatch(setToken(response.data.access_token));
          store.dispatch(setRefreshToken(response.data.refresh_token));
          axios
            .get(path, _getConfig(authentication, query, contentType, timeout))
            .then(function (response) {
              store.dispatch(removeLoader());
              return resolve(response);
            })
            .catch(function (error) {
              store.dispatch(removeLoader());
              return reject(error);
            });
        })
        .catch(() => {
          store.dispatch(removeLoader());
          store.dispatch(removeToken());
          store.dispatch(removeRefrshToken());
          window.location.replace('login');
        });
    } else {
      axios
        .get(path, _getConfig(authentication, query, contentType, timeout))
        .then(function (response) {
          store.dispatch(removeLoader());
          return resolve(response);
        })
        .catch(function (error) {
          store.dispatch(removeLoader());
          return reject(error);
        });
    }
  });
};

export const del = (
  path: string,
  authentication = false,
  query = {},
  body = {},
  getAllPath = false
) => {
  store.dispatch(setLoader());
  return new Promise((resolve, reject) => {
    if (!getAllPath) {
      path = `${basePath}${path}`;
    }
    if (isExpired()) {
      _refetchIfExpired()
        .then((response) => {
          store.dispatch(setToken(response.data.access_token));
          store.dispatch(setRefreshToken(response.data.refresh_token));
          axios
            .delete(path, { ..._getConfig(authentication, query), data: body })
            .then(function (response) {
              store.dispatch(removeLoader());
              return resolve(response);
            })
            .catch(function (error) {
              store.dispatch(removeLoader());
              return reject(error);
            });
        })
        .catch(() => {
          store.dispatch(removeLoader());
          store.dispatch(removeToken());
          store.dispatch(removeRefrshToken());
          window.location.replace('login');
        });
    } else {
      axios
        .delete(path, { ..._getConfig(authentication, query), data: body })
        .then(function (response) {
          store.dispatch(removeLoader());
          return resolve(response);
        })
        .catch(function (error) {
          store.dispatch(removeLoader());
          return reject(error);
        });
    }
  });
};
