import { del, get, post, put } from '../utility/http';

export async function fetchContainerType(ctx: any) {
  try {
    const response = await get(
      `/cnc/ctx/${ctx}/containertypes`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addContainerType(form: any) {
  try {
    const response = await post(
      `/cnc/ctx/${form.ctx}/containertypes`,
      true,
      {},
      form.body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editContainerType(form: any) {
  try {
    const response = await put(
      `/cnc/ctx/${form.ctx}/containertypes/${form.id}`,
      true,
      {},
      form.body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeContainerType({ctx, idCont}:{
  ctx: any;
  idCont: any;
}) {
  try {
    const response = await del(
      `/cnc/ctx/${ctx}/containertypes/${idCont}`,
      true,
      {},
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
