import { del, post, put  } from '../utility/http';

export async function addContainer(form: any) {
  try {
    const response = await post(
      `/cnc/ctx/${form.ctx}/containers`,
      true,
      {},
      form.body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
export async function editContainer(form: { ctx: string; body: any; id:any }) {
  try {
    const response = await put(
      `/cnc/ctx/${form.ctx}/containers/${form.id}`, //provvisorio
      true,
      {},
      form.body
    );
  
    return response;
  } catch (e: any) {
    
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeContainer({ ctx, idCont}:{
  ctx: any;
  idCont: any;
}) {
  try {
    const response = await del(
      `/cnc/ctx/${ctx}/containers/${idCont}`, 
      true,
      {}
    );
  
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

