import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  fetchCreateCitizenReport,
  fetchEditCitizenReport,
  fetchThreatPoint
} from '../../services/threatPointAPI';

interface threatPointState {
  threatPoint: {
    data: any;
    status: string;
  };
  citizenReport: {
    data: any;
    status: string;
};
  threatPointService: boolean;
  threatPointPending: any;
  threatPointPendingRemove: any;
  editThreatPoint: {
    data: any;
    status: string;
  };
}
const initialState: threatPointState = {
  threatPoint: {
    data: null,
    status: 'idle'
  },
  citizenReport: {
    data: null,
    status: 'idle'
  },
  threatPointService: false,
  threatPointPending: [],
  threatPointPendingRemove: [],
  editThreatPoint: {
    data: null,
    status: 'idle'
  },
};

export const getThreatPointAsync = createAsyncThunk(
  'threatPoint/fetchThreatPoint',
  async (ctx: string) => {
    try {
      const response: any = await fetchThreatPoint(ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const createCitizenReport = createAsyncThunk(
  'threatPoint/createCitizenReport',
  async (data: any) => {
    try {
      const response: any = await fetchCreateCitizenReport(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editCitizenReport = createAsyncThunk(
  'threatPoint/editCitizenReport',
  async (data: any) => {
    try {
      const response: any = await fetchEditCitizenReport(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const threatPointSlice = createSlice({
  name: 'threatPoint',
  initialState,
  reducers: {
    setThreatPointService: (state,action) => {
      state.threatPointService = action.payload;
    },
    setThreatPointPending: (state,action) => {
      state.threatPointPending.push({
        which_threatPoint: 'pending',
        interval: action.payload
      });
    },
    setThreatPointPendingRemove: (state,action) => {
      state.threatPointPending.push({
        which_threatPoint: 'pending',
        interval: action.payload
      });
    },
    resetThreatPoint: (state) => {
      state.threatPoint.status = 'idle';
      state.threatPoint.data = null;
    },
    resetThreatPointService: (state) => {
      state.threatPointService = false;
    },
    resetThreatPointPending: (state) => {
      state.threatPointPending.forEach((report:any) => clearInterval(report.interval));
      state.threatPointPending = [];
    },
    resetThreatPointPendingRemove: (state) => {
      state.threatPointPendingRemove.forEach((report:any) => clearInterval(report.interval));
      state.threatPointPendingRemove = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThreatPointAsync.pending, (state) => {
        state.threatPoint.status = 'loading';
      })
      .addCase(getThreatPointAsync.fulfilled, (state: any, action) => {
        state.threatPoint.status = 'success';
        state.threatPoint.data = action.payload;
      })
      .addCase(getThreatPointAsync.rejected, (state: any, action) => {
        state.threatPoint.status = 'failed';
        state.threatPoint.data = action.error;
      })
      .addCase(editCitizenReport.pending, (state) => {
        state.editThreatPoint.status = 'loading';
      })
      .addCase(editCitizenReport.fulfilled, (state: any, action) => {
        state.editThreatPoint.status = 'success';
        state.editThreatPoint.data = action.payload;
      })
      .addCase(editCitizenReport.rejected, (state: any, action) => {
        state.editThreatPoint.status = 'failed';
        state.editThreatPoint.data = action.error;
      })
      .addCase(createCitizenReport.pending, (state) => {
        state.citizenReport.status = 'loading';
      })
      .addCase(createCitizenReport.fulfilled, (state: any, action) => {
        state.citizenReport.status = 'success';
        state.citizenReport.data = action.payload;
      })
      .addCase(createCitizenReport.rejected, (state: any, action) => {
        state.citizenReport.status = 'failed';
        state.citizenReport.data = action.error;
      });
  }
});

export const {
  setThreatPointService,
  resetThreatPoint,
  resetThreatPointService,
  setThreatPointPending,
  resetThreatPointPending,
  setThreatPointPendingRemove,
  resetThreatPointPendingRemove
} = threatPointSlice.actions;
export const threatPointState = (state: RootState) => state.threatPoint;
export const citizenReportState = (state: RootState) => state.threatPoint.citizenReport;
export default threatPointSlice.reducer;
