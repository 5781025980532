import { postFormEncoded } from '../utility/http';
import {
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_CLIENT_SECRET,
  REACT_APP_KEYCLOAK_SCOPE
} from '../config';

export async function fetchLogin(
  username: string,
  password: string
): Promise<any> {
  try {
    const response = await postFormEncoded(
      `${REACT_APP_KEYCLOAK_URL}realms/${REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
      {
        username,
        password,
        client_id: REACT_APP_KEYCLOAK_CLIENT_ID,
        grant_type: 'password',
        client_secret: REACT_APP_KEYCLOAK_CLIENT_SECRET,
        scope: REACT_APP_KEYCLOAK_SCOPE
      }
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
