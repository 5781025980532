import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { LayerManagementState } from '../../model/layerManagementInterface';
import {
  addLayerToUser,
  removeLayerToUser,
  fetchLayers,
  editLayerToUser,
  importLayerToUser,
  removeImportedLayerToUser
} from '../../services/layerManagementApi';
import { openToast } from '../../utility/toast';

const initialState: LayerManagementState = {
  userAssets: {
    data: null,
    status: 'idle'
  },
  assets: {
    data: null,
    status: 'idle'
  },
  addAsset: {
    data: null,
    status: 'idle'
  },
  removeAsset: {
    data: null,
    status: 'idle'
  },
  editAsset: {
    data: null,
    status: 'idle'
  },
  importLayer: {
    data: null,
    status: 'idle'
  },
  layerModalType: 'TILE'
};

export const userAssetAsync = createAsyncThunk(
  'layerManagement/fetchUserLayer',
  async (data: { projects: string; maps: number }) => {
    try {
      const response: any = await fetchLayers(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const assetAsync = createAsyncThunk(
  'layerManagement/fetchLayers',
  async (data: { projects: string; maps: number }) => {
    try {
      const response: any = await fetchLayers(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addLayerAsync = createAsyncThunk(
  'layerManagement/addLayer',
  async (data: { projects: string; body: any }) => {
    try {
      const response: any = await addLayerToUser(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const importLayerAsync = createAsyncThunk(
  'layerManagement/importLayer',
  async (data: { body: any; context: any }) => {    
    try {
      const response: any = await importLayerToUser(data.body, data.context);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const removeLayerAsync = createAsyncThunk(
  'layerManagement/removeLayer',
  async (data: { projects: string; id: any }) => {
    try {
      const response: any = await removeLayerToUser(data);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const removeImportedLayerAsync = createAsyncThunk(
  'layerManagement/removeImportedLayer',
  async (data: { projects: string; id: any, runId: string }) => {
    try {
      const response: any = await removeImportedLayerToUser(data);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editLayerAsync = createAsyncThunk(
  'layerManagement/editLayer',
  async (data: { projects: string; body: any }) => {
    try {
      const response: any = await editLayerToUser(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const layerManagementSlice = createSlice({
  name: 'layerManagement',
  initialState,
  reducers: {
    resetAssets: (state) => {
      state.assets.status = 'idle';
      state.assets.data = null;
    },
    resetUserAssets: (state) => {
      state.userAssets.status = 'idle';
      state.userAssets.data = null;
    },
    resetAddLayer: (state) => {
      state.addAsset.status = 'idle';
      state.addAsset.data = null;
    },
    resetRemoveLayer: (state) => {
      state.removeAsset.status = 'idle';
      state.removeAsset.data = null;
    },
    resetEditLayer: (state) => {
      state.editAsset.status = 'idle';
      state.editAsset.data = null;
    },
    resetImportLayer: (state) => {
      state.importLayer.status = 'idle';
      state.importLayer.data = null;
    },
    setLayerModalType: (state, action) => {
      state.layerModalType = action.payload;
    },
    reSetLayerModalType: (state) => {
      state.layerModalType = 'TILE';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userAssetAsync.pending, (state) => {
        state.userAssets.status = 'loading';
      })
      .addCase(userAssetAsync.fulfilled, (state: any, action) => {
        state.userAssets.status = 'success';
        state.userAssets.data = action.payload;
      })
      .addCase(userAssetAsync.rejected, (state: any, action) => {
        state.userAssets.status = 'failed';
        state.userAssets.data = action.error;
      })
      .addCase(assetAsync.pending, (state) => {
        state.assets.status = 'loading';
      })
      .addCase(assetAsync.fulfilled, (state: any, action) => {
        state.assets.status = 'success';
        state.assets.data = action.payload;
      })
      .addCase(assetAsync.rejected, (state: any, action) => {
        state.assets.status = 'failed';
        state.assets.data = action.error;
      })
      .addCase(addLayerAsync.pending, (state) => {
        state.addAsset.status = 'loading';
      })
      .addCase(addLayerAsync.fulfilled, (state: any, action) => {
        state.addAsset.status = 'success';
        state.addAsset.data = action.payload;
      })
      .addCase(addLayerAsync.rejected, (state: any, action) => {
        state.addAsset.status = 'failed';
        state.addAsset.data = action.error;
      })
      .addCase(removeLayerAsync.pending, (state) => {
        state.removeAsset.status = 'loading';
      })
      .addCase(removeLayerAsync.fulfilled, (state: any, action) => {
        state.removeAsset.status = 'success';
        state.removeAsset.data = action.payload;
      })
      .addCase(removeLayerAsync.rejected, (state: any, action) => {
        state.removeAsset.status = 'failed';
        state.removeAsset.data = action.error;
      })
      .addCase(removeImportedLayerAsync.pending, (state) => {
        state.removeAsset.status = 'loading';
      })
      .addCase(removeImportedLayerAsync.fulfilled, (state: any, action) => {
        state.removeAsset.status = 'success';
        state.removeAsset.data = action.payload;
      })
      .addCase(removeImportedLayerAsync.rejected, (state: any, action) => {
        state.removeAsset.status = 'failed';
        state.removeAsset.data = action.error;
      })
      .addCase(editLayerAsync.pending, (state) => {
        state.editAsset.status = 'loading';
      })
      .addCase(editLayerAsync.fulfilled, (state: any, action) => {
        state.editAsset.status = 'success';
        state.editAsset.data = action.payload;
      })
      .addCase(editLayerAsync.rejected, (state: any, action) => {
        state.editAsset.status = 'failed';
        state.editAsset.data = action.error;
      })
      .addCase(importLayerAsync.pending, (state) => {
        state.importLayer.status = 'loading';
      })
      .addCase(importLayerAsync.fulfilled, (state: any, action) => {
        state.importLayer.status = 'success';
        state.importLayer.data = action.payload;
      })
      .addCase(importLayerAsync.rejected, (state: any, action) => {
        state.importLayer.status = 'failed';
        state.importLayer.data = action.error;
      });
  }
});

export const {
  resetAssets,
  resetUserAssets,
  resetAddLayer,
  resetRemoveLayer,
  resetEditLayer,
  resetImportLayer,
  setLayerModalType,
  reSetLayerModalType
} = layerManagementSlice.actions;
export const layersStatus = (state: RootState) => state.layerManagement.assets;
export const modalType = (state: RootState) => state.layerManagement.layerModalType;
export const addLayerStatus = (state: RootState) =>
  state.layerManagement.addAsset;
export const userAssetsStatus = (state: RootState) =>
  state.layerManagement.userAssets;
export const removeLayerStatus = (state: RootState) =>
  state.layerManagement.removeAsset;
export const editLayerStatus = (state: RootState) =>
  state.layerManagement.editAsset;
export const importLayerStatus = (state: RootState) =>
  state.layerManagement.importLayer;
export default layerManagementSlice.reducer;
