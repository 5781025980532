import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openToast } from '../../utility/toast';
import { RootState } from '../../app/store';
import { JobDetailState } from '../../model/jobDetailInterface';
import { fetchTask } from '../../services/taskDetailAPI';
import { fetchPeriodicJob,
  addPeriodicJob,
  editPeriodicJob,
  removePeriodicJob, 
  fetchJobsByJobIdTaskId,
  fetchjobHistory, 
  fetchjobHistoryDetail, 
  fetchAttachedFiles,
  fetchAttachedVideo} from '../../services/jobDetailAPI';

const initialState: JobDetailState = {
  task: {
    data: null,
    status: 'idle'
  },
  job: {
    data: null,
    status: 'idle'
  },
  jobHistory: {
    data: null,
    status: 'idle'
  },
  jobHistoryDetail: {
    data: null,
    status: 'idle'
  },
  periodicJob: {
    data: null,
    status: 'idle'
  },
  addPeriodicJob: {
    data: null,
    status: 'idle'
  },
  editPeriodicJob: {
    data: null,
    status: 'idle'
  },
  removePeriodicJob: {
    data: null,
    status: 'idle'
  },
  attachedFiles: {
    data: null,
    status: 'idle'
  },
  attachedVideo: {
    data: null,
    status: 'idle'
  }
};


export const periodicJobAsync = createAsyncThunk(
  'jobDetail/fetchPeriodicJob',
  async (idJob: string) => {
    try {
      const response: any = await fetchPeriodicJob(idJob);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const jobHistoryAsync = createAsyncThunk(
  'jobDetail/fetchHistoryJob',
  async ({uavId,ctx,jobId /*,type,jobId,dateStart,dateEnd*/}: {
    uavId:any, ctx:any,jobId:any /*, type: any,
    jobId: string, dateStart: any, dateEnd: any*/}) => {
    try {
      const response: any = await fetchjobHistory(
        uavId,ctx,jobId/*,type,jobId,dateStart,dateEnd*/);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);
export const jobHistoryDetailAsync = createAsyncThunk(
  'jobDetail/fetchHistoryJobDetail',
  async ({runId,types}: {
    runId:any, types:any}) => {
    try {
      const response: any = await fetchjobHistoryDetail(runId,types);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);


export const editPeriodicJobAsync = createAsyncThunk(
  'jobDetail/editPeriodicJob',
  async (body: any) => {
    try {
      const idCron= body.id;
      const response: any = await editPeriodicJob(body, idCron);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const removePeriodicJobAsync = createAsyncThunk(
  'jobDetail/removePeriodicJob',
  async (idCron: string) => {
    try {
      const response: any = await removePeriodicJob(idCron);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addPeriodicJobAsync = createAsyncThunk(
  'jobDetail/addPeriodicJob',
  async (body: any) => {
    try {
      const response: any = await addPeriodicJob(body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getTaskAsync = createAsyncThunk(
  'jobDetail/fetchTask',
  async (id: any) => {
    try {
      const response: any = await fetchTask(id);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getJobAsync = createAsyncThunk(
  'jobDetail/fetchJobsByTask',
  async ({jobId, taskId}: {jobId: string, taskId: string}) => {
    try {
      const response: any = await fetchJobsByJobIdTaskId(jobId, taskId);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getAttachedFilesAsync = createAsyncThunk(
  'jobDetail/fetchAttachedFiles',
  async ({runId, ctx}: {runId: string, ctx: string}) => {
    try {
      const response: any = await fetchAttachedFiles(runId, ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getAttachedVideoAsync = createAsyncThunk(
  'jobDetail/fetchAttachedVideo',
  async ({resId, ctx}: {resId: string, ctx: string}) => {
    try {
      const response: any = await fetchAttachedVideo(resId, ctx);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const jobDetailSlice = createSlice({
  name: 'jobDetail',
  initialState,
  reducers: {
    resetTask: (state) => {
      state.task.status = 'idle';
      state.task.data = null;
    },
    resetJob: (state) => {
      state.job.status = 'idle';
      state.job.data = null;
    },
    resetJobHistory: (state) => {
      state.jobHistory.status = 'idle';
      state.jobHistory.data = null;
    },
    resetJobHistoryDetail: (state) => {
      state.jobHistoryDetail.status = 'idle';
      state.jobHistoryDetail.data = null;
    },
    resetPeriodicJob: (state) => {
      state.periodicJob.status = 'idle';
      state.periodicJob.data = null;
    },
    resetAddPeriodicJob: (state) => {
      state.addPeriodicJob.status = 'idle';
      state.addPeriodicJob.data = null;
    },
    resetEditPeriodicJob: (state) => {
      state.editPeriodicJob.status = 'idle';
      state.editPeriodicJob.data = null;
    },
    resetRemovePeriodicJob: (state) => {
      state.removePeriodicJob.status = 'idle';
      state.removePeriodicJob.data = null;
    },
    resetAttachedFiles: (state) => {
      state.attachedFiles.status = 'idle';
      state.attachedFiles.data = null;
    },
    resetAttachedVideo: (state) => {
      state.attachedVideo.status = 'idle';
      state.attachedVideo.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(periodicJobAsync.pending, (state) => {
        state.periodicJob.status = 'loading';
      })
      .addCase(periodicJobAsync.fulfilled, (state: any, action) => {
        state.periodicJob.status = 'success';
        state.periodicJob.data = action.payload;
      })
      .addCase(periodicJobAsync.rejected, (state: any, action) => {
        state.periodicJob.status = 'failed';
        state.periodicJob.data = action.error;
      })
      .addCase(jobHistoryAsync.pending, (state) => {
        state.jobHistory.status = 'loading';
      })
      .addCase(jobHistoryAsync.fulfilled, (state: any, action) => {
        state.jobHistory.status = 'success';
        state.jobHistory.data = action.payload;
      })
      .addCase(jobHistoryAsync.rejected, (state: any, action) => {
        state.jobHistory.status = 'failed';
        state.jobHistory.data = action.error;
      })
      .addCase(jobHistoryDetailAsync.pending, (state) => {
        state.jobHistoryDetail.status = 'loading';
      })
      .addCase(jobHistoryDetailAsync.fulfilled, (state: any, action) => {
        state.jobHistoryDetail.status = 'success';
        state.jobHistoryDetail.data = action.payload;
      })
      .addCase(jobHistoryDetailAsync.rejected, (state: any, action) => {
        state.jobHistoryDetail.status = 'failed';
        state.jobHistoryDetail.data = action.error;
      })
      .addCase(editPeriodicJobAsync.pending, (state) => {
        state.editPeriodicJob.status = 'loading';
      })
      .addCase(editPeriodicJobAsync.fulfilled, (state: any, action) => {
        state.editPeriodicJob.status = 'success';
        state.editPeriodicJob.data = action.payload;
      })
      .addCase(editPeriodicJobAsync.rejected, (state: any, action) => {
        state.editPeriodicJob.status = 'failed';
        state.editPeriodicJob.data = action.error;
      })
      .addCase(removePeriodicJobAsync.pending, (state) => {
        state.removePeriodicJob.status = 'loading';
      })
      .addCase(removePeriodicJobAsync.fulfilled, (state: any, action) => {
        state.removePeriodicJob.status = 'success';
        state.removePeriodicJob.data = action.payload;
      })
      .addCase(removePeriodicJobAsync.rejected, (state: any, action) => {
        state.removePeriodicJob.status = 'failed';
        state.removePeriodicJob.data = action.error;
      })
      .addCase(addPeriodicJobAsync.pending, (state) => {
        state.addPeriodicJob.status = 'loading';
      })
      .addCase(addPeriodicJobAsync.fulfilled, (state: any, action) => {
        state.addPeriodicJob.status = 'success';
        state.addPeriodicJob.data = action.payload;
      })
      .addCase(addPeriodicJobAsync.rejected, (state: any, action) => {
        state.addPeriodicJob.status = 'failed';
        state.addPeriodicJob.data = action.error;
      })
      .addCase(getTaskAsync.pending, (state) => {
        state.task.status = 'loading';
      })
      .addCase(getTaskAsync.fulfilled, (state: any, action) => {
        state.task.status = 'success';
        state.task.data = action.payload;
      })
      .addCase(getTaskAsync.rejected, (state: any, action) => {
        state.task.status = 'failed';
        state.task.data = action.error;
      })
      .addCase(getJobAsync.pending, (state) => {
        state.job.status = 'loading';
      })
      .addCase(getJobAsync.fulfilled, (state: any, action) => {
        state.job.status = 'success';
        state.job.data = action.payload;
      })
      .addCase(getJobAsync.rejected, (state: any, action) => {
        state.job.status = 'failed';
        state.job.data = action.error;
      })
      .addCase(getAttachedFilesAsync.pending, (state) => {
        state.attachedFiles.status = 'loading';
      })
      .addCase(getAttachedFilesAsync.fulfilled, (state: any, action) => {
        state.attachedFiles.status = 'success';
        state.attachedFiles.data = action.payload;
      })
      .addCase(getAttachedFilesAsync.rejected, (state: any, action) => {
        state.attachedFiles.status = 'failed';
        state.attachedFiles.data = action.error;
      })
      .addCase(getAttachedVideoAsync.pending, (state) => {
        state.attachedVideo.status = 'loading';
      })
      .addCase(getAttachedVideoAsync.fulfilled, (state: any, action) => {
        state.attachedVideo.status = 'success';
        state.attachedVideo.data = action.payload;
      })
      .addCase(getAttachedVideoAsync.rejected, (state: any, action) => {
        state.attachedVideo.status = 'failed';
        state.attachedVideo.data = action.error;
      });
  }
});

export const {
  resetTask,
  resetJob,
  resetPeriodicJob,
  resetJobHistory,
  resetJobHistoryDetail,
  resetAddPeriodicJob,
  resetEditPeriodicJob,
  resetRemovePeriodicJob,
  resetAttachedFiles,
  resetAttachedVideo
} = jobDetailSlice.actions;
export const periodicJobStatus = (state: RootState) => state.jobDetail.periodicJob;
export const jobHistoryStatus = (state: RootState) => state.jobDetail.jobHistory;
export const jobHistoryDetailStatus = (state: RootState) => state.jobDetail.jobHistoryDetail;
export const addPeriodicJobStatus = (state: RootState) =>
  state.jobDetail.addPeriodicJob;
export const editPeriodicJobStatus = (state: RootState) =>
  state.jobDetail.editPeriodicJob;
export const removePeriodicJobStatus = (state: RootState) =>
  state.jobDetail.removePeriodicJob;
export const taskStatus = (state: RootState) =>
  state.jobDetail.task;
export const jobDetailStatus = (state: RootState) =>
  state.jobDetail.job;
export const attachedFilesStatus = (state: RootState) =>
  state.jobDetail.attachedFiles;
export const attachedVideoStatus = (state: RootState) =>
  state.jobDetail.attachedVideo;
export default jobDetailSlice.reducer;