import { get, put, post, del } from '../utility/http';

export async function fetchFedUsersRoles(roles: any) {
  try {
    const response = await get(`/federation/users/list?roles=${roles}`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchFedUsersRolesAndCtx(roles: any, ctx: string) {
  try {
    const response = await get(`/federation/users/list?roles=${roles}&groups=${ctx}`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchFedUsers() {
  try {
    const response = await get('/federation/users', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchFedUsersCtx(ctx: string) {
  try {
    const response = await get(`/federation/users?groups=${ctx}`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addFedUser(body: any) {
  try {
    const response = await post('/federation/users', true, {}, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editFedUser(username: string, body: any) {
  try {
    const response = await put(`/federation/users/${username}`, true, {}, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function activateFedUser(id: string, active: boolean) {
  try {
    const response = await put(
      `/federation/users/${id}/set-active?active=${active}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function deleteFedUser(id: string) {
  try {
    const response = await del(`/federation/users/${id}`, true, {});
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function getPilotDetailToView(username :any){
  try {
    const response = await get(
      `/data/tasks/users/${username}`,
      true,
      {}
    );
    return response;
  } catch (e:any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function getPilotsAviability({startDate, endDate}:any){
  try {
    const response = await get(
      `/data/tasks/availability?startDatetime=${startDate}&endDatetime=${endDate}`,
      true,
      {}
    );
    return response;
  } catch (e:any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
