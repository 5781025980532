import { del, get, post, put } from '../utility/http';

export async function fetchProfiles() {
  try {
    const response = await get('/users/profiles', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchProfile() {
  try {
    const response = await get('/users/profile', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchProfileByUsername(username: string) {
  try {
    const response = await get(`/users/profile/${username}`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addPicProfile(body: any) {
  try {
    const response = await post(
      '/users/profile/profile-pic',
      true,
      {},
      body,
      false,
      true,
      'multipart/form-data'
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addProfile(body: any) {
  try {
    const response = await post('/users/profile', true, {}, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editDetailsProfile(body: any) {
  try {
    const userRole = localStorage.getItem('userRole') ?? null;
    const isAdmin = userRole && userRole === 'ADMIN' ? true : false;
    const response = await put('/users/profile', true, { isAdmin }, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function editProfileByUsername(body: any) {
  try {
    const isAdmin = body?.userDto?.roles.includes('ADMIN') ? true : false;
    const response = await put(
      `/users/profile/${body?.userDto?.keycloakUser?.username}`,
      true,
      { isAdmin },
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function deleteProfile(username: string) {
  try {
    const response = await del(`/users/profile/${username}`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function changeProfilePassword(
  username: string,
  oldPassword: string,
  newPassword: string,
  temporary: boolean
) {
  try {
    const response = await put(
      '/users/reset-password',
      false,
      {},
      {
        username,
        oldPassword,
        newPassword,
        temporary
      }
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function resetProfilePassword(username: string, password: string) {
  try {
    const response = await put(
      '/users/reset-password',
      true,
      {},
      {
        username,
        oldPassword: 'old',
        newPassword: password,
        temporary: true
      }
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
