import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ProfileState } from '../../model/profileInterface';
import { editDetailsProfile, addPicProfile, fetchProfile } from '../../services/profileAPI';
import { openToast } from '../../utility/toast';

const initialState: ProfileState = {
  
  profileData :{
    data: null,
    status:'idle'
  },

  editProfileData :{
    data: null,
    status:'idle'
  },

  profilePic:{
    data: null,
    status:'idle'
  }

};


export const profileAsync = createAsyncThunk(
  'profile/fetchProfile',
  async () => {
    try {
      const response: any = await fetchProfile();
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editDetailsProfileAsync = createAsyncThunk(
  'profile/EditDetailsProfile',
  async (body: any) => {
    try {
      const response: any = await editDetailsProfile(body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addPicProfileAsync = createAsyncThunk(
  'profile/addPicProfile',
  async (body: any) => {
    try {
      const response: any = await addPicProfile(body);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);


export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfileData: (state) => {
      state.profileData.status = 'idle';
      state.profileData.data = null;
    },
    resetEditProfileData: (state) => {
      state.profileData.status = 'idle';
      state.profileData.data = null;
    },
    resetPicProfile: (state) => {
      state.profilePic.status = 'idle';
      state.profilePic.data = null;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(profileAsync.pending, (state) => {
        state.profileData.status = 'loading';
      })
      .addCase(profileAsync.fulfilled, (state: any, action) => {
        state.profileData.status = 'success';
        state.profileData.data = action.payload;
      })
      .addCase(profileAsync.rejected, (state: any, action) => {
        state.profileData.status = 'failed';
        state.profileData.data = action.error;
      })
      .addCase(addPicProfileAsync.pending, (state) => {
        state.profilePic.status = 'loading';
      })
      .addCase(addPicProfileAsync.fulfilled, (state: any, action) => {
        state.profilePic.status = 'success';
        state.profilePic.data = action.payload;
      })
      .addCase(addPicProfileAsync.rejected, (state: any, action) => {
        state.profilePic.status = 'failed';
        state.profilePic.data = action.error;
      })
      .addCase(editDetailsProfileAsync.pending, (state) => {
        state.editProfileData.status = 'loading';
      })
      .addCase(editDetailsProfileAsync.fulfilled, (state: any, action) => {
        state.editProfileData.status = 'success';
        state.editProfileData.data = action.payload;
      })
      .addCase(editDetailsProfileAsync.rejected, (state: any, action) => {
        state.editProfileData.status = 'failed';
        state.editProfileData.data = action.error;
      });
  }
});

export const {
  resetProfileData,
  resetPicProfile,
  resetEditProfileData
 
} = profileSlice.actions;
export const profileStatus = (state: RootState) => state.profile.profileData;
export const editDetailsProfilStatus = (state: RootState) => state.profile.editProfileData;
export const picProfileStatus = (state: RootState) => state.profile.profilePic;


export default profileSlice.reducer;