import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { AssetManagementServiceState } from '../../model/assetManagementServiceInterface';
import {
  addAsset,
  fetchAsset,
  removeAsset,
  editAsset,
  fetchCategory,
  fetchManufacturer,
  addManufacturer,
  editManufacturer,
  removeManufacturer
} from '../../services/assetManagementServiceAPI';
import { openToast } from '../../utility/toast';

const initialState: AssetManagementServiceState = {
  assets: {
    data: null,
    status: 'idle'
  },
  category: {
    data: null,
    status: 'idle'
  },
  manufacturer: {
    data: null,
    status: 'idle'
  },
  addManufacturer: {
    data: null,
    status: 'idle'
  },
  removeManufacturer: {
    data: null,
    status: 'idle'
  },
  editManufacturer: {
    data: null,
    status: 'idle'
  },
  addAsset: {
    data: null,
    status: 'idle'
  },
  removeAsset: {
    data: null,
    status: 'idle'
  },
  editAsset: {
    data: null,
    status: 'idle'
  },
};

export const categoryAsync = createAsyncThunk(
  'assetManagementService/fetchCategory',
  async () => {
    try {
      const response: any = await fetchCategory();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const manufacturerAsync = createAsyncThunk(
  'assetManagementService/fetchManufacturer',
  async () => {
    try {
      const response: any = await fetchManufacturer();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);


export const addManufacturerAsync = createAsyncThunk(
  'assetManagementService/addManufacturer',
  async (payload: any ) => {
    try {
      const response: any = await addManufacturer(payload.body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      }); 
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const removeManufacturerAsync = createAsyncThunk(
  'assetManagementService/removeManufacturer',
  async (id: any) => {
    try {
      const response: any = await removeManufacturer(id);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'Unable to delete, manufacturer assigned to one or more drones.'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editManufacturerAsync = createAsyncThunk(
  'assetManagementService/editManufacturer',
  async (payload: any) => {
    try {
      const response: any = await editManufacturer(payload.body, payload.id);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      }); 
      return Promise.reject(e.data ? e.data : e);
    }
  }
);


export const assetAsync = createAsyncThunk(
  'assetManagementService/fetchAssets',
  async () => {
    try {
      const response: any = await fetchAsset();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addAssetAsync = createAsyncThunk(
  'assetManagementService/addAsset',
  async (body: any ) => {
    try {
      const response: any = await addAsset(body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      }); 
      return Promise.reject(e.data ? e.data : e);
    }
  }
);


export const removeAssetAsync = createAsyncThunk(
  'assetManagementService/removeAsset',
  async (id: any) => {
    try {
      const response: any = await removeAsset(id);
      openToast({
        severity: 'success',
        alertMessage: 'DeleteAssetOk'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'DeleteAssetKo'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editAssetAsync = createAsyncThunk(
  'assetManagementService/editAsset',
  async (body: any) => {
    try {
      const response: any = await editAsset(body, body.id);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      }); 
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const assetManagementServiceSlice = createSlice({
  name: 'assetManagementService',
  initialState,
  reducers: {
    resetAssets: (state) => {
      state.assets.status = 'idle';
      state.assets.data = null;
    },
    resetAddAsset: (state) => {
      state.addAsset.status = 'idle';
      state.addAsset.data = null;
    },
    resetRemoveAsset: (state) => {
      state.removeAsset.status = 'idle';
      state.removeAsset.data = null;
    },
    resetEditAsset: (state) => {
      state.editAsset.status = 'idle';
      state.editAsset.data = null;
    },
    resetManufacturer: (state) => {
      state.manufacturer.status = 'idle';
      state.manufacturer.data = null;
    },
    resetAddManufacturer: (state) => {
      state.addManufacturer.status = 'idle';
      state.addManufacturer.data = null;
    },
    resetRemoveManufacturer: (state) => {
      state.removeManufacturer.status = 'idle';
      state.removeManufacturer.data = null;
    },
    resetEditManufacturer: (state) => {
      state.editManufacturer.status = 'idle';
      state.editManufacturer.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(assetAsync.pending, (state) => {
        state.assets.status = 'loading';
      })
      .addCase(assetAsync.fulfilled, (state: any, action) => {
        state.assets.status = 'success';
        state.assets.data = action.payload;
      })
      .addCase(assetAsync.rejected, (state: any, action) => {
        state.assets.status = 'failed';
        state.assets.data = action.error;
      })
      .addCase(categoryAsync.pending, (state) => {
        state.category.status = 'loading';
      })
      .addCase(categoryAsync.fulfilled, (state: any, action) => {
        state.category.status = 'success';
        state.category.data = action.payload;
      })
      .addCase(categoryAsync.rejected, (state: any, action) => {
        state.category.status = 'failed';
        state.category.data = action.error;
      })
      .addCase(manufacturerAsync.pending, (state) => {
        state.manufacturer.status = 'loading';
      })
      .addCase(manufacturerAsync.fulfilled, (state: any, action) => {
        state.manufacturer.status = 'success';
        state.manufacturer.data = action.payload;
      })
      .addCase(manufacturerAsync.rejected, (state: any, action) => {
        state.manufacturer.status = 'failed';
        state.manufacturer.data = action.error;
      })
      .addCase(addManufacturerAsync.pending, (state) => {
        state.addManufacturer.status = 'loading';
      })
      .addCase(addManufacturerAsync.fulfilled, (state: any, action) => {
        state.addManufacturer.status = 'success';
        state.addManufacturer.data = action.payload;
      })
      .addCase(addManufacturerAsync.rejected, (state: any, action) => {
        state.addManufacturer.status = 'failed';
        state.addManufacturer.data = action.error;
      })
      .addCase(removeManufacturerAsync.pending, (state) => {
        state.removeManufacturer.status = 'loading';
      })
      .addCase(removeManufacturerAsync.fulfilled, (state: any, action) => {
        state.removeManufacturer.status = 'success';
        state.removeManufacturer.data = action.payload;
      })
      .addCase(removeManufacturerAsync.rejected, (state: any, action) => {
        state.removeManufacturer.status = 'failed';
        state.removeManufacturer.data = action.error;
      })
      .addCase(editManufacturerAsync.pending, (state) => {
        state.editManufacturer.status = 'loading';
      })
      .addCase(editManufacturerAsync.fulfilled, (state: any, action) => {
        state.editManufacturer.status = 'success';
        state.editManufacturer.data = action.payload;
      })
      .addCase(editManufacturerAsync.rejected, (state: any, action) => {
        state.editManufacturer.status = 'failed';
        state.editManufacturer.data = action.error;
      })
      .addCase(addAssetAsync.pending, (state) => {
        state.addAsset.status = 'loading';
      })
      .addCase(addAssetAsync.fulfilled, (state: any, action) => {
        state.addAsset.status = 'success';
        state.addAsset.data = action.payload;
      })
      .addCase(addAssetAsync.rejected, (state: any, action) => {
        state.addAsset.status = 'failed';
        state.addAsset.data = action.error;
      })
      .addCase(removeAssetAsync.pending, (state) => {
        state.removeAsset.status = 'loading';
      })
      .addCase(removeAssetAsync.fulfilled, (state: any, action) => {
        state.removeAsset.status = 'success';
        state.removeAsset.data = action.payload;
      })
      .addCase(removeAssetAsync.rejected, (state: any, action) => {
        state.removeAsset.status = 'failed';
        state.removeAsset.data = action.error;
      })
      .addCase(editAssetAsync.pending, (state) => {
        state.editAsset.status = 'loading';
      })
      .addCase(editAssetAsync.fulfilled, (state: any, action) => {
        state.editAsset.status = 'success';
        state.editAsset.data = action.payload;
      })
      .addCase(editAssetAsync.rejected, (state: any, action) => {
        state.editAsset.status = 'failed';
        state.editAsset.data = action.error;
      });
  }
});

export const {
  resetAssets,
  resetAddAsset,
  resetRemoveAsset,
  resetEditAsset,
  resetAddManufacturer,
  resetEditManufacturer,
  resetManufacturer,
  resetRemoveManufacturer
} = assetManagementServiceSlice.actions;
export const assetsStatus = (state: RootState) => state.assetManagementService.assets;
export const categoryStatus = (state: RootState) => state.assetManagementService.category;
export const manufacturerStatus = (state: RootState) => state.assetManagementService.manufacturer;
export const addAssetStatus = (state: RootState) =>
  state.assetManagementService.addAsset;
export const removeAssetStatus = (state: RootState) =>
  state.assetManagementService.removeAsset;
export const editAssetStatus = (state: RootState) =>
  state.assetManagementService.editAsset;

export const addManufacturerStatus = (state: RootState) =>
  state.assetManagementService.addManufacturer;
export const removeManufacturerStatus = (state: RootState) =>
  state.assetManagementService.removeManufacturer;
export const editManufacturerStatus = (state: RootState) =>
  state.assetManagementService.editManufacturer;


export default assetManagementServiceSlice.reducer;
