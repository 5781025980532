import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ResourceManagementState } from '../../model/resourceManagementInterface';
import { openToast } from '../../utility/toast';
import {
  addUserResource,
  editUserResource,
  fetchResourcesByUser,
  fetchUserResources,
  removeUserResource
} from '../../services/resourceManagementAPI';

const initialState: ResourceManagementState = {
  userResources: {
    data: null,
    status: 'idle'
  },
  addResource: {
    data: null,
    status: 'idle'
  },
  editResource: {
    data: null,
    status: 'idle'
  },
  removeResource: {
    data: null,
    status: 'idle'
  }
};

export const userResourcesAsync = createAsyncThunk(
  'resourceManagement/fetchUserResources',
  async (username: any) => {
    try {
      let response: any;
      if (username) {
        response = await fetchResourcesByUser(username);
      } else {
        response = await fetchUserResources();
      }
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addUserResourceAsync = createAsyncThunk(
  'resourceManagement/addUserResource',
  async ({ body }: { body: any }) => {
    try {
      const response: any = await addUserResource(body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editUserResourceAsync = createAsyncThunk(
  'resourceManagement/editUserResource',
  async ({ body, id }: { body: any; id: number }) => {
    try {
      const response: any = await editUserResource(body, id);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const removeUserResourceAsync = createAsyncThunk(
  'resourceManagement/removeUserResource',
  async (id: number) => {
    try {
      const response: any = await removeUserResource(id);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const resourceManagementSlice = createSlice({
  name: 'resourceManagement',
  initialState,
  reducers: {
    resetUserResources: (state) => {
      state.userResources.status = 'idle';
      state.userResources.data = null;
    },
    resetAddResource: (state) => {
      state.addResource.status = 'idle';
      state.addResource.data = null;
    },
    resetEditResource: (state) => {
      state.editResource.status = 'idle';
      state.editResource.data = null;
    },
    resetRemoveResource: (state) => {
      state.removeResource.status = 'idle';
      state.removeResource.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userResourcesAsync.pending, (state) => {
        state.userResources.status = 'loading';
      })
      .addCase(userResourcesAsync.fulfilled, (state: any, action) => {
        state.userResources.status = 'success';
        state.userResources.data = action.payload;
      })
      .addCase(userResourcesAsync.rejected, (state: any, action) => {
        state.userResources.status = 'failed';
        state.userResources.data = action.error;
      })
      .addCase(addUserResourceAsync.pending, (state) => {
        state.addResource.status = 'loading';
      })
      .addCase(addUserResourceAsync.fulfilled, (state: any, action) => {
        state.addResource.status = 'success';
        state.addResource.data = action.payload;
      })
      .addCase(addUserResourceAsync.rejected, (state: any, action) => {
        state.addResource.status = 'failed';
        state.addResource.data = action.error;
      })
      .addCase(editUserResourceAsync.pending, (state) => {
        state.editResource.status = 'loading';
      })
      .addCase(editUserResourceAsync.fulfilled, (state: any, action) => {
        state.editResource.status = 'success';
        state.editResource.data = action.payload;
      })
      .addCase(editUserResourceAsync.rejected, (state: any, action) => {
        state.editResource.status = 'failed';
        state.editResource.data = action.error;
      })
      .addCase(removeUserResourceAsync.pending, (state) => {
        state.removeResource.status = 'loading';
      })
      .addCase(removeUserResourceAsync.fulfilled, (state: any, action) => {
        state.removeResource.status = 'success';
        state.removeResource.data = action.payload;
      })
      .addCase(removeUserResourceAsync.rejected, (state: any, action) => {
        state.removeResource.status = 'failed';
        state.removeResource.data = action.error;
      });
  }
});

export const {
  resetUserResources,
  resetAddResource,
  resetEditResource,
  resetRemoveResource
} = resourceManagementSlice.actions;

export const userResourcesStatus = (state: RootState) =>
  state.resourceManagement.userResources;
export const addResourceStatus = (state: RootState) =>
  state.resourceManagement.addResource;
export const editResourceStatus = (state: RootState) =>
  state.resourceManagement.editResource;
export const removeResourceStatus = (state: RootState) =>
  state.resourceManagement.removeResource;

export default resourceManagementSlice.reducer;
