import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openToast } from '../../utility/toast';
import { RootState } from '../../app/store';
import { IAdminManagement, IUser } from '../../model/adminManagementInterface';
import {
  delContext,
  fetchKCContexts,
  postContext,
  putContext
} from '../../services/managementContextAPI';
import {
  addProfile,
  deleteProfile,
  editProfileByUsername,
  fetchProfiles,
  resetProfilePassword
} from '../../services/profileAPI';
import { fetchContesti } from '../../services/navigazioneAPI';

const initialState: IAdminManagement = {
  kcContexts: {
    data: null,
    status: 'idle'
  },
  dbContexts: {
    data: null,
    status: 'idle'
  },
  addContext: {
    data: null,
    status: 'idle'
  },
  editContext: {
    data: null,
    status: 'idle'
  },
  deleteContext: {
    data: null,
    status: 'idle'
  },
  users: {
    data: null,
    status: 'idle'
  },
  addUser: {
    data: null,
    status: 'idle'
  },
  editUser: {
    data: null,
    status: 'idle'
  },
  removeUser: {
    data: null,
    status: 'idle'
  },
  resetUserPassword: {
    data: null,
    status: 'idle'
  }
};

export const fetchDBContextsAsync = createAsyncThunk(
  'adminManagement/fetchDBContexts',
  async () => {
    try {
      const response: any = await fetchContesti();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const fetchKCContextsAsync = createAsyncThunk(
  'adminManagement/fetchKCContexts',
  async () => {
    try {
      const response: any = await fetchKCContexts();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addContextAsync = createAsyncThunk(
  'adminManagement/postContext',
  async ({ bodyKey, bodyDB }: { bodyKey: any; bodyDB: any }) => {
    try {
      const response: any = await postContext(bodyKey, bodyDB);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editContextAsync = createAsyncThunk(
  'adminManagement/putContext',
  async ({ idK, body }: { idK: string; body: any }) => {
    try {
      const response: any = await putContext(idK, body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const deleteContextAsync = createAsyncThunk(
  'adminManagement/delContext',
  async ({ idK, idDB }: { idK: string; idDB: string }) => {
    try {
      const response: any = await delContext(idK, idDB);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: e?.data?.errorMessage ?? 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const fetchUsersAsync = createAsyncThunk(
  'adminManagement/fetchProfiles',
  async () => {
    try {
      const response: any = await fetchProfiles();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addUserAsync = createAsyncThunk(
  'adminManagement/addProfile',
  async (body: IUser) => {
    try {
      const response: any = await addProfile(body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editUserAsync = createAsyncThunk(
  'adminManagement/editProfileByUsername',
  async (body: IUser) => {
    try {
      const response: any = await editProfileByUsername(body);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const removeUserAsync = createAsyncThunk(
  'adminManagement/deleteProfile',
  async ({ username }: { username: string }) => {
    try {
      const response: any = await deleteProfile(username);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const resetUserPasswordAsync = createAsyncThunk(
  'adminManagement/resetProfilePassword',
  async ({ username, password }: { username: string; password: string }) => {
    try {
      const response: any = await resetProfilePassword(username, password);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const adminManagementSlice = createSlice({
  name: 'adminManagement',
  initialState,
  reducers: {
    resetKCContexts: (state) => {
      state.kcContexts.status = 'idle';
      state.kcContexts.data = null;
    },
    resetDBContexts: (state) => {
      state.dbContexts.status = 'idle';
      state.dbContexts.data = null;
    },
    resetAddContext: (state) => {
      state.addContext.status = 'idle';
      state.addContext.data = null;
    },
    resetEditContext: (state) => {
      state.editContext.status = 'idle';
      state.editContext.data = null;
    },
    resetDeleteContext: (state) => {
      state.deleteContext.status = 'idle';
      state.deleteContext.data = null;
    },
    resetUsers: (state) => {
      state.users.status = 'idle';
      state.users.data = null;
    },
    resetAddUser: (state) => {
      state.addUser.status = 'idle';
      state.addUser.data = null;
    },
    resetEditUser: (state) => {
      state.editUser.status = 'idle';
      state.editUser.data = null;
    },
    resetRemoveUser: (state) => {
      state.removeUser.status = 'idle';
      state.removeUser.data = null;
    },
    resetResetUserPassword: (state) => {
      state.resetUserPassword.status = 'idle';
      state.resetUserPassword.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDBContextsAsync.pending, (state) => {
        state.dbContexts.status = 'loading';
      })
      .addCase(fetchDBContextsAsync.fulfilled, (state: any, action) => {
        state.dbContexts.status = 'success';
        state.dbContexts.data = action.payload;
      })
      .addCase(fetchDBContextsAsync.rejected, (state: any, action) => {
        state.dbContexts.status = 'failed';
        state.dbContexts.data = action.error;
      })
      .addCase(fetchKCContextsAsync.pending, (state) => {
        state.kcContexts.status = 'loading';
      })
      .addCase(fetchKCContextsAsync.fulfilled, (state: any, action) => {
        state.kcContexts.status = 'success';
        state.kcContexts.data = action.payload;
      })
      .addCase(fetchKCContextsAsync.rejected, (state: any, action) => {
        state.kcContexts.status = 'failed';
        state.kcContexts.data = action.error;
      })
      .addCase(addContextAsync.pending, (state) => {
        state.addContext.status = 'loading';
      })
      .addCase(addContextAsync.fulfilled, (state: any, action) => {
        state.addContext.status = 'success';
        state.addContext.data = action.payload;
      })
      .addCase(addContextAsync.rejected, (state: any, action) => {
        state.addContext.status = 'failed';
        state.addContext.data = action.error;
      })
      .addCase(editContextAsync.pending, (state) => {
        state.editContext.status = 'loading';
      })
      .addCase(editContextAsync.fulfilled, (state: any, action) => {
        state.editContext.status = 'success';
        state.editContext.data = action.payload;
      })
      .addCase(editContextAsync.rejected, (state: any, action) => {
        state.editContext.status = 'failed';
        state.editContext.data = action.error;
      })
      .addCase(deleteContextAsync.pending, (state) => {
        state.deleteContext.status = 'loading';
      })
      .addCase(deleteContextAsync.fulfilled, (state: any, action) => {
        state.deleteContext.status = 'success';
        state.deleteContext.data = action.payload;
      })
      .addCase(deleteContextAsync.rejected, (state: any, action) => {
        state.deleteContext.status = 'failed';
        state.deleteContext.data = action.error;
      })
      .addCase(fetchUsersAsync.pending, (state) => {
        state.users.status = 'loading';
      })
      .addCase(fetchUsersAsync.fulfilled, (state: any, action) => {
        state.users.status = 'success';
        state.users.data = action.payload;
      })
      .addCase(fetchUsersAsync.rejected, (state: any, action) => {
        state.users.status = 'failed';
        state.users.data = action.error;
      })
      .addCase(addUserAsync.pending, (state) => {
        state.addUser.status = 'loading';
      })
      .addCase(addUserAsync.fulfilled, (state: any, action) => {
        state.addUser.status = 'success';
        state.addUser.data = action.payload;
      })
      .addCase(addUserAsync.rejected, (state: any, action) => {
        state.addUser.status = 'failed';
        state.addUser.data = action.error;
      })
      .addCase(editUserAsync.pending, (state) => {
        state.editUser.status = 'loading';
      })
      .addCase(editUserAsync.fulfilled, (state: any, action) => {
        state.editUser.status = 'success';
        state.editUser.data = action.payload;
      })
      .addCase(editUserAsync.rejected, (state: any, action) => {
        state.editUser.status = 'failed';
        state.editUser.data = action.error;
      })
      .addCase(removeUserAsync.pending, (state) => {
        state.removeUser.status = 'loading';
      })
      .addCase(removeUserAsync.fulfilled, (state: any, action) => {
        state.removeUser.status = 'success';
        state.removeUser.data = action.payload;
      })
      .addCase(removeUserAsync.rejected, (state: any, action) => {
        state.removeUser.status = 'failed';
        state.removeUser.data = action.error;
      })
      .addCase(resetUserPasswordAsync.pending, (state) => {
        state.resetUserPassword.status = 'loading';
      })
      .addCase(resetUserPasswordAsync.fulfilled, (state: any, action) => {
        state.resetUserPassword.status = 'success';
        state.resetUserPassword.data = action.payload;
      })
      .addCase(resetUserPasswordAsync.rejected, (state: any, action) => {
        state.resetUserPassword.status = 'failed';
        state.resetUserPassword.data = action.error;
      });
  }
});

export const {
  resetKCContexts,
  resetDBContexts,
  resetAddContext,
  resetEditContext,
  resetDeleteContext,
  resetUsers,
  resetAddUser,
  resetEditUser,
  resetRemoveUser,
  resetResetUserPassword
} = adminManagementSlice.actions;

export const kcContextsState = (state: RootState) =>
  state.adminManagement.kcContexts;
export const dbContextsState = (state: RootState) =>
  state.adminManagement.dbContexts;
export const addContextState = (state: RootState) =>
  state.adminManagement.addContext;
export const editContextState = (state: RootState) =>
  state.adminManagement.editContext;
export const deleteContextState = (state: RootState) =>
  state.adminManagement.deleteContext;
export const usersState = (state: RootState) => state.adminManagement.users;
export const addUserState = (state: RootState) => state.adminManagement.addUser;
export const editUserState = (state: RootState) =>
  state.adminManagement.editUser;
export const removeUserState = (state: RootState) =>
  state.adminManagement.removeUser;
export const resetUserPasswordState = (state: RootState) =>
  state.adminManagement.resetUserPassword;

export default adminManagementSlice.reducer;
