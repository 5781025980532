import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { AssetManagementState } from '../../model/assetManagementInterface';
import {
  addAssetToUser,
  fetchAsset,
  fetchUserAsset,
  getAllAssetBusy,
  getAssetJobs,
  getDroneAvailability,
  getDroneDetailToView,
  getFederatedTo,
  removeAssetToUser,
  suspendAssetToUser
} from '../../services/assetManagementAPI';
import { openToast } from '../../utility/toast';

const initialState: AssetManagementState = {
  userAssets: {
    data: null,
    status: 'idle'
  },
  assets: {
    data: null,
    status: 'idle'
  },
  addAsset: {
    data: null,
    status: 'idle'
  },
  suspendAsset: {
    data: null,
    status: 'idle'
  },
  removeAsset: {
    data: null,
    status: 'idle'
  },
  droneToEdit: {
    data: null,
    status: 'idle'
  },
  droneDetailToView:{
    data:null,
    status: 'idle'
  },
  droneListAvailable:{
    data:null,
    status: 'idle'
  },
  federatedList:{
    data:null,
    status:'idle'
  },
  assetJobsList:{
    data:null,
    status:'idle'
  },
  assetBusyList:{
    data:null,
    status:'idle'
  }
};

export const userAssetAsync = createAsyncThunk(
  'assetManagement/fetchUserAsset',
  async (type: string) => {
    try {
      const response: any = await fetchUserAsset(type);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const assetAsync = createAsyncThunk(
  'assetManagement/fetchAsset',
  async () => {
    try {
      const response: any = await fetchAsset();
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const addAssetAsync = createAsyncThunk(
  'assetManagement/addAsset',
  async (body: any) => {
    try {
      const response: any = await addAssetToUser(body);
      openToast({
        severity: 'success',
        alertMessage: 'AddOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'AddKO'
      }); 
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const suspendAssetAsync = createAsyncThunk(
  'assetManagement/suspendAsset',
  async (body: any) => {
    try {
      const idUserAsset = body.id;
      const response: any = await suspendAssetToUser(body, idUserAsset);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      }); 
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      }); 
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const removeAssetAsync = createAsyncThunk(
  'assetManagement/removeAsset',
  async (idUserAsset: number) => {
    try {
      const response: any = await removeAssetToUser(idUserAsset);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getDroneDetailAsync = createAsyncThunk(
  'assetManagement/droneDetailToView',
  async (id: number) => {
    try {
      const response: any = await getDroneDetailToView(id);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getDroneAvailabilityAsync = createAsyncThunk(
  'assetManagement/droneListAvailable',
  async ({startDate, endDate, role}:any) => {
    try {
      const response:any = await getDroneAvailability({startDate, endDate, role});
      return response.data;
    } catch (e:any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getFederatedToAsync = createAsyncThunk(
  'assetManagement/federatedList',
  async (role: string) => {
    try {
      const response: any = await getFederatedTo(role);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getAssetJobsAsync = createAsyncThunk(
  'assetManagement/assetJobsList',
  async (id: any) => {
    try {
      const response: any = await getAssetJobs(id);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const getAllAssetBusyAsync = createAsyncThunk(
  'assetManagement/assetBusy',
  async ({startDate, endDate, role}:any) => {
    try {
      const response: any = await getAllAssetBusy({startDate, endDate, role});
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const assetManagementSlice = createSlice({
  name: 'assetManagement',
  initialState,
  reducers: {
    resetAssets: (state) => {
      state.assets.status = 'idle';
      state.assets.data = null;
    },
    resetUserAssets: (state) => {
      state.userAssets.status = 'idle';
      state.userAssets.data = null;
    },
    resetAddAsset: (state) => {
      state.addAsset.status = 'idle';
      state.addAsset.data = null;
    },
    resetSuspendAsset: (state) => {
      state.suspendAsset.status = 'idle';
      state.suspendAsset.data = null;
    },
    resetRemoveAsset: (state) => {
      state.removeAsset.status = 'idle';
      state.removeAsset.data = null;
    },
    setDroneToEdit: (state, action) => {
      state.droneToEdit.data = action.payload;
    },
    resetDroneToEdit: (state) => {
      state.droneToEdit.status = 'idle';
      state.droneToEdit.data = null;
    },
    resetDroneDetailToView: (state) => {
      state.droneDetailToView.status = 'idle';
      state.droneDetailToView.data = null;
    },
    resetAlldroneListAvailable: (state) => {
      state.droneListAvailable.status = 'idle';
      state.droneListAvailable.data = null;
    },
    resetFederatedList: (state) => {
      state.federatedList.status = 'idle';
      state.federatedList.data = null;
    },
    resetAssetJobsList: (state) => {
      state.assetJobsList.status = 'idle';
      state.assetJobsList.data = null;
    },
    resetAssetBusyList: (state) => {
      state.assetBusyList.status = 'idle';
      state.assetBusyList.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userAssetAsync.pending, (state) => {
        state.userAssets.status = 'loading';
      })
      .addCase(userAssetAsync.fulfilled, (state: any, action) => {
        state.userAssets.status = 'success';
        state.userAssets.data = action.payload;
      })
      .addCase(userAssetAsync.rejected, (state: any, action) => {
        state.userAssets.status = 'failed';
        state.userAssets.data = action.error;
      })
      .addCase(assetAsync.pending, (state) => {
        state.assets.status = 'loading';
      })
      .addCase(assetAsync.fulfilled, (state: any, action) => {
        state.assets.status = 'success';
        state.assets.data = action.payload;
      })
      .addCase(assetAsync.rejected, (state: any, action) => {
        state.assets.status = 'failed';
        state.assets.data = action.error;
      })
      .addCase(addAssetAsync.pending, (state) => {
        state.addAsset.status = 'loading';
      })
      .addCase(addAssetAsync.fulfilled, (state: any, action) => {
        state.addAsset.status = 'success';
        state.addAsset.data = action.payload;
      })
      .addCase(addAssetAsync.rejected, (state: any, action) => {
        state.addAsset.status = 'failed';
        state.addAsset.data = action.error;
      })
      .addCase(suspendAssetAsync.pending, (state) => {
        state.suspendAsset.status = 'loading';
      })
      .addCase(suspendAssetAsync.fulfilled, (state: any, action) => {
        state.suspendAsset.status = 'success';
        state.suspendAsset.data = action.payload;
      })
      .addCase(suspendAssetAsync.rejected, (state: any, action) => {
        state.suspendAsset.status = 'failed';
        state.suspendAsset.data = action.error;
      })
      .addCase(removeAssetAsync.pending, (state) => {
        state.removeAsset.status = 'loading';
      })
      .addCase(removeAssetAsync.fulfilled, (state: any, action) => {
        state.removeAsset.status = 'success';
        state.removeAsset.data = action.payload;
      })
      .addCase(removeAssetAsync.rejected, (state: any, action) => {
        state.removeAsset.status = 'failed';
        state.removeAsset.data = action.error;
      })
      .addCase(getDroneDetailAsync.pending, (state) => {
        state.droneDetailToView.status = 'loading';
      })
      .addCase(getDroneDetailAsync.fulfilled, (state: any, action) => {
        state.droneDetailToView.status = 'success';
        state.droneDetailToView.data = action.payload;
      })
      .addCase(getDroneDetailAsync.rejected, (state: any, action) => {
        state.droneDetailToView.status = 'failed';
        state.droneDetailToView.data = action.error;
      })
      .addCase(getDroneAvailabilityAsync.pending, (state) => {
        state.droneListAvailable.status = 'loading';
      })
      .addCase(getDroneAvailabilityAsync.fulfilled, (state: any, action) => {
        state.droneListAvailable.status = 'success';
        state.droneListAvailable.data = action.payload;
      })
      .addCase(getDroneAvailabilityAsync.rejected, (state: any, action) => {
        state.droneListAvailable.status = 'failed';
        state.droneListAvailable.data = action.error;
      })
      .addCase(getFederatedToAsync.pending, (state) => {
        state.federatedList.status = 'loading';
      })
      .addCase(getFederatedToAsync.fulfilled, (state: any, action) => {
        state.federatedList.status = 'success';
        state.federatedList.data = action.payload;
      })
      .addCase(getFederatedToAsync.rejected, (state: any, action) => {
        state.federatedList.status = 'failed';
        state.federatedList.data = action.error;
      })
      .addCase(getAssetJobsAsync.pending, (state) => {
        state.assetJobsList.status = 'loading';
      })
      .addCase(getAssetJobsAsync.fulfilled, (state: any, action) => {
        state.assetJobsList.status = 'success';
        state.assetJobsList.data = action.payload;
      })
      .addCase(getAssetJobsAsync.rejected, (state: any, action) => {
        state.assetJobsList.status = 'failed';
        state.assetJobsList.data = action.error;
      })
      .addCase(getAllAssetBusyAsync.pending, (state) => {
        state.assetBusyList.status = 'loading';
      })
      .addCase(getAllAssetBusyAsync.fulfilled, (state: any, action) => {
        state.assetBusyList.status = 'success';
        state.assetBusyList.data = action.payload;
      })
      .addCase(getAllAssetBusyAsync.rejected, (state: any, action) => {
        state.assetBusyList.status = 'failed';
        state.assetBusyList.data = action.error;
      });
  }
});

export const {
  resetAssets,
  resetUserAssets,
  resetAddAsset,
  resetSuspendAsset,
  resetRemoveAsset,
  setDroneToEdit,
  resetDroneToEdit,
  resetDroneDetailToView,
  resetAlldroneListAvailable,
  resetFederatedList,
  resetAssetJobsList,
  resetAssetBusyList
} = assetManagementSlice.actions;
export const assetsStatus = (state: RootState) => state.assetManagement.assets;
export const addAssetStatus = (state: RootState) =>
  state.assetManagement.addAsset;
export const userAssetsStatus = (state: RootState) =>
  state.assetManagement.userAssets;
export const suspendAssetStatus = (state: RootState) =>
  state.assetManagement.suspendAsset;
export const removeAssetStatus = (state: RootState) =>
  state.assetManagement.removeAsset;
export const droneToEditStatus = (state: RootState) => state.assetManagement.droneToEdit;
export const droneDetailToViewStatus = (state: RootState) =>
  state.assetManagement.droneDetailToView;
export const droneListAvailableStatus = (state: RootState) =>
  state.assetManagement.droneListAvailable;
export const federatedListStatus = (state:RootState) => state.assetManagement.federatedList;
export const assetJobsListStatus = (state:RootState) => state.assetManagement.assetJobsList;
export const assetBusyListStatus = (state:RootState) => state.assetManagement.assetBusyList;

export default assetManagementSlice.reducer;
