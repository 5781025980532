import { del, get, post, put } from '../utility/http';

export async function fetchUserAsset(type: string) {
  try {
    const response = await get(
      `/asset-management/userhasasset/myassets/${type}`,
      true
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchAsset() {
  try {
    const response = await get('/asset-management/assets', true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function addAssetToUser(body: any) {
  try {
    const response = await post(
      '/asset-management/userhasasset',
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function suspendAssetToUser(body: any, idUserAsset: number) {
  try {
    const response = await put(
      `/asset-management/userhasasset/${idUserAsset}`,
      true,
      {},
      body
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function removeAssetToUser(idUserAsset: number) {
  try {
    const response = await del(
      `/asset-management/userhasasset/${idUserAsset}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function getDroneDetailToView(id: number){
  try {
    const response = await get(
      `/asset-management/userhasasset/myasset/${id}`,
      true,
      {}
    );
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function getDroneAvailability({startDate, endDate, role}:any){
  try {
    const response = await post(
      `/data/availableassets?startDatetime=${startDate}&endDatetime=${endDate}`,
      true,
      {},
      role
    );
    return response;
  } catch (e:any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function getFederatedTo(role:string){
  try {
    const response = await get(
      `/federation/users/${role}/federatedto/`,
      true,
      {}
    );
    return response;
  } catch (e:any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function getAssetJobs(id:any){
  try {
    const response = await get(
      `/data/jobs/userhasassets/${id}`,
      true,
      {}
    );
    return response;
  } catch (e:any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function getAllAssetBusy({startDate, endDate, role}:any){
  try {
    const response = await get(
      `/data/availableassets/users/${role}?startDatetime=${startDate}&endDatetime=${endDate}`,
      true,
      {}
    );
    return response;
  } catch (e:any) {
    return Promise.reject(e.response ? e.response : e);
  }
}