import { get, post, put } from '../utility/http';
import { openToast } from '../utility/toast';

export async function fetchThreatPoint(ctx: string) {
  try {
    const response = await get(`/tasks/tasks/ctx/${ctx}?type=citizen-request`, true);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchCreateCitizenReport(body: any) {
  try {
    const response = await post(`/tasks/ctx/${body.ctx}/tasks`, true, {}, body, false, true);
    openToast({
      severity: 'success',
      alertMessage: 'ReportOk'
    });
    return response;
  } catch (e: any) {
    openToast({
      severity: 'error',
      alertMessage: 'ReportKO'
    });
    return Promise.reject(e.response ? e.response : e);
  }
}

export async function fetchEditCitizenReport(body: any) {
  try {
    const response = await put(`/tasks/tasks/${body.id}`, true, {}, body);
    return response;
  } catch (e: any) {
    return Promise.reject(e.response ? e.response : e);
  }
}
