import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchBoundComune, fetchDroni, fetchEditTask } from '../../services/modifyTaskAPI';
import { openToast } from '../../utility/toast';

const initialState: any = {
  taskToEdit: {
    data: [],
    status: 'idle'
  },
  editTask: {
    taskData: {
      data: null,
      status: 'idle'
    },
    drawing: false,
    drawingType: ''
  },
  droni: {
    data: [],
    status: 'idle'
  },
  boundComune: {
    data: null,
    status: 'idle'
  }
};

export const droniAsync = createAsyncThunk('editTask/droni', async () => {
  try {
    const response: any = await fetchDroni();
    return response.data;
  } catch (e: any) {
    return Promise.reject(e.data ? e.data : e);
  }
});

export const boundComuneAsync = createAsyncThunk(
  'editTask/boundComune',
  async (data: string) => {
    try {
      const response: any = await fetchBoundComune(data);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const editTaskAsync = createAsyncThunk(
  'editTask/editTask',
  async (data: any) => {
    try {
      const response: any = await fetchEditTask(data);
      openToast({
        severity: 'success',
        alertMessage: 'FetchOK'
      });
      return response.data;
    } catch (e: any) {
      openToast({
        severity: 'error',
        alertMessage: 'FetchKO'
      });
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const modifyTaskSlice = createSlice({
  name: 'editTask',
  initialState,
  reducers: {
    setEditTaskDrawingType: (state, action) => {
      state.editTask.drawingType = action.payload;
    },
    resetEditTaskDrawingType: (state) => {
      state.editTask.drawingType = '';
    },
    setEditTaskData: (state, action) => {
      state.editTask.taskData.status = 'idle';
      state.editTask.taskData.data = action.payload;
    },
    resetEditTaskData: (state) => {
      state.editTask.taskData.status = 'idle';
      state.editTask.taskData.data = null;
    },
    setEditTaskDrawing: (state) => {
      state.editTask.drawing = true;
    },
    resetEditTaskDrawing: (state) => {
      state.editTask.drawing = false;
    },
    setTaskToEdit: (state, action) => {
      state.taskToEdit.status = 'idle';
      state.taskToEdit.data = action.payload;
    },
    resetTaskToEdit: (state) => {
      state.taskToEdit.status = 'idle';
      state.taskToEdit.data = [];
    },
    resetDroni: (state) => {
      state.droni.status = 'idle';
      state.droni.data = [];
    },
    resetBoundComune: (state) => {
      state.boundComune.status = 'idle';
      state.boundComune.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(droniAsync.pending, (state) => {
        state.droni.status = 'loading';
      })
      .addCase(droniAsync.fulfilled, (state, action) => {
        state.droni.status = 'success';
        state.droni.data = action.payload;
      })
      .addCase(droniAsync.rejected, (state, action) => {
        state.droni.status = 'failed';
        state.droni.data = action.error;
      })
      .addCase(boundComuneAsync.pending, (state) => {
        state.boundComune.status = 'loading';
      })
      .addCase(boundComuneAsync.fulfilled, (state, action) => {
        state.boundComune.status = 'success';
        state.boundComune.data = action.payload;
      })
      .addCase(boundComuneAsync.rejected, (state, action) => {
        state.boundComune.status = 'failed';
        state.boundComune.data = action.error;
      })
      .addCase(editTaskAsync.pending, (state) => {
        state.editTask.taskData.status = 'loading';
      })
      .addCase(editTaskAsync.fulfilled, (state, action) => {
        state.editTask.taskData.status = 'success';
        state.editTask.taskData.data = action.payload;
      })
      .addCase(editTaskAsync.rejected, (state, action) => {
        state.editTask.taskData.status = 'failed';
        state.editTask.taskData.data = action.error;
      });
  }
});

export const {
  setEditTaskDrawingType,
  resetEditTaskDrawingType,
  setEditTaskDrawing,
  resetEditTaskDrawing,
  setEditTaskData,
  resetEditTaskData,
  setTaskToEdit,
  resetTaskToEdit,
  resetDroni,
  resetBoundComune
} = modifyTaskSlice.actions;
export const taskToEditState = (state: RootState) =>
  state.taskToEdit.taskToEdit;
export const droniState = (state: RootState) => state.taskToEdit.droni;
export const editTaskDrawingState = (state: RootState) =>
  state.taskToEdit.editTask.drawing;
export const editTaskDrawingTypeState = (state: RootState) =>
  state.taskToEdit.editTask.drawingType;
export const editTaskDataState = (state: RootState) =>
  state.taskToEdit.editTask.taskData;
export const boundComuneState = (state: RootState) =>
  state.taskToEdit.boundComune;

export default modifyTaskSlice.reducer;
